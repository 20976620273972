import React, { useState } from 'react'
import './feedback.scss'
import { FeedbackPage } from './FeedbackPage'
import { PageCreate } from './PageCreate'
import { PageConfirm } from '@/pages/Profile/components/Feedback/PageConfirm'
import { PageSent } from '@/pages/Profile/components/Feedback/PageSent'
import { useTranslation } from 'react-i18next'

export const FeedbackPageIndex = {
  Create: 0,
  Confirm: 1,
  Sent: 2
}

const Feedback = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(FeedbackPageIndex.Create)
  const [subject, setSubject] = useState(t('text_feedback_subject_withdrawal'))
  const [content, setContent] = useState('')
  return (
    <>
      <FeedbackPage pageIndex={FeedbackPageIndex.Create} page={page} setPage={setPage}>
        <PageCreate
          setPage={setPage}
          setContent={setContent}
          setSubject={setSubject}
          content={content}
          subject={subject}
        />
      </FeedbackPage>
      <FeedbackPage pageIndex={FeedbackPageIndex.Confirm} page={page} setPage={setPage}>
        <PageConfirm setPage={setPage} subject={subject} content={content} />
      </FeedbackPage>
      <FeedbackPage pageIndex={FeedbackPageIndex.Sent} page={page} setPage={setPage}>
        <PageSent subject={subject} />
      </FeedbackPage>
    </>
  )
}
export default Feedback
