export const checkRequire = (value) => {
  return !(
    !value ||
    (value && typeof value === 'string' && value.trim() === '') ||
    (value && typeof value === 'object' && Object.keys(value).length === 0)
  )
}

export const checkMin = (value, numberMin) => {
  return !(value && value.trim().length < numberMin)
}

export const checkMax = (value, numberMax) => {
  return !(value && value.trim().length > numberMax)
}

export const checkPassWord = (value) => {
  const passFormat = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}$/
  return value && passFormat.test(value)
}

export const checkEmail = (value) => {
  // eslint-disable-next-line prettier/prettier, no-useless-escape
  const mailFormat = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return value && mailFormat.test(value)
}
