import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { apiPost } from '@/config/httpRequest'
import { loadStripe } from '@stripe/stripe-js'
import YourCart from '@/features/Payment/components/YourCart'
import '@/features/Payment/style.scss'
import Close from '@/assets/images/icons/close.svg'
import CheckoutForm from '@/features/Payment/components/CheckoutForm'
import { setIsLoading, setShowToastError } from '@/features/Common/CommonSlice'
import { t } from 'i18next'

const Stripe = ({ eventId, pointId, dataSession, setIsShowStripe, handleConfirmPayment }) => {
  const [data, setData] = useState(null)
  const [isClickedBuy, setIsClickedBuy] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!eventId && !pointId) return
    let payload = eventId ? { eventId: eventId } : { pointId: pointId }
    // call api checkout to buy
    dispatch(setIsLoading(true))
    apiPost('/api/v1/events/checkout', payload)
      .then((res) => {
        setData(res?.data?.data)
        dispatch(setIsLoading(false))
      })
      .catch((error) => {
        dispatch(setIsLoading(false))
        dispatch(setShowToastError(error?.data?.detail ?? 'error'))
        setIsShowStripe(false)
      })
  }, [eventId, pointId])

  /*----------Show your cart----------*/
  /*----------Call api/checkout to get Stripe client secret----------*/
  const { paymentIntentKey, paymentIntentId, customer, ephemeralKey, publishableKey } = data ?? {}
  const clientSecret = paymentIntentKey

  function cancelPayment() {
    if (!paymentIntentId) {
      setIsShowStripe(false)
      return
    }

    // Todo: call api events/cancel-payment
    dispatch(setIsLoading(true))
    apiPost('/api/v1/events/cancel-payment', { paymentIntentId: paymentIntentId })
      .then(() => {
        console.info(`cancel payment ${paymentIntentId} success`)
      })
      .catch((reason) => {
        console.error(`cancel payment ${paymentIntentId}  failed => reason: `, reason)
        dispatch(
          setShowToastError(`cancel payment ${paymentIntentId}  failed => reason: ${reason}`)
        )
      })
      .finally(() => {
        setIsShowStripe(false)
        dispatch(setIsLoading(false))
      })
  }

  if (!isClickedBuy || !clientSecret)
    return (
      <React.Fragment>
        <div className="box-title-detail-session mb-4">
          <div className="action-close cs" onClick={() => cancelPayment()}>
            <img src={Close} alt="close" />
          </div>
        </div>
        <div className="m-auto h-100">
          <YourCart setIsClickedBuy={setIsClickedBuy} data={data} dataSession={dataSession} />
        </div>
      </React.Fragment>
    )

  const options = {
    // passing the client secret obtained in step 3
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'night'
    },
    customerOptions: {
      customer: customer,
      ephemeralKey: ephemeralKey
    }
  }

  const stripePromise = loadStripe(publishableKey || '')

  const renderHeader = () => {
    if (pointId)
      return (
        <div className="box-title-detail-session">
          <div className="title text-white">{t('point_buy_title')}</div>
          <div className="action-close cs" onClick={() => setIsClickedBuy(false)}>
            <img src={Close} alt="close" />
          </div>
        </div>
      )

    return (
      <div className="box-title-detail-session mb-4">
        <div className="action-close cs" onClick={() => setIsClickedBuy(false)}>
          <img src={Close} alt="close" />
        </div>
      </div>
    )
  }

  return (
    <>
      {renderHeader()}
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          data={data}
          handleConfirmPayment={(res) => {
            handleConfirmPayment(res)
            setIsClickedBuy(false)
          }}
        />
      </Elements>
    </>
  )
}

Stripe.propTypes = {
  eventId: PropTypes.any,
  pointId: PropTypes.any,
  dataSession: PropTypes.any,
  setIsShowStripe: PropTypes.func,
  handleConfirmPayment: PropTypes.func.isRequired
}

export default Stripe
