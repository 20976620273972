import DefaultLayout from './layouts/DefaultLayout'
import { Route, Routes } from 'react-router-dom'
import { arrRouter } from './routers/index'
import { useEffect } from 'react'
import { setupPresence } from '@/config/firebase'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import { useSelector } from 'react-redux'

function App() {
  const user = useSelector(selectCurrentUser) || null
  useEffect(() => {
    document.onkeyup = function (e) {
      if (e.key == 'PrintScreen') {
        stopPrntScr()
        return false
      }
      return true
    }

    document.onkeydown = function (e) {
      if (
        e.ctrlKey &&
        (e.keyCode === 73 ||
          e.keyCode === 67 ||
          e.keyCode === 86 ||
          e.keyCode === 85 ||
          e.keyCode === 117)
      ) {
        return false
      } else if (e.keyCode === 123) {
        return false
      } else {
        return true
      }
    }
  }, [])

  useEffect(() => {
    if (user?.id) setupPresence(user?.id)
  }, [user])

  const stopPrntScr = () => {
    let inpFld = document.createElement('input')
    inpFld.setAttribute('value', '.')
    inpFld.setAttribute('width', '0')
    inpFld.style.height = '0px'
    inpFld.style.width = '0px'
    inpFld.style.border = '0px'
    document.body.appendChild(inpFld)
    inpFld.select()
    document.execCommand('copy')
    inpFld.remove(inpFld)
  }

  return (
    <div className="app-camelia">
      <Routes>
        {arrRouter.map((route, index) => {
          const Page = route.component
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <DefaultLayout
                  isNeedAuth={route.isNeedAuth || false}
                  isHiddenScrollLayout={route.isHiddenScrollLayout || false}
                  classNameComponent={route.className || ''}>
                  <Page />
                </DefaultLayout>
              }></Route>
          )
        })}
      </Routes>
    </div>
  )
}

export default App
