import AgoraRTC from 'agora-rtc-sdk-ng'

export const APP_ID_AGORA = process.env.REACT_APP_ID_AGORA

export const CLIENT = AgoraRTC.createClient({
  mode: 'live',
  codec: 'vp8'
})

export const ROLE_AGORA = {
  host: 'host',
  audience: 'audience'
}

export const TYPE_EVENT = {
  liveStream: 'LIVESTREAM',
  playBack: 'PLAYBACK'
}
