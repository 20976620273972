import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { STATUS_STRIPE, TAB_PROFILE } from '@/utils/constants'
import {
  selectCurrentToken,
  selectCurrentUser,
  selectStripeTransactionList
} from '@/features/Auth/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '@/config/firebase'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { setIsLoading, setIsShowModalFullPage } from '@/features/Common/CommonSlice'
import { getTimeStartAndTimeEnd } from '@/utils/utils'
import {
  selectListSession,
  setDataItemModalListImage,
  setListSession
} from '@/features/Sessions/SessionSlice'
import moment from 'moment'
import ItemSessionPointHistory from '@/components/profile/ItemSessionPointHistory'

function PointHistory({ tabProfile }) {
  const token = useSelector(selectCurrentToken) || null
  const user = useSelector(selectCurrentUser) || null
  const dispatch = useDispatch()
  const listSession = useSelector(selectListSession) || []
  const stripeTransactionList = useSelector(selectStripeTransactionList) || []

  useEffect(() => {
    if (tabProfile === TAB_PROFILE.POINT_HISTORY) {
      getDataPointHistory().catch(console.error)
    }
  }, [tabProfile, token])

  const getDataPointHistory = async () => {
    dispatch(setIsLoading(true))
    const queryDataEvent = await query(collection(db, 'events'))
    await onSnapshot(queryDataEvent, (querySnapshot) => {
      const data = []
      querySnapshot.forEach((doc) => {
        const { timestamps, audienceIDs, title, price, coverImagePath, image_limit } = doc.data()
        const time = getTimeStartAndTimeEnd(timestamps)
        const formatData = {
          id: doc.id,
          audienceIds: audienceIDs || [],
          title: title,
          price: price,
          image: coverImagePath,
          timeStart: time?.timeStart?.seconds * 1000,
          timeEnd: time?.timeEnd?.seconds * 1000,
          dateStart: moment(time?.timeStart?.seconds * 1000).format('YYYY/MM/DD'),
          timeString: `${moment(time?.timeStart?.seconds * 1000).format('HH:mm')} - ${moment(
            time?.timeEnd?.seconds * 1000
          ).format('HH:mm')}`,
          image_limit: image_limit
        }
        data.push(formatData)
      })
      dispatch(setListSession(data))
    })
    dispatch(setIsLoading(false))
  }

  const dataPointHistory = useMemo(() => {
    if (listSession && listSession.length > 0 && user) {
      const timeNow = new Date().getTime()
      const dataSession = listSession.filter(
        (item) =>
          timeNow - item.timeEnd > 0 &&
          item.audienceIds.find((audienceId) => audienceId === user.id)
      )
      const dataSessionBought = stripeTransactionList?.filter(
        (item) => item.status === STATUS_STRIPE.SUCCEEDED
      )
      if (dataSessionBought && dataSessionBought.length > 0) {
        const dataSortSessionBought = dataSessionBought.sort(
          ({ created_date: created_date_a }, { created_date: created_date_b }) => {
            return created_date_b?.seconds - created_date_a?.seconds
          }
        )
        const data = []
        dataSortSessionBought.forEach((item) => {
          const dataFind = dataSession.find((itemSession) => itemSession.id === item.event_id)
          const check = data.find((itemCheck) => itemCheck.id === item.event_id)
          if (dataFind && !check) {
            data.push(dataFind)
          }
        })
        return data
      }

      return []
    }
    return []
  }, [listSession, user, stripeTransactionList])

  const showModalListImageSession = (dataItem) => {
    dispatch(setDataItemModalListImage({ id: dataItem.id, title: dataItem.title }))
    dispatch(setIsShowModalFullPage(true))
  }
  return (
    <div className="list-point-history">
      {dataPointHistory && dataPointHistory.length > 0
        ? dataPointHistory.map((item) => {
            return (
              <div
                className="w-100 cs"
                onClick={() => showModalListImageSession(item)}
                key={item.id}>
                <ItemSessionPointHistory dataItem={item} />
              </div>
            )
          })
        : ''}
    </div>
  )
}

PointHistory.propTypes = {
  tabProfile: PropTypes.any
}

export default PointHistory
