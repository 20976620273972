import Cards from 'react-credit-cards-2'
import PropTypes from 'prop-types'
import 'react-credit-cards-2/es/styles-compiled.css'
import RCheckBox from '@/components/RCheckBox'

const CreditCard = (props) => {
  const { item, selected } = props
  const { last4, exp_month, exp_year, brand } = item
  const cvc = ''
  const expiry = `${exp_month < 10 ? '0' + exp_month : exp_month}/${exp_year}`
  const focus = ''
  const name = ''
  const number = '**** **** **** ' + last4
  return (
    <div className={`mt-2`}>
      <div
        className={`card-outer ${selected ? 'credit-card-selected' : ''} `}
        onClick={props.onClick}>
        <Cards
          cvc={cvc}
          expiry={expiry}
          focused={focus}
          name={name}
          number={number}
          preview={true}
          issuer={brand}
        />
        {selected && (
          <RCheckBox className="card-rcheckbox" onChange={() => {}} isChecked={selected} />
        )}
      </div>
    </div>
  )
}
CreditCard.propTypes = {
  item: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
}
export default CreditCard
