import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import { t } from 'i18next'

export function MyPoint() {
  const user = useSelector(selectCurrentUser) || null
  return (
    <React.Fragment key={user?.id ?? ''}>
      <div className="point-container my-point-container">
        <div className="title yuGothic fs-12px-b">{t('text_title_my_point')}</div>
        <div className="my-point roboto fs-12px-m">{user?.point ?? 'point'}P</div>
      </div>
    </React.Fragment>
  )
}
