import React, { useState, useEffect } from 'react'

import {
  selectIsShowForgotPassword,
  setIsShowForgotPassword,
  setShowFormLogin
} from '@/features/Auth/AuthSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { checkEmail } from '@/utils/validate'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { ERROR_FIREBASE } from '@/utils/constants'
import { setShowToastError, setShowToastSuccess } from '@/features/Common/CommonSlice'

import '@/assets/scss/login.scss'
import Button from 'react-bootstrap/Button'

import Modal from 'react-bootstrap/Modal'
import Close from '@/assets/images/icons/close.svg'

function ForgotPassword() {
  const isShow = useSelector(selectIsShowForgotPassword) || false

  const [email, setEmail] = useState('')
  const [successInput, setSuccessInput] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClose = () => {
    dispatch(setIsShowForgotPassword(false))
  }
  useEffect(() => {
    if (!isShow) {
      setEmail('')
      setSuccessInput(false)
      setErrorMessage(null)
    }
  }, [isShow])
  useEffect(() => {
    if (checkEmail(email)) {
      setSuccessInput(true)
      setErrorMessage(null)
    } else {
      setSuccessInput(false)
    }
  }, [email])

  const blurInput = (e) => {
    const value = e.target.value
    if (!checkEmail(value)) {
      if (!value) {
        setErrorMessage(t('require_email'))
      } else {
        setErrorMessage(t('error_email'))
      }
    }
  }

  useEffect(() => {
    let keyDownHandler = null
    if (isShow) {
      keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          handleSubmit()
        }
      }

      document.addEventListener('keydown', keyDownHandler)
    }

    return () => {
      if (keyDownHandler) {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [email, isShow])

  const handleSubmit = () => {
    if (!successInput || !isShow) return
    const auth = getAuth()
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch(setShowToastSuccess(t('text_forgot_password_success')))
        dispatch(setIsShowForgotPassword(false))
        dispatch(setShowFormLogin(false))
        navigate('/')
      })
      .catch((error) => {
        const errorCode = error.code
        if (errorCode === ERROR_FIREBASE.EMAIL_NOT_FOUND) {
          dispatch(setShowToastError(t('email_not_register')))
        } else if (errorCode === ERROR_FIREBASE.TOO_MANY_REQUESTS) {
          dispatch(setShowToastError(t('text_too_many_request')))
        } else {
          dispatch(setShowToastError(t('text_error_forgot_password')))
        }
      })
  }
  return (
    <Modal show={isShow} className="form-login" centered scrollable>
      <Modal.Header className="header-login">
        <div className="action-close-button" onClick={handleClose}>
          <img src={Close} alt="Close" />
        </div>
        <h3 className="text-white fs-14px text-center pt-5">{t('text_forgot_password')}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="body-modal-forgot-password">
          <form autoComplete="off">
            <div className={`box-input mb-3 ${successInput ? 'success-input' : ''}`}>
              <label htmlFor="email" className="form-label text-white">
                {t('email_address')}
              </label>
              <input
                type="email"
                className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                id="email"
                autoComplete="off"
                value={email}
                placeholder=""
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => blurInput(e)}
              />
              {errorMessage ? <div className="invalid-feedback">{errorMessage}</div> : ''}
            </div>
            <div className="action-login d-flex justify-content-center py-4">
              <Button
                className={`button-login ${!successInput ? 'disable-button' : ''}`}
                onClick={handleSubmit}>
                {t('button_submit_forgot_password')}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ForgotPassword
