import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectListSession, setIsShowListSession } from '@/features/Sessions/SessionSlice'
import { useTranslation } from 'react-i18next'
import ItemSession from '@/components/home/ItemSession'

function BoxSessionHome() {
  const listSession = useSelector(selectListSession) || []

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const boxSessionHome = useMemo(() => {
    const cloneData = [...listSession]
    const sortData = cloneData.sort((a, b) => {
      return b.timeStart - a.timeStart
    })
    return sortData.slice(0, 6)
  }, [listSession])

  const showListSession = () => {
    dispatch(setIsShowListSession(true))
    const element = document.getElementById('layout-content-page')
    if (element) {
      element.scrollTo(0, 0)
    }
  }
  return (
    <div className="box-list-session-home">
      <div className="header-list-session-home d-flex align-items-center mb-3">
        <h3 className="title-home w-80">{t('text_session')}</h3>
        <div
          className="action-list w-20 fs-16px text-white text-end cs hidden-app"
          onClick={showListSession}>
          {t('text_list_session')}
        </div>
      </div>
      <div className="content-session-list">
        {boxSessionHome && boxSessionHome.length > 0
          ? boxSessionHome.map((item) => (
              <div className="box-session-home" key={item.id}>
                <ItemSession dataItem={item} />
              </div>
            ))
          : ''}
      </div>
      <div className="action-list-session text-center my-3">
        <button className="btn w-50" onClick={showListSession}>
          {t('text_list_session')}
        </button>
      </div>
    </div>
  )
}

export default BoxSessionHome
