/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsShowRegister,
  setAvatarUser,
  setCredentials,
  setShowFormRegister,
  setShowEmailVerify
} from '@/features/Auth/AuthSlice'
import { auth, db, registerWithEmailAndPassword, storage } from '@/config/firebase'
import { doc, setDoc } from 'firebase/firestore'
import { setIsLoading, setShowToastError } from '@/features/Common/CommonSlice'
import { checkEmail, checkPassWord } from '@/utils/validate'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { sendEmailVerification } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import { localStorageWeb } from '@/utils/constants'
import '@/assets/scss/login.scss'
import { Link } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Close from '@/assets/images/icons/close.svg'
import EyeOn from '@/assets/images/icons/eye-on.svg'
import EyeOff from '@/assets/images/icons/eye-off.svg'
import NoAvatar from '@/assets/images/no-avatar.svg'

function FormRegister() {
  const isShow = useSelector(selectIsShowRegister) || false
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [imageUpload, setImageUpload] = useState(null)

  const [formRegister, setFormRegister] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassWord: ''
  })

  const [errorMessage, setErrorMessage] = useState({
    userName: null,
    email: null,
    password: null,
    confirmPassWord: ''
  })

  const [successInput, setSuccessInput] = useState({
    userName: false,
    email: false,
    password: false,
    confirmPassWord: false
  })
  const [isShowEye, setIsShowEye] = useState(false)
  const [isShowEyeConfirm, setIsShowEyeConfirm] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleInputLogin = (e, nameInput) => {
    const form = {}
    form[nameInput] = e.target.value
    setFormRegister((previous) => {
      return {
        ...previous,
        ...form
      }
    })
  }

  useEffect(() => {
    if (formRegister.userName) {
      setSuccessInput((previous) => {
        return {
          ...previous,
          userName: true
        }
      })
      setErrorMessage((previous) => {
        return {
          ...previous,
          userName: null
        }
      })
    } else {
      setSuccessInput((previous) => {
        return {
          ...previous,
          userName: false
        }
      })
    }
    if (checkEmail(formRegister.email)) {
      setSuccessInput((previous) => {
        return {
          ...previous,
          email: true
        }
      })
      setErrorMessage((previous) => {
        return {
          ...previous,
          email: null
        }
      })
    } else {
      setSuccessInput((previous) => {
        return {
          ...previous,
          email: false
        }
      })
    }
    if (checkPassWord(formRegister.password)) {
      setSuccessInput((previous) => {
        return {
          ...previous,
          password: true
        }
      })
      setErrorMessage((previous) => {
        return {
          ...previous,
          password: null
        }
      })
    } else {
      setSuccessInput((previous) => {
        return {
          ...previous,
          password: false
        }
      })
    }
    if (formRegister.confirmPassWord && formRegister.password === formRegister.confirmPassWord) {
      setSuccessInput((previous) => {
        return {
          ...previous,
          confirmPassWord: true
        }
      })
      setErrorMessage((previous) => {
        return {
          ...previous,
          confirmPassWord: null
        }
      })
    } else {
      setSuccessInput((previous) => {
        return {
          ...previous,
          confirmPassWord: false
        }
      })
    }
    if (
      !formRegister.userName ||
      !checkEmail(formRegister.email) ||
      !checkPassWord(formRegister.password) ||
      formRegister.password !== formRegister.confirmPassWord
    ) {
      setIsSubmit(false)
    } else {
      setIsSubmit(true)
    }
  }, [formRegister])

  const resetValueDefault = () => {
    setFormRegister({
      userName: '',
      email: '',
      password: '',
      confirmPassWord: ''
    })
    setErrorMessage({
      userName: null,
      email: null,
      password: null,
      confirmPassWord: ''
    })
    setSuccessInput({
      userName: false,
      email: false,
      password: false,
      confirmPassWord: false
    })
    setIsShowEye(false)
    setIsShowEyeConfirm(false)
    setIsSubmit(false)
  }

  const handleClose = () => {
    resetValueDefault()
    dispatch(setShowFormRegister(false))
  }

  useEffect(() => {
    let keyDownHandler = null
    if (isShow) {
      const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          handleSubmit().catch(console.error)
        }
      }

      document.addEventListener('keydown', keyDownHandler)
    }

    return () => {
      if (keyDownHandler) {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [isSubmit, formRegister, isShow])

  useEffect(() => {
    if (!isShow) {
      resetValueDefault()
    }
  }, [isShow])

  const handleSubmit = async () => {
    if (!isSubmit || !isShow) return
    dispatch(setIsLoading(true))
    registerWithEmailAndPassword(formRegister)
      .then(async (response) => {
        await sendEmailVerification(auth.currentUser).catch(console.error)
        await setDoc(doc(db, 'users', response.user.uid), {
          auth_user_id: response.user.uid,
          email: formRegister.email,
          user_name: formRegister.userName,
          email_verified: false,
          point: 0
        })
        if (imageUpload) {
          const imageRef = ref(storage, `users/avatar/${response.user.uid}.jpg`)
          uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              localStorage.setItem(localStorageWeb.avatar_user, url)
              dispatch(setAvatarUser(url))
            })
          })
        }
        const dataUser = {
          email: formRegister.email,
          id: response.user.uid,
          userName: formRegister.userName,
          point: 0
        }
        dispatch(
          setCredentials({
            user: dataUser,
            accessToken: response._tokenResponse.idToken
          })
        )
        dispatch(setIsLoading(false))
        dispatch(setShowEmailVerify(true))
      })
      .catch((error) => {
        if (error === 'auth/email-already-in-use') {
          dispatch(setShowToastError(t('email_already')))
        } else {
          dispatch(setShowToastError(t('register_error')))
        }
        dispatch(setIsLoading(false))
      })
  }

  const uploadImage = (e) => {
    const file = e.target.files[0]
    file.preview = URL.createObjectURL(file)
    setImageUpload(file)
  }

  const blurInput = (e, nameInput) => {
    const value = e.target.value
    switch (nameInput) {
      case 'userName':
        if (!value) {
          setErrorMessage((previous) => {
            return {
              ...previous,
              userName: t('require_userName')
            }
          })
        }
        break
      case 'email':
        if (!checkEmail(value)) {
          if (!value) {
            setErrorMessage((previous) => {
              return {
                ...previous,
                email: t('require_email')
              }
            })
          } else {
            setErrorMessage((previous) => {
              return {
                ...previous,
                email: t('error_email')
              }
            })
          }
        }
        break
      case 'password':
        if (!checkPassWord(value)) {
          if (!value) {
            setErrorMessage((previous) => {
              return {
                ...previous,
                password: t('require_password')
              }
            })
          } else {
            setErrorMessage((previous) => {
              return {
                ...previous,
                password: t('error_password')
              }
            })
          }
        }
        break
      case 'confirmPassWord':
        if (!value) {
          setErrorMessage((previous) => {
            return {
              ...previous,
              confirmPassWord: t('require_password_confirm')
            }
          })
        }
        if (value !== formRegister.password) {
          setErrorMessage((previous) => {
            return {
              ...previous,
              confirmPassWord: t('password_not_match')
            }
          })
        }
        break
      default:
        break
    }
  }

  return (
    <Modal show={isShow} className="form-login form-register" centered scrollable>
      <Modal.Header className="header-login">
        <div className="action-close-button" onClick={handleClose}>
          <img src={Close} alt="Close" />
        </div>
        <h3 className="text-white fs-14px text-center pt-5">{t('text_register')}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="body-modal-form-register">
          <form autoComplete="off">
            <div className="avatar">
              <label>
                <input type="file" hidden onChange={uploadImage} />
                {!imageUpload ? (
                  <img src={NoAvatar} alt="no-avatar" />
                ) : (
                  <img src={imageUpload.preview} alt="no-avatar" />
                )}
              </label>
            </div>
            <div className="text-center text-white my-3">{t('text_avatar')}</div>

            <div className={`box-input mb-3 ${successInput.userName ? 'success-input' : ''}`}>
              <label htmlFor="userName" className="form-label text-white">
                {t('label_user_name')}
              </label>
              <input
                type="text"
                className={`form-control ${errorMessage.userName ? 'is-invalid' : ''}`}
                id="userName"
                autoComplete="off"
                value={formRegister.userName}
                placeholder=""
                onChange={(e) => handleInputLogin(e, 'userName')}
                onBlur={(e) => blurInput(e, 'userName')}
              />
              {errorMessage.userName ? (
                <div className="invalid-feedback">{errorMessage.userName}</div>
              ) : (
                ''
              )}
            </div>
            <div className={`box-input mb-3 ${successInput.email ? 'success-input' : ''}`}>
              <label htmlFor="email" className="form-label text-white">
                {t('email_address')}
              </label>
              <input
                type="email"
                className={`form-control ${errorMessage.email ? 'is-invalid' : ''}`}
                id="email"
                autoComplete="off"
                value={formRegister.email}
                placeholder="受信可能なメールアドレスをご入力ください"
                onChange={(e) => handleInputLogin(e, 'email')}
                onBlur={(e) => blurInput(e, 'email')}
              />
              {errorMessage.email ? (
                <div className="invalid-feedback">{errorMessage.email}</div>
              ) : (
                ''
              )}
            </div>
            <div className="box-input mb-3">
              <label htmlFor="password" className="form-label text-white">
                {t('password')}
              </label>
              <div
                className={`input-group input-password ${
                  successInput.password ? 'success-input' : ''
                }`}>
                <input
                  type={isShowEye ? 'text' : 'password'}
                  className={`form-control ${errorMessage.password ? 'is-invalid' : ''} ${
                    successInput.password ? 'border-end-0' : ''
                  }`}
                  id="password"
                  autoComplete="off"
                  placeholder={t('placeholder_password')}
                  value={formRegister.password}
                  onChange={(e) => handleInputLogin(e, 'password')}
                  onBlur={(e) => blurInput(e, 'password')}
                />
                {!isShowEye ? (
                  <span
                    className={`input-group-text cs ${errorMessage.password ? 'is-invalid' : ''}`}
                    onClick={() => setIsShowEye(true)}>
                    <img src={EyeOn} alt="EyeOn" />
                  </span>
                ) : (
                  <span
                    className={`input-group-text cs ${errorMessage.password ? 'is-invalid' : ''}`}
                    onClick={() => setIsShowEye(false)}>
                    <img src={EyeOff} alt="EyeOff" />
                  </span>
                )}
                {errorMessage.password ? (
                  <div className="invalid-feedback">{errorMessage.password}</div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="box-input mb-3">
              <label htmlFor="confirmPassword" className="form-label text-white">
                {t('label_confirm_password')}
              </label>
              <div
                className={`input-group input-password ${
                  successInput.confirmPassWord ? 'success-input' : ''
                }`}>
                <input
                  type={isShowEyeConfirm ? 'text' : 'password'}
                  className={`form-control ${errorMessage.confirmPassWord ? 'is-invalid' : ''} ${
                    successInput.confirmPassWord ? 'border-end-0' : ''
                  }`}
                  id="confirmPassWord"
                  autoComplete="off"
                  placeholder={t('placeholder_confirm_password')}
                  value={formRegister.confirmPassWord}
                  onChange={(e) => handleInputLogin(e, 'confirmPassWord')}
                  onBlur={(e) => blurInput(e, 'confirmPassWord')}
                />
                {!isShowEyeConfirm ? (
                  <span
                    className={`input-group-text cs ${
                      errorMessage.confirmPassWord ? 'is-invalid' : ''
                    }`}
                    onClick={() => setIsShowEyeConfirm(true)}>
                    <img src={EyeOn} alt="EyeOn" />
                  </span>
                ) : (
                  <span
                    className={`input-group-text cs ${
                      errorMessage.confirmPassWord ? 'is-invalid' : ''
                    }`}
                    onClick={() => setIsShowEyeConfirm(false)}>
                    <img src={EyeOff} alt="EyeOff" />
                  </span>
                )}
                {errorMessage.confirmPassWord ? (
                  <div className="invalid-feedback">{errorMessage.confirmPassWord}</div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </div>

        <div style={{ paddingLeft: 34 + 'px' }}>
          <Link to={'/information/terms_of_service_web'} target="_blank" rel="noopener noreferrer">
            会員登録には利用規約への同意が必要です。
          </Link>
        </div>

        <div className="action-login d-flex justify-content-center" onClick={handleSubmit}>
          <Button type="submit" className={`button-login ${!isSubmit ? 'disable-button' : ''}`}>
            {t('button_submit_register')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FormRegister
