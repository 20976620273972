import React from 'react'
import PropTypes from 'prop-types'
import ChevronBack from '@/assets/images/chevron-back.svg'
import { useTranslation } from 'react-i18next'

function DetailItemNotification({ dataItem, closeModalDetail }) {
  const { t } = useTranslation()
  return (
    <div className="detail-notification">
      <div className="header-notification d-flex align-items-center">
        <div className="back cs" onClick={closeModalDetail}>
          <img src={ChevronBack} alt="Chevron Back" />
        </div>
        <div className="title text-white text-center">{t('text_title_detail_news')}</div>
      </div>

      <div className="box-content-notfication">
        <div className="header-title-notification text-white">
          <div className="title line line-2">{dataItem?.title || ''}</div>
          <div className="date text-end">{dataItem?.time_created || ''}</div>
        </div>
        <div className="content text-white">{dataItem?.body || ''}</div>
      </div>
    </div>
  )
}

DetailItemNotification.propTypes = {
  closeModalDetail: PropTypes.func,
  dataItem: PropTypes.any
}

export default DetailItemNotification
