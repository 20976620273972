import { createSlice } from '@reduxjs/toolkit'
import { getCookie } from '@/utils/utils'
import { keyCookie, localStorageWeb, TAB_PROFILE } from '@/utils/constants'

const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    user: getCookie(keyCookie.user) ? JSON.parse(getCookie(keyCookie.user)) : null,
    token: getCookie(keyCookie.token) || null,
    isShowLogin: false,
    isShowRegister: false,
    isShowEmailVerify: false,
    avatarUser: localStorage.getItem(localStorageWeb.avatar_user) || '',
    onOffNotification: false,
    isShowForgotPassword: false,
    tabProfile: TAB_PROFILE.SETTING_PROFILE,
    isLoadUser: false,
    stripeTransactionList: []
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload
      state.user = user
      state.token = accessToken
    },
    logOut: (state) => {
      state.user = null
      state.token = null
    },
    setShowFormLogin: (state, action) => {
      state.isShowLogin = action.payload
    },
    setShowFormRegister: (state, action) => {
      state.isShowRegister = action.payload
    },
    setShowEmailVerify: (state, action) => {
      state.isShowEmailVerify = action.payload
    },
    setAvatarUser: (state, action) => {
      state.avatarUser = action.payload
    },
    setOnOffNotification: (state, action) => {
      state.onOffNotification = action.payload
    },
    setTokenLogin: (state, action) => {
      state.token = action.payload
    },
    setIsShowForgotPassword: (state, action) => {
      state.isShowForgotPassword = action.payload
    },
    setTabProfile: (state, action) => {
      state.tabProfile = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setIsLoadUser: (state, action) => {
      state.isLoadUser = action.payload
    },
    setImageUser: (state, action) => {
      state.imageUser = action.payload
    },
    setStripeTransactionList: (state, action) => {
      state.stripeTransactionList = action.payload
    }
  }
})

export const {
  setCredentials,
  logOut,
  setShowFormLogin,
  setShowFormRegister,
  setShowEmailVerify,
  setAvatarUser,
  setOnOffNotification,
  setTokenLogin,
  setIsShowForgotPassword,
  setTabProfile,
  setUser,
  setIsLoadUser,
  setStripeTransactionList
} = AuthSlice.actions

export default AuthSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
export const selectIsShowLogin = (state) => state.auth.isShowLogin
export const selectIsShowRegister = (state) => state.auth.isShowRegister
export const selectIsShowEmailVerify = (state) => state.auth.isShowEmailVerify
export const selectAvatarUser = (state) => state.auth.avatarUser
export const selectOnOffNotification = (state) => state.auth.onOffNotification
export const selectIsShowForgotPassword = (state) => state.auth.isShowForgotPassword
export const selectTabProfile = (state) => state.auth.tabProfile
export const selectIsLoadUser = (state) => state.auth.isLoadUser
export const selectStripeTransactionList = (state) => state.auth.stripeTransactionList
