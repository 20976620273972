import React, { useState, useEffect } from 'react'
import '@/assets/scss/editProfile.scss'
import ChevronBack from '@/assets/images/chevron-back.svg'
import { t } from 'i18next'
import NoAvatar from '@/assets/images/no-avatar.svg'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCurrentUser,
  setUser,
  setTokenLogin,
  setAvatarUser,
  selectAvatarUser
} from '@/features/Auth/AuthSlice'
import { checkEmail, checkPassWord } from '@/utils/validate'
import ModalConfirm from '@/components/common/ModalConfirm'
import { setShowToastError, setIsLoading } from '@/features/Common/CommonSlice'
import { db, logInWithEmailAndPassword } from '@/config/firebase'
import { updateDoc, doc } from 'firebase/firestore'
import { getStorage, ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage'
import { updateEmail } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { localStorageWeb, ERROR_FIREBASE } from '@/utils/constants'

function EditProfile() {
  const [imageUpload, setImageUpload] = useState(null)
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState({
    userName: null,
    email: null,
    password: null
  })
  const [successInput, setSuccessInput] = useState({
    userName: false,
    password: false
  })
  const [isShowModal, setIsShowModal] = useState(false)
  const [isNextRouter, setIsNextRouter] = useState(false)
  const avatarUser = useSelector(selectAvatarUser)
  const userLogin = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const uploadImage = (e) => {
    const file = e.target.files[0]
    file.preview = URL.createObjectURL(file)
    setImageUpload(file)
  }
  const blurInput = (e, nameInput) => {
    const value = e.target.value
    switch (nameInput) {
      case 'email':
        if (!checkEmail(value.trim())) {
          if (value.trim()) {
            setErrorMessage((previous) => {
              return {
                ...previous,
                email: t('error_email')
              }
            })
          }
        }
        break
      case 'password':
        if (!checkPassWord(value)) {
          if (!value) {
            setErrorMessage((previous) => {
              return {
                ...previous,
                password: t('require_password')
              }
            })
          } else {
            setErrorMessage((previous) => {
              return {
                ...previous,
                password: t('error_password')
              }
            })
          }
        }
        break
      default:
        break
    }
  }

  const submitInput = () => {
    if (userName && userName.trim().length > 0 && userName !== userLogin.userName) {
      updateDataUser({ user_name: userName.trim() })
    }
    if (email && email.trim().length > 0 && checkEmail(email) && email !== userLogin.email) {
      setIsShowModal(true)
    }
  }
  const updateDataUser = async (payload) => {
    const docRef = doc(db, 'users', userLogin.id)
    updateDoc(docRef, payload)
      .then(() => {
        if (payload.user_name) {
          setUserName('')
          setErrorMessage((previous) => {
            return {
              ...previous,
              userName: null
            }
          })
          const userFirebase = {
            ...userLogin,
            userName: userName.trim()
          }
          dispatch(setUser(userFirebase))
        }
        if (payload.email) {
          setEmail('')
          setErrorMessage((previous) => {
            return {
              ...previous,
              email: null
            }
          })
          const userFirebase = {
            ...userLogin,
            email: email.trim()
          }
          dispatch(setUser(userFirebase))
          if (isNextRouter) {
            navigate('/profile')
            setIsNextRouter(false)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const actionClose = () => {
    setPassword('')
    setErrorMessage((previous) => {
      return {
        ...previous,
        password: null
      }
    })
    setIsShowModal(false)
  }
  useEffect(() => {
    let keyDownHandler = null
    if (password) {
      keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          actionConfirm()
        }
      }
      document.addEventListener('keydown', keyDownHandler)
    }

    return () => {
      if (keyDownHandler) {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [password])
  const actionConfirm = () => {
    if (!checkPassWord(password.trim())) {
      if (!password.trim()) {
        setErrorMessage((previous) => {
          return {
            ...previous,
            password: t('require_password')
          }
        })
      } else {
        setErrorMessage((previous) => {
          return {
            ...previous,
            password: t('error_password')
          }
        })
      }
      return
    }
    dispatch(setIsLoading(true))
    logInWithEmailAndPassword({ email: userLogin.email, password: password })
      .then(async (user) => {
        await updateEmail(user, email)
          .then(() => {
            updateDataUser({ email: email.trim() }).then(() => {
              dispatch(setTokenLogin(user.accessToken))
              actionClose()
            })
          })
          .catch((err) => {
            if (err.code === ERROR_FIREBASE.EMAIL_ALREADY) {
              dispatch(setShowToastError(t('email_already')))
            }
          })

        dispatch(setIsLoading(false))
      })
      .catch(() => {
        dispatch(setIsLoading(false))
        dispatch(setShowToastError(t('text_error_verify_password')))
      })
  }

  const uploadFile = (imageRef) => {
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        localStorage.setItem(localStorageWeb.avatar_user, url)
        dispatch(setAvatarUser(url))
      })
    })
  }

  const submitEditProfile = async () => {
    dispatch(setIsLoading(true))
    const storage = getStorage()
    const desertRef = ref(storage, `users/avatar/${userLogin.id}.jpg`)
    if (imageUpload) {
      await deleteObject(desertRef)
        .then(() => {
          uploadFile(desertRef)
        })
        .catch(() => {
          uploadFile(desertRef)
        })
    }
    submitInput()
    if (email.trim()) {
      dispatch(setIsLoading(false))
      setIsNextRouter(true)
      return
    }
    dispatch(setIsLoading(false))
    navigate('/profile')
  }
  const backRouter = () => {
    navigate(-1)
  }

  useEffect(() => {
    setSuccessInput(() => {
      return {
        email: checkEmail(email.trim()) && email !== userLogin?.email,
        userName: userName.trim() && userName !== userLogin?.userName,
        password: checkPassWord(password.trim())
      }
    })
    setErrorMessage((previous) => {
      return {
        email: checkEmail(email.trim()) ? null : previous.email,
        userName: userName.trim() ? null : previous.userName,
        password: checkPassWord(password.trim()) ? null : previous.password
      }
    })
  }, [email, userName, password])

  return (
    <div className="page-edit-profile content-page-app">
      <div className="header-page-edit-profile d-flex align-items-center justify-content-between">
        <div className="back cs" onClick={backRouter}>
          <img src={ChevronBack} alt="Chevron Back" />
        </div>
        <div className="title text-white">{t('text_title_edit_profile')}</div>
        <div className="submit-edit cs" onClick={submitEditProfile}>
          {t('text_submit_edit_profile')}
        </div>
      </div>
      <div className="content-page-edit-profile">
        <form autoComplete="off">
          <div className="avatar pb-5">
            <label>
              <input type="file" hidden onChange={uploadImage} />
              {!imageUpload ? (
                <img
                  src={avatarUser}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = NoAvatar
                  }}
                  alt="no-avatar"
                />
              ) : (
                <img src={imageUpload.preview} alt="no-avatar" />
              )}
            </label>
          </div>
          <div className="box-input-edit-profile mb-3">
            <label className="w-100 position-relative d-flex align-items-center">
              <input
                type="text"
                className={`form-control ${successInput?.userName ? 'box-success-input' : ''}`}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder={userLogin?.userName}
                onBlur={(e) => blurInput(e, 'userName')}
              />
              <div
                className={`submit-input-edit cs ${successInput?.userName ? 'successInput' : ''}`}
                onClick={submitInput}>
                {t('text_submit_input')}
              </div>
            </label>
          </div>
          <div className="box-input-edit-profile mb-3">
            <label className="w-100 position-relative d-flex align-items-center">
              <input
                type="email"
                className={`form-control ${successInput?.email ? 'box-success-input' : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={userLogin?.email}
                onBlur={(e) => blurInput(e, 'email')}
              />
              <div
                onClick={submitInput}
                className={`submit-input-edit cs ${successInput?.email ? 'successInput' : ''}`}>
                {t('text_submit_input')}
              </div>
            </label>
            {errorMessage?.email ? (
              <div className="invalid-feedback d-block">{errorMessage?.email}</div>
            ) : (
              ''
            )}
          </div>
        </form>
      </div>
      <ModalConfirm
        titleModal={t('text_title_verify_password')}
        showModal={isShowModal}
        textCancel={t('text_action_cancel')}
        textConfirm={t('text_action_submit')}
        actionClose={actionClose}
        actionConfirm={actionConfirm}>
        <div className="text-description-verify-password px-4">
          {t('text_description_verify_password')}
        </div>
        <div className="p-4 verify-password-input">
          <form autoComplete="off">
            <div className="box-input">
              <input
                className={`form-control ${successInput?.password ? 'box-success-input' : ''}`}
                type="password"
                value={password}
                placeholder={t('placeholder_password')}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={(e) => blurInput(e, 'password')}
              />
              {errorMessage?.password ? (
                <div className="invalid-feedback d-block">{errorMessage?.password}</div>
              ) : (
                ''
              )}
            </div>
          </form>
        </div>
      </ModalConfirm>
    </div>
  )
}

export default EditProfile
