import React, { useEffect, useMemo } from 'react'
import ChevronBack from '@/assets/images/chevron-back.svg'
import { t } from 'i18next'
import { LINK_CONFIG_PROFILE } from '@/utils/constants'
import { useParams, useNavigate } from 'react-router-dom'
import { remoteConfig } from '@/config/firebase'
import { getValue, fetchAndActivate } from 'firebase/remote-config'
import { setIsLoading } from '@/features/Common/CommonSlice'
import { useDispatch } from 'react-redux'

import '@/assets/scss/infomation.scss'

remoteConfig.settings.minimumFetchIntervalMillis = 0

function DetailProfileConfig() {
  const { id } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const textTitle = useMemo(() => {
    switch (id) {
      case LINK_CONFIG_PROFILE.POLICY:
        return t('text_privacy_policy')
      case LINK_CONFIG_PROFILE.TEAM_OF_SERVICE:
        return t('text_terms_of_service')
      default:
        return ''
    }
  }, [id])
  useEffect(() => {
    if (id) {
      dispatch(setIsLoading(true))
      fetchAndActivate(remoteConfig)
        .then(() => {
          const data = getValue(remoteConfig, id)
          const dataClone = {
            ...data
          }
          const content = dataClone._value.replace(/\\n/g, '<br /> <br />')
          const contentHTML = document.getElementById('content-detail-profile-config')
          const backHome = `<div class="text-back-home text-center pt-5"><a href="/">${t(
            'text_back_home'
          )}</a><div>`
          contentHTML.innerHTML = content + backHome
          dispatch(setIsLoading(false))
        })
        .catch(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [id])
  const backrouter = () => {
    navigate(-1)
  }
  return (
    <div className="page-detail-profile-config content-page-app">
      <div className="header-detail-profile-config d-flex align-items-center">
        <div className="back cs" onClick={backrouter}>
          <img src={ChevronBack} alt="Chevron Back" />
        </div>
        <div className="title text-center text-white">{textTitle}</div>
      </div>
      <div
        className="content-detail-profile-config padding-bottom-120"
        id="content-detail-profile-config"></div>
    </div>
  )
}

export default DetailProfileConfig
