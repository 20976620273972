import React from 'react'
import PropTypes from 'prop-types'
import { selectIsShowModalFullPage } from '@/features/Common/CommonSlice'
import { useSelector } from 'react-redux'

function ModalFullPage({ children }) {
  const isShowModal = useSelector(selectIsShowModalFullPage)
  return <div className={`modal-full-page ${isShowModal ? 'show' : ''}`}>{children}</div>
}

ModalFullPage.propTypes = {
  children: PropTypes.any
}

export default ModalFullPage
