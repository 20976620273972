import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { TAB_PROFILE, STATUS_STRIPE } from '@/utils/constants'
import {
  selectCurrentToken,
  selectCurrentUser,
  selectStripeTransactionList
} from '@/features/Auth/AuthSlice'
import { useSelector, useDispatch } from 'react-redux'
import { db } from '@/config/firebase'
import { collection, query, onSnapshot } from 'firebase/firestore'
import { setIsLoading } from '@/features/Common/CommonSlice'
import { getTimeStartAndTimeEnd } from '@/utils/utils'
import { selectListSession, setListSession } from '@/features/Sessions/SessionSlice'
import moment from 'moment'
import ItemSessionHistory from '@/components/profile/ItemSessionHistory'

function SessionHistory({ tabProfile }) {
  const token = useSelector(selectCurrentToken) || null
  const user = useSelector(selectCurrentUser) || null
  const dispatch = useDispatch()
  const listSession = useSelector(selectListSession) || []
  const stripeTransactionList = useSelector(selectStripeTransactionList) || []

  useEffect(() => {
    if (tabProfile === TAB_PROFILE.SESSION_HISTORY) {
      getDataSessionHistory()
    }
  }, [tabProfile, token])

  const getDataSessionHistory = async () => {
    dispatch(setIsLoading(true))
    const queryDataEvent = await query(collection(db, 'events'))
    await onSnapshot(queryDataEvent, (querySnapshot) => {
      const data = []
      querySnapshot.forEach((doc) => {
        const time = getTimeStartAndTimeEnd(doc.data().timestamps)
        const formatData = {
          id: doc.id,
          audienceIds: doc.data().audienceIDs || [],
          title: doc.data().title,
          price: doc.data().price,
          image: doc.data().coverImagePath,
          timeStart: time?.timeStart?.seconds * 1000,
          timeEnd: time?.timeEnd?.seconds * 1000,
          dateStart: moment(time?.timeStart?.seconds * 1000).format('YYYY/MM/DD'),
          timeString: `${moment(time?.timeStart?.seconds * 1000).format('HH:mm')} - ${moment(
            time?.timeEnd?.seconds * 1000
          ).format('HH:mm')}`,
          image_limit: doc.data().image_limit
        }
        data.push(formatData)
      })
      dispatch(setListSession(data))
    })
    dispatch(setIsLoading(false))
  }

  const dataSessionHistory = useMemo(() => {
    if (listSession && listSession.length > 0 && user) {
      const dataSession = listSession.filter((item) =>
        item.audienceIds.find((audienceId) => audienceId === user.id)
      )
      const dataSessionBought = stripeTransactionList?.filter(
        (item) => item.status === STATUS_STRIPE.SUCCEEDED
      )
      if (dataSessionBought && dataSessionBought.length > 0) {
        const dataSortSessionBought = dataSessionBought.sort((a, b) => {
          return b.created_date.seconds - a.created_date.seconds
        })
        const data = []
        dataSortSessionBought.forEach((item) => {
          const dataFind = dataSession.find((itemSession) => itemSession.id === item.event_id)
          const check = data.find((itemCheck) => itemCheck.id === item.event_id)
          if (dataFind && !check) {
            data.push(dataFind)
          }
        })
        return data
      }

      return []
    }
    return []
  }, [listSession, user, stripeTransactionList])

  return (
    <div className="list-point-history">
      {dataSessionHistory && dataSessionHistory.length > 0
        ? dataSessionHistory.map((item) => {
            return (
              <div className="w-100 cs" key={item.id}>
                <ItemSessionHistory dataItem={item} />
              </div>
            )
          })
        : ''}
    </div>
  )
}
SessionHistory.propTypes = {
  tabProfile: PropTypes.any
}

export default SessionHistory
