import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import { useTranslation } from 'react-i18next'
import {
  selectIsShowModalFullPage,
  setIsLoading,
  setIsShowModalFullPage
} from '@/features/Common/CommonSlice'
import { db } from '@/config/firebase'
import { collection, query, getDocs, where, documentId } from 'firebase/firestore'
import { STATUS_IMAGE_USER } from '@/utils/constants'
import '@/assets/scss/album.scss'
import ModalFullPage from '@/components/common/ModalFullPage'
import { getTimeStartAndTimeEnd } from '@/utils/utils'
import moment from 'moment'
import ListImageBySession from '@/components/album/ListImageBySession'
import CameliaDefault from '@/assets/images/camelia_default.png'

function Album() {
  const user = useSelector(selectCurrentUser) || null
  const isShowModal = useSelector(selectIsShowModalFullPage) || null
  const [dataItemSession, setDataItemSession] = useState(null)
  const [idEventUser, setIdEventUser] = useState([])
  const [idEventBought, setIdEventBought] = useState([])
  const [listSessionBoughtImage, setListSessionBoughtImage] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setIsLoading(true))
    if (idEventBought.length > 0 && listSessionBoughtImage.length > 0) {
      dispatch(setIsLoading(false))
    }
    const timeOut = setTimeout(() => {
      dispatch(setIsLoading(false))
    }, 1000)
    if (idEventBought.length > 0 && timeOut) {
      clearTimeout(timeOut)
    }
  }, [idEventBought, listSessionBoughtImage])

  useEffect(() => {
    if (user.id) {
      getDataIdEvent()
    }
  }, [user])

  const getDataIdEvent = async () => {
    const q = query(collection(db, `users/${user.id}/events`))
    const getDocData = await getDocs(q)
    if (!getDocData.size) {
      dispatch(setIsLoading(false))
    }
    const dataId = []
    getDocData.forEach((doc) => {
      if (doc.id) {
        dataId.push(doc.id)
      }
    })
    setIdEventUser(dataId)
  }

  useEffect(() => {
    if (!idEventUser.length) return
    getDataImageBought()
  }, [idEventUser])

  const getDataImageBought = () => {
    idEventUser.forEach(async (item) => {
      const q = query(
        collection(db, `users/${user.id}/events/${item}/images`),
        where('status', '==', STATUS_IMAGE_USER.BOUGHT)
      )
      const queryImage = await getDocs(q)
      if (queryImage.size) {
        setIdEventBought((previous) => {
          if (!previous.find((val) => val === item)) return [...previous, item]
          return [...previous]
        })
      }
    })
  }
  useEffect(() => {
    if (!idEventBought.length) return
    getDataEventById()
  }, [idEventBought])

  const getDataEventById = async () => {
    const queryDataEvent = await query(
      collection(db, 'events'),
      where(documentId(), 'in', idEventBought)
    )
    const getDocEvents = await getDocs(queryDataEvent)
    const data = []
    getDocEvents.forEach((doc) => {
      if (doc.id) {
        const doc_data = doc.data()
        const { timestamps, audienceIDs, title, price, coverImagePath, image_limit } = doc_data
        const time = getTimeStartAndTimeEnd(timestamps)
        const formatData = {
          id: doc.id,
          audienceIds: audienceIDs || [],
          title: title,
          price: price,
          image: coverImagePath,
          timeStart: time?.timeStart?.seconds * 1000,
          timeEnd: time?.timeEnd?.seconds * 1000,
          dateStart: moment(time?.timeStart?.seconds * 1000).format('YYYY/MM/DD'),
          timeString: `${moment(time?.timeStart?.seconds * 1000).format('HH:mm')} - ${moment(
            time?.timeEnd?.seconds * 1000
          ).format('HH:mm')}`,
          image_limit: image_limit
        }
        data.push(formatData)
      }
    })
    setListSessionBoughtImage(data)
  }

  const dataAlbum = useMemo(() => {
    if (!listSessionBoughtImage.length) return []
    return listSessionBoughtImage?.sort((a, b) => {
      return b.timeStart - a.timeStart
    })
  }, [listSessionBoughtImage])

  const showListImageAlbum = (event, dataItem) => {
    event.preventDefault()
    setDataItemSession(dataItem)
    dispatch(setIsShowModalFullPage(true))
  }

  return (
    <div className="page-album position-relative">
      {isShowModal ? (
        <ModalFullPage>
          <ListImageBySession dataItem={dataItemSession} />
        </ModalFullPage>
      ) : (
        ''
      )}
      <div className="content-page-app">
        <div className="header-content-album">
          <div className="title-page text-white text-center">{t('album')}</div>
          <div className="description-page text-center text-white">{t('text_album')}</div>
        </div>
        <div className="list-album padding-bottom-120">
          {dataAlbum && dataAlbum.length > 0
            ? dataAlbum.map((item) => {
                return (
                  <div
                    className="box-album position-relative"
                    key={item.id}
                    onClick={(e) => showListImageAlbum(e, item)}>
                    <div className="image">
                      <img
                        src={item.image}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = CameliaDefault
                        }}
                        alt={item.title}
                      />
                    </div>
                    <div className="title position-absolute text-white line line-2">
                      {item.title}
                    </div>
                  </div>
                )
              })
            : ''}
        </div>
      </div>
    </div>
  )
}

export default Album
