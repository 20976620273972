import React, { memo, useMemo } from 'react'
import { selectListSession } from '@/features/Sessions/SessionSlice'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectCurrentToken } from '@/features/Auth/AuthSlice'
import ItemSession from '@/components/home/ItemSession'
import { useTranslation } from 'react-i18next'

function FeatureSession() {
  const listSession = useSelector(selectListSession) || []

  const user = useSelector(selectCurrentUser) || null
  const token = useSelector(selectCurrentToken) || null

  const { t } = useTranslation()

  const feature = useMemo(() => {
    const timeNow = new Date().getTime()
    let result = {}
    if (listSession) {
      const arrClone = [...listSession]
      const arrData = arrClone.filter((item) => item.timeEnd >= timeNow)
      if (arrData && arrData.length > 0) {
        if (user && user.id) {
          const data = []
          arrData.forEach((element) => {
            if (!element.audienceIds.includes(user.id)) {
              data.push(element)
            }
          })
          if (data && data.length > 0) {
            result = data.sort((a, b) => {
              return a.timeStart - b.timeStart
            })[0]
          }
        } else {
          result = arrData.sort((a, b) => {
            return a.timeStart - b.timeStart
          })[0]
        }
      }
      return result
    }
  }, [listSession, token])

  return (
    <>
      {feature && Object.keys(feature).length > 0 ? (
        <div className="feature-session my-3">
          <div className="header-list-session-home d-flex align-items-center mb-3">
            <h3 className="title-home w-100">{t('text_feature')}</h3>
          </div>
          <ItemSession dataItem={feature} />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default memo(FeatureSession)
