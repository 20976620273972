import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const PointSpend = ({ pointNeeded, userPoint }) => {
  const isNotEnoughPoint = pointNeeded > userPoint
  return (
    <div className="point_spend_container d-flex justify-content-center">
      <div className="p-2">
        {t('possession_points')}
        {
          isNotEnoughPoint && <Link to="/point"></Link> // Todo: show button buy point
        }
      </div>
      <div className="align-self-center"></div>
      <div className="p-2">
        <span className={`point-needed ${isNotEnoughPoint ? 'text-danger' : ''}`}>
          {pointNeeded}
        </span>
        /{userPoint}P
      </div>
    </div>
  )
}
PointSpend.propTypes = {
  pointNeeded: PropTypes.number.isRequired,
  userPoint: PropTypes.number.isRequired
}
export default PointSpend
