const routers = {
  dashboard: '/',
  home: '/home',
  notification: '/notification',
  search: '/search',
  album: '/album',
  profile: '/profile',
  editProfile: '/profile/edit',
  detailSession: '/session/:id',
  point: '/point',
  information: 'information/:id',
  feedback: '/feedback'
}
export default routers
