import PropTypes from 'prop-types'

export const getXY = (e, zoom = 1) => {
  let x
  let y
  if (e.touches && e.touches.length) {
    x = e.touches[0].pageX
    y = e.touches[0].pageY
  } else {
    x = e.pageX
    y = e.pageY
  }
  const center = {
    x: (document.querySelector('html')?.offsetWidth ?? 0) / 2,
    y: ((document.querySelector('.lb-img')?.offsetHeight ?? 0) * zoom) / 2
  }

  return { x: x - center.x, y: y - center.y }
}
getXY.propTypes = {
  touches: PropTypes.any,
  pageX: PropTypes.any,
  pageY: PropTypes.any
}
