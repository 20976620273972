import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { storage, db } from '@/config/firebase'
import { getDownloadURL, ref } from 'firebase/storage'
import { STATUS_IMAGE_USER } from '@/utils/constants'
import {
  selectIsShowModalFullPage,
  setIsLoading,
  setIsShowModalFullPage
} from '@/features/Common/CommonSlice'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import '@/assets/scss/modalListImageSession.scss'
import ListImage from '@/components/album/ListImage'
import PreviewImage from '@/components/album/PreviewImage'
import { collection, getDocs, query, where } from 'firebase/firestore'

function ListImageBySession({ dataItem }) {
  const userLogin = useSelector(selectCurrentUser) || null
  const isShowModal = useSelector(selectIsShowModalFullPage) || null
  const [listImageBought, setListImageBought] = useState([])
  const [listImageThumbnail, setListImageThumbnail] = useState([])
  const [isShowPreviewImage, setIsShowPreviewImage] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (userLogin?.id && dataItem?.id && isShowModal) {
      getDataImage()
    }
  }, [isShowModal, dataItem, userLogin])

  const getDataImage = async () => {
    const q = query(
      collection(db, `users/${userLogin.id}/events/${dataItem.id}/images`),
      where('status', '==', STATUS_IMAGE_USER.BOUGHT)
    )
    const queryImage = await getDocs(q)
    const dataImage = []
    queryImage.forEach((doc) => {
      if (doc.data()) {
        dataImage.push(doc.data())
      }
    })
    setListImageBought(dataImage)
  }

  useEffect(() => {
    if (listImageBought && listImageBought.length > 0) {
      setIsLoading(true)
      listImageBought.forEach(async (item) => {
        const { thumbnail_path } = item
        const imageRef = ref(storage, thumbnail_path)
        getDownloadURL(imageRef)
          .then((url) => {
            const data = {
              id: item.id,
              path: url,
              title: item.title,
              pathPreview: item.path,
              created_date: item?.created_date?.seconds,
              updated_date: item?.updated_date?.seconds
            }
            setListImageThumbnail((previous) => {
              const check = previous.find((dataCheck) => dataCheck.id === data.id)
              if (!check) {
                return [...previous, data]
              }
              return [...previous]
            })
          })
          .catch(console.error)
      })
      setIsLoading(false)
    }
  }, [listImageBought])

  const dataImage = useMemo(() => {
    const data = []
    if (listImageThumbnail && listImageThumbnail.length > 0) {
      listImageBought.forEach((item) => {
        const check = listImageThumbnail.find((itemImage) => itemImage.id === item.id)
        if (check) {
          data.push(check)
        }
      })
    }
    return data
  }, [listImageBought, listImageThumbnail])

  const closeModal = () => {
    setListImageBought([])
    setListImageThumbnail([])
    dispatch(setIsShowModalFullPage(false))
  }
  const handleBoxImage = (dataImagePreview) => {
    setIsShowPreviewImage(true)
    setImagePreview(dataImagePreview)
  }
  const closePreview = () => {
    setIsShowPreviewImage(false)
    setImagePreview(null)
  }
  return (
    <div className="list-image-session-modal">
      {!isShowPreviewImage ? (
        <ListImage
          dataItem={dataItem}
          dataImage={dataImage}
          closeModal={closeModal}
          actionClickBoxImage={handleBoxImage}
        />
      ) : (
        <PreviewImage
          dataPreview={imagePreview}
          isShowPreviewImage={isShowPreviewImage}
          closePreview={closePreview}
          dataItem={dataItem}
        />
      )}
    </div>
  )
}

ListImageBySession.propTypes = {
  dataItem: PropTypes.any
}
export default ListImageBySession
