import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const YourCart = ({ setIsClickedBuy, data, dataSession }) => {
  const { t } = useTranslation()
  let { paymentIntentKey, price, currency } = data || {}
  let { title } = dataSession || {}
  let isDisableButton = !paymentIntentKey

  const getButtonText = () => (isDisableButton ? t('text_loading') : t('text_buy'))

  return (
    <div className="content-container">
      <h1 className="text-white text-center mt-5 mb-0">{t('text_your_cart')}</h1>
      <div className="box-price d-flex align-items-center your-cart-wrapper">
        <span className="icon">
          <i className="bi bi-ticket"></i>
        </span>
        <div className="title">{title ?? ''}</div>
        <div className="price">
          {currency ?? ' '} {price ?? '- - -'}
        </div>
      </div>
      <div className="action-button-session text-center">
        <button
          className={`camelia-button camelia-button-filled ${
            isDisableButton ? 'disabled-button' : 'orange-button'
          }`}
          disabled={isDisableButton}
          onClick={() => setIsClickedBuy(true)}>
          {getButtonText()}
        </button>
      </div>
    </div>
  )
}

YourCart.propTypes = {
  setIsClickedBuy: PropTypes.func,
  data: PropTypes.any,
  dataSession: PropTypes.any
}

export default YourCart
