import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import Close from '@/assets/images/icons/close.svg'

function ListVideoPlayback({
  show,
  actionChangeVideo,
  listVideo,
  actionCloseChangeVideo,
  timeStartVideo
}) {
  useEffect(() => {
    const timeNow = new Date().getTime()
    const timePlay = (timeNow - timeStartVideo) / 1000
    if (listVideo && listVideo.length > 0) {
      listVideo.forEach((item, index) => {
        const element = document.getElementById(`video-change-playback-${item.uid}${index}`)
        if (element) {
          element.type = `video/mp4`
          if (timePlay - item.duration > 0) {
            element.src = `${item.path}#t=${item.duration - 0.01}`
          } else {
            element.src = `${item.path}#t=${timePlay}`
          }
          setTimeout(() => {
            element.play()
          }, 0)
        }
      })
    }
    return () => {
      if (listVideo && listVideo.length > 0) {
        listVideo.forEach((item, index) => {
          const element = document.getElementById(`video-change-playback-${item.uid}${index}`)
          if (element) {
            setTimeout(() => {
              element.pause()
            }, 0)
          }
        })
      }
    }
  }, [show])
  return (
    <div className={`list-video-host ${show ? 'show' : ''}`}>
      <div className="content-list-video-host">
        <div className="action-close">
          <div className="action-close-button cs" onClick={actionCloseChangeVideo}>
            <img src={Close} alt="close" />
          </div>
        </div>
        <div className="list-video d-flex p-20px" id="list-video-playback">
          {listVideo && listVideo.length > 0
            ? listVideo.map((item, index) => {
                return (
                  <video
                    data-wf-ignore="true"
                    data-object-fit="cover"
                    playsInline
                    key={item.uid}
                    onClick={() => actionChangeVideo(item)}
                    id={`video-change-playback-${item.uid}${index}`}
                    className="box-video pr-15px"></video>
                )
              })
            : ''}
        </div>
      </div>
    </div>
  )
}

ListVideoPlayback.propTypes = {
  show: PropTypes.bool,
  actionChangeVideo: PropTypes.func,
  actionCloseChangeVideo: PropTypes.func,
  listVideo: PropTypes.array,
  timeStartVideo: PropTypes.number
}

export default memo(ListVideoPlayback)
