import React, { useEffect, useState } from 'react'
import './style.scss'
import TopActionBar from '@/components/ModalListImageSession/ImageViewer/TopActionBar'
import Thumbnails from '@/components/ModalListImageSession/ImageViewer/Thumbnails'
import BottomActionBar from '@/components/ModalListImageSession/ImageViewer/BottomActionBar'
import { useSwipeable } from 'react-swipeable'
import { getXY } from '@/components/ModalListImageSession/ImageViewer/utils'
import PropTypes from 'prop-types'
import ChevronBack from '@/assets/images/chevron-back.svg'
import { DEFAULT_LARGE_ZOOM, DEFAULT_ZOOM_STEP } from '@/utils/constants'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '@/config/firebase'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'

let previousTouch
const Lightbox = (props) => {
  let {
    eventId,
    viewImageId,
    listImage,
    TopBar,
    allowReset = true,
    allowThumbnail = true,
    onClose,
    setDataWhenBoughtImage
  } = props
  let initX = 0
  let initY = 0
  let lastX = 0
  let lastY = 0
  let _cont = React.createRef()

  const userLogin = useSelector(selectCurrentUser) || null
  const [loading, setLoading] = useState(false)
  const [moving, setMoving] = useState(false)
  const [current, setCurrent] = useState(viewImageId)
  const [transform, setTransform] = useState({ x: 0, y: 0, zoom: 1, rotate: 0 })
  const [listImageBuyRequest, setListImageBuyRequest] = useState([])
  const { x, y, zoom, rotate } = transform

  useEffect(() => {
    getDataImageBuyRequest()
  }, [])

  const createTransform = (x, y, zoom, rotate) =>
    `translate3d(${x}px,${y}px,0px) scale(${zoom}) rotate(${rotate}deg)`
  const shouldShowReset = () => x > 0 || y > 0 || zoom !== 1 || rotate !== 0
  const isReset = allowReset && shouldShowReset()
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isReset) return
      navigateImage('next')
    },
    onSwipedRight: () => {
      if (isReset) return
      navigateImage('prev')
    },
    delta: 10,
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
    swipeDuration: Infinity,
    touchEventOptions: { passive: true }
  })

  const startMove = (e) => {
    if (zoom <= 1) return false
    setMoving(true)
    let _xy = getXY(e, zoom)
    initX = _xy.x - x
    initY = _xy.y - y
  }
  const duringMove = (e) => {
    if (!isReset || (!moving && !e.touches)) return false
    if (e.touches && e.touches.length) {
      const touch = e.touches[0]
      if (previousTouch) {
        // be aware that these only store the movement of the first touch in the touches array
        e.movementX = touch.pageX - previousTouch.pageX
        e.movementY = touch.pageY - previousTouch.pageY
      }
      previousTouch = touch
    }

    lastX = transform.x + e.movementX - initX
    lastY = transform.y + e.movementY - initY
    setTransform({ ...transform, x: lastX, y: lastY })
  }
  const endMove = () => {
    setMoving(false)
  }
  const stopSideEffect = (e) => e.stopPropagation()
  const getCurrentImage = () => {
    if (!listImage) return null
    if (!multi) return listImage[0]
    const filterd = listImage?.filter((item) => item.id === current)
    return filterd === null ? null : filterd[0]
  }
  const resetZoom = () => {
    setTransform({ ...transform, x: 0, y: 0, zoom: 1 })
  }
  const shockZoom = (e) => {
    let {
      zoomStep = DEFAULT_ZOOM_STEP,
      allowZoom = true,
      doubleClickZoom = DEFAULT_LARGE_ZOOM
    } = props
    if (!allowZoom || !doubleClickZoom) return false
    stopSideEffect(e)
    if (zoom > 1) return resetZoom()
    const _z = (zoomStep < 1 ? Math.ceil(doubleClickZoom / zoomStep) : zoomStep) * zoomStep
    setTransform({ ...transform, zoom: _z })
  }
  const navigateImage = (direction, e) => {
    if (isReset) return
    if (e) stopSideEffect(e)
    let _current = 0
    let currentIndex = listImage?.indexOf(getCurrentImage()) ?? 0
    switch (direction) {
      case 'next':
        _current = currentIndex + 1
        break
      case 'prev':
        _current = currentIndex - 1
        break
    }
    if (_current >= listImage?.length) _current = 0
    else if (_current < 0) _current = listImage?.length - 1
    setCurrent(listImage[_current].id)
    reset()
    const { onNavigateImage } = props
    if (typeof onNavigateImage === 'function') {
      onNavigateImage(current)
    }
  }
  const reset = (e) => {
    if (e) stopSideEffect(e)
    setTransform({ x: 0, y: 0, zoom: 1, rotate: 0 })
  }
  const exit = (e) => {
    if (typeof onClose === 'function') return onClose(e)
    console.error(
      'No Exit function passed on prop: onClose. Clicking the close button will do nothing'
    )
  }
  const canvasClick = (e) => {
    let { clickOutsideToExit = true } = props
    if (clickOutsideToExit && zoom <= 1) return exit(e)
  }

  const multi = (listImage?.length ?? 0) > 1
  let imageUrl = getCurrentImage()?.url ?? ''
  let title = getCurrentImage()?.title ?? ''
  if (!imageUrl) {
    console.warn('Not showing lightbox because no image(s) was supplied')
    return null
  }

  const getDataImageBuyRequest = async () => {
    const q = query(
      collection(db, `image_buy_requests/`),
      where('event_id', '==', eventId),
      where('user_id', '==', userLogin.id)
    )
    const queryImage = await getDocs(q)
    const dataImage = []
    queryImage.forEach((doc) => {
      if (doc.data()) {
        const buyRequestData = doc.data()
        buyRequestData.image_id = doc.id
        dataImage.push(buyRequestData)
      }
    })
    setListImageBuyRequest(dataImage)
  }

  function renderNavigateControls() {
    if (isReset || !multi) return null
    return (
      <div className="mobile-controls">
        <img
          src={ChevronBack}
          alt="Previous"
          title="Previous"
          className="lb-button prev"
          onClick={(e) => navigateImage('prev', e)}></img>
        <img
          src={ChevronBack}
          alt="Next"
          title="Next"
          className="lb-button next"
          onClick={(e) => navigateImage('next', e)}></img>
      </div>
    )
  }

  return (
    <div className="lb-container">
      {TopBar}
      {!TopBar && (
        <TopActionBar
          {...props}
          title={title}
          exit={exit}
          setDataWhenBoughtImage={setDataWhenBoughtImage}
        />
      )}
      <div className={`lb-canvas ${loading ? ' lb-loading' : ''}`} {...handlers}>
        <div
          className="d-flex justify-content-center align-items-center"
          ref={_cont}
          onClick={(e) => canvasClick(e)}>
          <img
            draggable="false"
            style={{
              transform: createTransform(x, y, zoom, rotate),
              cursor: zoom > 1 ? 'grab' : 'unset',
              transition: moving ? 'none' : 'all 0.001s'
            }}
            onMouseDown={(e) => startMove(e)}
            onTouchStart={(e) => startMove(e)}
            onMouseMove={(e) => duringMove(e)}
            onTouchMove={(e) => duringMove(e)}
            onMouseUp={(e) => endMove(e)}
            onMouseLeave={(e) => endMove(e)}
            onTouchEnd={(e) => endMove(e)}
            onClick={(e) => stopSideEffect(e)}
            onDoubleClick={(e) => shockZoom(e)}
            onLoad={() => setLoading(false)}
            className={`lb-img ${loading ? ' lb-loading' : ''}`}
            title={title}
            src={imageUrl}
            alt={title}
          />
          {renderNavigateControls()}
        </div>
      </div>

      <BottomActionBar
        {...props}
        title={title}
        isReset={isReset}
        reset={reset}
        multi={multi}
        current={current}
        allowRotate={true}
        transform={transform}
        setTransform={setTransform}
        listImageBuyRequest={listImageBuyRequest}
      />
      {allowThumbnail && <Thumbnails {...props} current={current} setCurrent={setCurrent} />}
    </div>
  )
}
Lightbox.propTypes = {
  eventId: PropTypes.string,
  buyMode: PropTypes.string.isRequired,
  TopBar: PropTypes.element,
  allowThumbnail: PropTypes.bool,
  allowReset: PropTypes.bool,
  allowZoom: PropTypes.bool,
  viewImageId: PropTypes.any,
  listImage: PropTypes.array,
  keyboardInteraction: PropTypes.bool,
  doubleClickZoom: PropTypes.number,
  onNavigateImage: PropTypes.func,
  onClose: PropTypes.func,
  clickOutsideToExit: PropTypes.func,
  zoomStep: PropTypes.number,
  setDataWhenBoughtImage: PropTypes.func,
  refreshData: PropTypes.func
}
export default Lightbox
