import React, { memo } from 'react'
import Close from '@/assets/images/icons/close.svg'
import { useTranslation } from 'react-i18next'
import { auth } from '@/config/firebase'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsShowEmailVerify,
  setShowEmailVerify,
  setShowFormRegister
  // setAvatarUser,
  // setCredentials,
  // setShowFormLogin
} from '@/features/Auth/AuthSlice'
// import { logoutApp } from '@/utils/utils'
// import { setIsLoading } from '@/features/Common/CommonSlice'
// import { useNavigate } from 'react-router-dom'

function FormConfirmEmail() {
  const isShow = useSelector(selectIsShowEmailVerify) || false
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const navigate = useNavigate()

  const handleOnClick = () => {
    dispatch(setShowEmailVerify(false))
    dispatch(setShowFormRegister(false))
  }

  // noinspection JSCheckFunctionSignatures
  return (
    <div className={`form-confirm-email ${isShow ? 'show' : ''}`}>
      <div className="content-confirm-email">
        <div className="action-close">
          <div className="action-close-button" onClick={handleOnClick}>
            <img src={Close} alt="close" />
          </div>
        </div>
        <div className="title-confirm-email">{t('title_confirm_email')}</div>
        <div className="content">
          {t('content_confirm_email', { email: auth?.currentUser?.email })}
        </div>
        <div className="button-confirm">
          <button className="btn" onClick={handleOnClick}>
            {t('ok')}
          </button>
        </div>
      </div>
    </div>
  )
}

FormConfirmEmail.propTypes = {}

export default memo(FormConfirmEmail)
