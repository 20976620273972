import React from 'react'
import PropTypes from 'prop-types'

const Cond = (props) => {
  const { condition, children } = props
  if (!condition) return null
  return <React.Fragment>{children}</React.Fragment>
}
Cond.propTypes = {
  condition: PropTypes.any,
  children: PropTypes.any
}
export default Cond
