import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { t } from 'i18next'
import React from 'react'
import moment from 'moment'

export const PageSent = ({ subject }) => {
  const text_content = (
    <>
      <p>{t('template_sent1')}</p>
      <p>{t('template_sent2')}</p>
      <p>{`${t('template_sent3')} ${moment().format('YYYY年MM月DD日 hh時mm分ss秒')}`}</p>
      <p>
        {t('template_sent4')} {subject}
      </p>
      <p>{t('template_sent5')}</p>
      <p>{t('template_sent6')}</p>
    </>
  )
  return (
    <>
      <Form className="form-create-feedback">
        <Form.Group className="text-center mb-3" controlId="feedback-sent.Title">
          <Form.Label className="text-center">{t('text_feedback_sent')}</Form.Label>
        </Form.Group>
        <hr />
        <Form.Group className="mb-3 feedback-content" controlId="feedback-sent.Content">
          <Form.Label className="yuGothic text-content">{text_content}</Form.Label>
        </Form.Group>
        <hr />
        <div className="d-flex justify-content-center">
          <div className="text-back-home text-center pt-2">
            <a href="/">{t('text_back_home')}</a>
          </div>
        </div>
      </Form>
    </>
  )
}
PageSent.propTypes = {
  subject: PropTypes.string.isRequired
}
