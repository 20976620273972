import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Close from '@/assets/images/icons/close.svg'

export function RenderVideo({ itemVideo }) {
  const element = document.getElementById(itemVideo.uid)
  if (element) {
    element.style.width = '150px'
    element.style.height = '150px'
    element.style.padding = '15px'
    element.style.borderRadius = '8px'
    const remoteVideoTrack = itemVideo.user.videoTrack
    try {
      return remoteVideoTrack.play(element)
    } catch {
      console.error('not render')
    }
  }
}

function ListVideoHost({ show, actionChangeHost, listVideo, actionCloseChangeHost }) {
  return (
    <div className={`list-video-host ${show ? 'show' : ''}`}>
      <div className="content-list-video-host">
        <div className="action-close">
          <div className="action-close-button cs" onClick={actionCloseChangeHost}>
            <img src={Close} alt="close" />
          </div>
        </div>
        <div className="list-video d-flex">
          {listVideo && listVideo.length > 0
            ? listVideo.map((item) => {
                return (
                  <div
                    key={item.uid}
                    onClick={() => actionChangeHost(item)}
                    className="box-video"
                    id={item.uid}>
                    <RenderVideo itemVideo={item} />
                  </div>
                )
              })
            : ''}
        </div>
      </div>
    </div>
  )
}

ListVideoHost.propTypes = {
  show: PropTypes.bool,
  actionChangeHost: PropTypes.func,
  actionCloseChangeHost: PropTypes.func,
  listVideo: PropTypes.array
}
RenderVideo.propTypes = {
  itemVideo: PropTypes.any
}

export default memo(ListVideoHost)
