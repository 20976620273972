import { createSlice } from '@reduxjs/toolkit'

const PointSlice = createSlice({
  name: 'point',
  initialState: {
    listPoint: []
  },
  reducers: {
    setListPoint: (state, action) => {
      state.listPoint = action.payload
    }
  }
})

export const { setListPoint } = PointSlice.actions

export default PointSlice.reducer

export const selectListPoint = (state) => state.point.listPoint
