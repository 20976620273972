import React, { memo, useEffect, useMemo, useState } from 'react'
import { db } from '@/config/firebase'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { selectIsLoading, setIsLoading } from '@/features/Common/CommonSlice'
import {
  selectIsShowListSession,
  selectListSession,
  setListSession
} from '@/features/Sessions/SessionSlice'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import FeatureSession from '@/components/home/FeatureSession'
import NextSession from '@/components/home/NextSession'
import BoxSessionHome from '@/components/home/BoxSessionHome'
import ItemSession from '@/components/home/ItemSession'
import { getTimeStartAndTimeEnd } from '@/utils/utils'
import useWindowDimensions from '@/components/common/GetWindowDimensions'
import {
  selectIsShowEmailVerify,
  selectIsShowLogin,
  selectIsShowRegister
} from '@/features/Auth/AuthSlice'

function Home() {
  const isLoading = useSelector(selectIsLoading) || false
  const listSession = useSelector(selectListSession) || []
  const isShowListSession = useSelector(selectIsShowListSession) || false
  const [isShowData, setIsShowData] = useState(true)
  const { width } = useWindowDimensions()
  const isShowLogin = useSelector(selectIsShowLogin) || false
  const isShowRegister = useSelector(selectIsShowRegister) || false
  const isShowEmailVerify = useSelector(selectIsShowEmailVerify) || false

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isLoading && listSession.length === 0) {
      getDataEvent().catch(console.error)
    }
  }, [])

  useEffect(() => {
    if (width <= 480 && (isShowLogin || isShowRegister || isShowEmailVerify)) {
      setIsShowData(false)
    } else {
      setIsShowData(true)
      const element = document.getElementById('layout-content-page')
      if (element) {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          element.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        })
      }
    }
  }, [width, isShowLogin, isShowRegister, isShowEmailVerify])

  const getDataEvent = async () => {
    dispatch(setIsLoading(true))
    const queryDataEvent = await query(collection(db, 'events'))
    await onSnapshot(queryDataEvent, (querySnapshot) => {
      const data = []
      querySnapshot.forEach((doc) => {
        const doc_data = doc.data()
        if (doc_data.invisible === true) return
        const { timestamps, audienceIDs, title, price, coverImagePath, image_limit } = doc_data
        const time = getTimeStartAndTimeEnd(timestamps)
        const formatData = {
          id: doc.id,
          audienceIds: audienceIDs || [],
          title: title,
          price: price,
          image: coverImagePath,
          timeStart: time?.timeStart?.seconds * 1000,
          timeEnd: time?.timeEnd?.seconds * 1000,
          dateStart: moment(time?.timeStart?.seconds * 1000).format('YYYY/MM/DD'),
          timeString: `${moment(time?.timeStart?.seconds * 1000).format('HH:mm')} - ${moment(
            time?.timeEnd?.seconds * 1000
          ).format('HH:mm')}`,
          image_limit: image_limit
        }
        data.push(formatData)
      })
      dispatch(setListSession(data))
    })
    dispatch(setIsLoading(false))
  }

  const sortData = useMemo(() => {
    const dataClone = [...listSession]
    if (dataClone && dataClone.length > 0) {
      return dataClone.sort((a, b) => {
        return b.timeStart - a.timeStart
      })
    }
    return []
  }, [listSession])

  return (
    <>
      {isShowData ? (
        <div className="w-100 content-page-app">
          {!isShowListSession ? (
            <>
              <NextSession />
              <FeatureSession />
              <BoxSessionHome />
            </>
          ) : (
            <div className="padding-bottom-120">
              {sortData &&
                sortData.map((item) => {
                  return <ItemSession dataItem={item} key={item.id} />
                })}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default memo(Home)
