import React from 'react'
import PropTypes from 'prop-types'
import RCheckBox from '@/components/RCheckBox'
import { BUY_REQUEST_STATUS } from '@/utils/constants'

const Thumbnails = (props) => {
  const {
    listImage,
    current,
    setCurrent,
    selectedImages,
    setSelectedImages,
    listImageBuyRequest,
    selectedImageBuyRequests,
    setSelectedImageBuyRequests
  } = props

  return (
    <div className="lb-thumbnail">
      {listImage &&
        listImage.map((item, index) => {
          const isSelected =
            (selectedImages && selectedImages.includes(item.id)) ||
            (selectedImageBuyRequests && selectedImageBuyRequests.includes(item.id))
          const select = () => {
            if (selectedImageBuyRequests.length > 0) {
              // 購入申請が承認済の写真が選択されてる場合は何もしない
              return
            }
            if (isSelected) {
              setSelectedImages(selectedImages.filter((x) => x !== current))
              return
            }
            if (selectedImages.length === 0) setSelectedImages([current])
            else setSelectedImages([...selectedImages, current])
          }
          const selectImageBuyRequest = () => {
            if (selectedImages.length > 0) {
              // 購入申請したい写真が選択されてる場合は何もしない
              return
            }
            if (isSelected) {
              setSelectedImageBuyRequests(selectedImageBuyRequests.filter((x) => x !== current))
              return
            }
            if (selectedImageBuyRequests.length === 0) setSelectedImageBuyRequests([current])
            else setSelectedImageBuyRequests([...selectedImageBuyRequests, current])
          }
          const getImageBuyRequestInfo = (item) => {
            return listImageBuyRequest.find((request) => request.image_id === item)
          }
          const handleSelect = () => {
            const buyRequestedImage = getImageBuyRequestInfo(current)
            if (buyRequestedImage) {
              switch (buyRequestedImage.status) {
                case BUY_REQUEST_STATUS.REQUESTED:
                  break
                case BUY_REQUEST_STATUS.APPROVED:
                  selectImageBuyRequest()
              }
            } else {
              select()
            }
          }
          const isActive = current === item.id
          return (
            <div
              className="lb-thumbnail-container"
              key={`${item.id}_${index}_thumbnail`}
              onClick={() => {
                setCurrent(item.id)
              }}>
              <img
                className={`img ${isActive ? 'active' : ''}`}
                src={item.url}
                alt={item.title}
                id={`to_${item.id}_${index}_thumbnail`}
              />
              <RCheckBox
                onChange={handleSelect}
                isChecked={isSelected}
                className="lb-thumbnail-checkbox"
              />
            </div>
          )
        })}
    </div>
  )
}
Thumbnails.propTypes = {
  listImage: PropTypes.array,
  current: PropTypes.string,
  setCurrent: PropTypes.func,
  selectedImages: PropTypes.array,
  setSelectedImages: PropTypes.func,
  listImageBuyRequest: PropTypes.any,
  selectedImageBuyRequests: PropTypes.any,
  setSelectedImageBuyRequests: PropTypes.func
}
export default Thumbnails
