import ChevronBack from '@/assets/images/chevron-back.svg'
import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FeedbackPageIndex } from '@/pages/Profile/components/Feedback/Feedback'

export const FeedbackPage = (props) => {
  const navigate = useNavigate()
  const { pageIndex, page, setPage } = props

  function goBack() {
    if (page > 0) setPage(page - 1)
    else navigate(-1)
  }

  if (pageIndex !== page) return <></>
  return (
    <>
      <div className="header-feedback d-flex align-items-center justify-content-between">
        <div className="back cs" onClick={goBack}>
          {page !== FeedbackPageIndex.Sent ? <img src={ChevronBack} alt="Chevron Back" /> : null}
        </div>
        <div className="title text-white">{t('問い合わせ')}</div>
        <div></div>
      </div>
      <div className="body-feedback">{props.children}</div>
    </>
  )
}
FeedbackPage.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  children: PropTypes.any
}
