import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import React from 'react'
import PropTypes from 'prop-types'
import { FeedbackPageIndex } from '@/pages/Profile/components/Feedback/Feedback'
import { useTranslation } from 'react-i18next'

export const PageCreate = ({ setPage, setContent, setSubject, content, subject }) => {
  const { t } = useTranslation()
  const FeedbackSubject = [
    {
      type: 'Withdrawal',
      text: t('text_feedback_subject_withdrawal')
    },
    {
      type: 'Purchasing',
      text: t('text_feedback_subject_purchasing')
    },
    {
      type: 'How',
      text: t('text_feedback_subject_how')
    },
    {
      type: 'Photo',
      text: t('text_feedback_subject_photo')
    },
    {
      type: 'Other',
      text: t('text_feedback_subject_other')
    }
  ]

  function handleSubmit() {
    setPage(FeedbackPageIndex.Confirm)
  }

  return (
    <>
      <Form className="form-create-feedback">
        <Form.Group className="mb-3" controlId="feedback-create.Type">
          <Form.Label>{t('text_feedback_subject')}</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setSubject(e?.target?.value ?? '')}
            value={subject}>
            {FeedbackSubject?.map((item) => {
              return (
                <React.Fragment key={item.type}>
                  <option value={item.text}>{item.text}</option>
                </React.Fragment>
              )
            })}
          </Form.Select>
        </Form.Group>
        <hr />
        <Form.Group className="mb-3 feedback-content" controlId="feedback-create.Content">
          <Form.Label className="yuGothic">{t('text_feedback_content')}</Form.Label>
          <Form.Control
            as="textarea"
            className="content-area"
            placeholder={t('text_feedback_content_placeholder')}
            onChange={(e) => setContent(e?.target?.value ?? '')}
            value={content}
          />
        </Form.Group>
        <hr />
        <div className="d-flex justify-content-center py-2">
          <Button
            type="submit"
            className={`camelia-button camelia-button-filled`}
            disabled={content === ''}
            onClick={handleSubmit}>
            {t('button_feedback_confirm')}
          </Button>
        </div>
      </Form>
    </>
  )
}
PageCreate.propTypes = {
  setPage: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  setSubject: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired
}
