import React from 'react'
import Close from '@/assets/images/icons/close.svg'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function ModalDetailSession({ dataItem, isShow, handleClose }) {
  const { t } = useTranslation()
  const timeHappen = (dataItem.timeEnd - dataItem.timeStart) / (1000 * 60)
  return (
    <Modal show={isShow} className="modal-detail-session w-100" centered>
      <Modal.Header className="header-modal-detail-session">
        <div className="content-header-modal">
          <div className="action-close-button" onClick={handleClose}>
            <img src={Close} alt="close" />
          </div>
          <div className="title-modal-detail-session">{dataItem.title}</div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="description-detail-session">{dataItem.description}</div>
        <div className="info-session">
          <div className="py-3">{t('text_price')}</div>
          <div className="time-happen mb-1">
            {`${t('shooting_time')} : ${timeHappen} ${t('text_minute')}`}
          </div>
          <div className="number-photo mb-1">
            {`${t('text_number_photo')} : ${dataItem.total_free_image} ${t('text_more_than')}`}
          </div>
          <div className="time-happen">
            {`${t('text_price')} : ${new Intl.NumberFormat('ja-JP').format(dataItem.price)} ${t(
              'yen'
            )}`}
            <span className="pl-10px">{`(${t('text_tax_included')})`}</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ModalDetailSession.propTypes = {
  dataItem: PropTypes.any,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func
}

export default ModalDetailSession
