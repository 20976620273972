import IconHome from '@/assets/images/home.svg'
import IconNotification from '@/assets/images/notification.svg'
import IconSearch from '@/assets/images/search.svg'
import IconAlbum from '@/assets/images/album.svg'
import IconPerson from '@/assets/images/person.svg'
import routers from '@/routers/routers'

export const linkFooter = [
  {
    path: routers.dashboard,
    icon: IconHome
  },
  {
    path: routers.notification,
    icon: IconNotification
  },
  {
    path: routers.search,
    icon: IconSearch
  },
  {
    path: routers.album,
    icon: IconAlbum,
    isAuth: true
  },
  {
    path: routers.profile,
    icon: IconPerson,
    isAuth: true
  }
]

export const keyCookie = {
  user: 'user_app',
  token: 'token'
}

export const localStorageWeb = {
  avatar_user: 'avatar_user',
  CURRENT_TIME_WORK: 'CURRENT_TIME_WORK',
  TOKEN_REVOKE: 'TOKEN_REVOKE'
}

export const EVENT_TYPE = {
  PLAYBACK: 'PLAYBACK',
  LIVESTREAM: 'LIVESTREAM'
}

export const TAB_PROFILE = {
  SESSION_HISTORY: 'session_history',
  POINT_HISTORY: 'point_history',
  SETTING_PROFILE: 'setting_profile'
}

export const STATUS_STRIPE = {
  NEW: 'NEW',
  SUCCEEDED: 'SUCCEEDED',
  CANCELLED: 'CANCELLED'
}

export const STATUS_IMAGE_USER = {
  AVAILABLE: 'AVAILABLE',
  BOUGHT: 'BOUGHT'
}

export const DEFAULT_ZOOM_STEP = 0.3
export const DEFAULT_LARGE_ZOOM = 4

export const TIME_LENGTHS = [
  { seconds: 31536000, termEn: 'years ago', termJp: '年前' },
  { seconds: 2678400, termEn: 'months ago', termJp: 'か月前' },
  { seconds: 86400, termEn: 'days ago', termJp: '日前' },
  { seconds: 3600, termEn: 'hours ago', termJp: '時間前' },
  { seconds: 60, termEn: 'minutes ago', termJp: '分前' },
  { seconds: 1, termEn: 'seconds ago', termJp: '秒前' }
]

export const CONFIG = {
  IMAGE_PRICE: 'IMAGE_PRICE'
}

export const LINK_CONFIG_PROFILE = {
  POLICY: 'privacy_policy_web',
  TEAM_OF_SERVICE: 'terms_of_service_web'
}

export const ERROR_FIREBASE = {
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  EMAIL_ALREADY: 'auth/email-already-in-use',
  TOO_MANY_REQUESTS: 'auth/too-many-requests'
}

export const MailToDev = 'onlinecamera.developer@gmail.com'
export const LINK_QA = 'https://tayori.com/faq/8657d4a9bafeb66d5e32d680685aa81a42f962f2/'
export const LINK_COMPANY = 'https://gns.tokyo/'

export const LOGIN_STATE = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  REVOKE: 'revoke'
}

export const BUY_MODE = {
  NORMAL: 'NORMAL',
  REQUEST: 'REQUEST'
}

export const BUY_REQUEST_STATUS = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED'
}
