import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ChevronBack from '@/assets/images/chevron-back.svg'
import { storage } from '@/config/firebase'
import { getDownloadURL, ref } from 'firebase/storage'
import ModalConfirm from '@/components/common/ModalConfirm'
import { apiPost } from '@/config/httpRequest'
import { b64toBlob } from '@/utils/utils'
import Lightbox from '@/components/ModalListImageSession/ImageViewer'

function PreviewImage({ isShowPreviewImage, dataItem, dataPreview, closePreview }) {
  const { t } = useTranslation()
  const [urlImage, setUrlImage] = useState(null)
  const [isShowModalConfirm, setIsShowConfirm] = useState(false)

  useEffect(() => {
    if (dataPreview && Object.keys(dataPreview).length > 0) {
      const imageRef = ref(storage, dataPreview?.pathPreview)
      getDownloadURL(imageRef)
        .then((url) => {
          const data = {
            ...dataPreview,
            urlPreview: url
          }
          setUrlImage(data)
        })
        .catch(() => {
          const data = {
            ...dataPreview,
            urlPreview: null
          }
          setUrlImage(data)
        })
    }
  }, [dataPreview])

  const actionCloseConfirm = () => {
    setIsShowConfirm(false)
  }
  const downloadFile = (baseStr64, fileName) => {
    const aElement = document.createElement('a')
    aElement.setAttribute('download', fileName)
    const href = URL.createObjectURL(b64toBlob(baseStr64))
    aElement.href = href
    aElement.click()
    URL.revokeObjectURL(href)
  }
  const actionConfirm = async () => {
    let name = `${urlImage.title}.jpg`
    apiPost(`/api/v1/images/${urlImage.id}/download`).then((response) => {
      // downloadFile('http://localhost:3000/static/media/1667173943.a09ac47482481d02dd42.jpg', name)
      downloadFile(response.data.data, name)
    })
    setIsShowConfirm(false)
  }

  const renderTopBar = () => {
    return (
      <div className="header-list-image-session d-flex align-items-center justify-content-between">
        <div className="back cs" onClick={closePreview}>
          <img src={ChevronBack} alt="Chevron Back" />
        </div>
        <div className="title text-white">{dataItem?.title}</div>
        <div className={`action-page enable-button cs`} onClick={() => setIsShowConfirm(true)}>
          {t('text_download')}
        </div>
      </div>
    )
  }

  return (
    <div className="preview-image">
      {renderTopBar()}
      {urlImage && urlImage.urlPreview ? (
        <div className="image position-relative">
          {isShowPreviewImage && (
            <Lightbox
              TopBar={renderTopBar()}
              eventId={dataItem.id}
              allowCheck={false}
              allowThumbnail={false}
              showTitle={false}
              selectedImages={[urlImage.id]}
              viewImageId={urlImage.id}
              onClose={closePreview}
              listImage={[
                {
                  id: urlImage.id,
                  url: urlImage.urlPreview,
                  title: urlImage.title
                }
              ]}
            />
          )}
          <ModalConfirm
            titleModal={t('text_title_download_image')}
            textCancel={t('text_action_cancel')}
            textConfirm={t('text_action_submit')}
            showModal={isShowModalConfirm}
            actionClose={actionCloseConfirm}
            actionConfirm={actionConfirm}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

PreviewImage.propTypes = {
  dataPreview: PropTypes.any,
  isShowPreviewImage: PropTypes.bool,
  dataItem: PropTypes.any,
  closePreview: PropTypes.func
}

export default PreviewImage
