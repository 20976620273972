import axios from 'axios'
import { getCookie } from '@/utils/utils'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import { refreshToken } from '@/config/firebase'

export const baseUrl = process.env.REACT_APP_BASE_URL

function getHTTPHeader() {
  const token = getCookie('token')
  if (!_isEmpty(token)) {
    return {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*'
    }
  }
}

const httpRequest = axios.create({
  baseURL: baseUrl,
  headers: getHTTPHeader()
})

export const httpRequestNotToken = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
})

export function buildQueries(query) {
  let requestString = ''
  if (!query) {
    return requestString
  }
  if (query && Object.keys(query).length === 0) {
    return requestString
  }
  Object.keys(query).forEach((key) => {
    if (!_isNil(query[key]) && query[key] !== '') {
      requestString += `${key}=${query[key]}&`
    }
  })
  if (requestString[requestString.length - 1] === '&') {
    requestString = requestString.slice(0, -1)
  }
  return `?${requestString}`
}

const setTokenLogin = async () => {
  const token = await refreshToken()
  if (token) {
    httpRequest.defaults.headers = {
      'Content-Type': 'application/json', // !important
      Authorization: `Bearer ${token}`
    }
  }
}

export const apiGet = async (url, payload, query) => {
  await setTokenLogin()
  return new Promise((resolve, reject) => {
    httpRequest
      .get(`${url}${buildQueries(query)}`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}
export const apiPost = async (url, payload, query) => {
  await setTokenLogin()
  return new Promise((resolve, reject) => {
    httpRequest
      .post(`${url}${buildQueries(query)}`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}
export const apiPut = async (url, payload, query) => {
  await setTokenLogin()
  return new Promise((resolve, reject) => {
    httpRequest
      .put(`${url}${buildQueries(query)}`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}
export const apiDelete = async (url, payload, query) => {
  await setTokenLogin()
  return new Promise((resolve, reject) => {
    httpRequest
      .delete(`${url}${buildQueries(query)}`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}

export default httpRequest
