import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import { storage, db } from '@/config/firebase'
import { ref, getDownloadURL } from 'firebase/storage'
import { STATUS_IMAGE_USER } from '@/utils/constants'

import IconImage from '@/assets/images/icons/images.png'
import IconPhoto from '@/assets/images/action_photo.png'
import CameliaDefault from '@/assets/images/camelia_default.png'

import { collection, getDocs, query } from 'firebase/firestore'

function ItemSessionPointHistory({ dataItem }) {
  const { t } = useTranslation()
  const [listImageBought, setListImageBought] = useState([])
  const [urlImage, setUrlImage] = useState(null)
  const userLogin = useSelector(selectCurrentUser) || null

  useEffect(() => {
    if (userLogin?.id && dataItem?.id) {
      getDataImage()
    }
  }, [dataItem, userLogin])
  const getDataImage = async () => {
    const q = query(collection(db, `users/${userLogin.id}/events/${dataItem.id}/images`))
    const queryImage = await getDocs(q)
    const dataImage = []
    queryImage.forEach((doc) => {
      if (doc.data()) {
        dataImage.push(doc.data())
      }
    })
    setListImageBought(dataImage)
  }

  const statusSession = useMemo(() => {
    if (dataItem && Object.keys(dataItem).length > 0) {
      const timeNow = new Date().getTime()
      if (dataItem.timeEnd - timeNow < 0) {
        return t('text_status_session_finished')
      }
      if (dataItem.timeStart - timeNow < 0 && dataItem.timeEnd - timeNow > 0) {
        return t('text_status_session_happen')
      }
      if (dataItem.timeStart - timeNow > 0) {
        return t('text_status_session_soon')
      }
    }
  }, [dataItem])

  const imageBought = useMemo(() => {
    return listImageBought.filter((item) => item.status === STATUS_IMAGE_USER.BOUGHT).length
  }, [listImageBought])

  useEffect(() => {
    if (listImageBought && listImageBought.length > 0) {
      const imageRef = ref(storage, listImageBought[0].path)
      getDownloadURL(imageRef)
        .then((url) => {
          setUrlImage(url)
        })
        .catch(() => {
          setUrlImage(dataItem.image)
        })
    } else {
      setUrlImage(dataItem.image)
    }
  }, [listImageBought])

  return (
    <div className="box-item-point-history d-flex align-items-center justify-content-center">
      <div className="box-image">
        <img
          src={urlImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = CameliaDefault
          }}
          alt={dataItem.title}
        />
      </div>
      <div className="box-info-session text-white">
        <div className="date-status-session d-flex align-items-center">
          <div className="text-date">
            <div className="pr-8px">{`${t('text_date_point_history')} : ${
              dataItem.dateStart
            }`}</div>
            <div>{dataItem.timeString}</div>
          </div>
          <div
            className={`status-sesion-point ml-10px ${
              statusSession === t('text_status_session_finished') ? 'session-end' : ''
            }`}>
            <label className="text">{statusSession}</label>
          </div>
        </div>
        <div className="box-title line line-2 mb-10px">{dataItem.title}</div>
        <div className="box-info-image-photo d-flex align-items-center">
          <div className="box-image d-flex align-item-center">
            <img src={IconImage} alt="image-session" />
            <div className="number pl-10px d-flex align-items-center">{`${imageBought}/${
              listImageBought.length
            } ${t('text_sheet')}`}</div>
          </div>
          <div className="box-photo pl-20px d-flex align-item-center">
            <img src={IconPhoto} alt="photo-session" />
            <div className="number pl-10px d-flex align-items-center">{`${
              dataItem.image_limit || 0
            } ${t('text_sheet')}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

ItemSessionPointHistory.propTypes = {
  dataItem: PropTypes.any
}

export default ItemSessionPointHistory
