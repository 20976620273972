import React, { useEffect, useState } from 'react'
import ChevronBack from '@/assets/images/chevron-back.svg'
import { setIsLoading } from '@/features/Common/CommonSlice'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ModalConfirm from '@/components/common/ModalConfirm'
import ModalAlert from '@/components/common/ModalAlert'
import { apiPost } from '@/config/httpRequest'
import { BUY_MODE } from '@/utils/constants'

//function TopActionBar({ exit, title }) {
const TopActionBar = (props) => {
  let {
    title,
    exit,
    selectedImages,
    selectedImageBuyRequests,
    buyMode,
    setDataWhenBoughtImage,
    refreshData
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isShowModalConfirm, setIsShowConfirm] = useState(false)
  const [isShowAlertSuccess, setIsShowAlertSuccess] = useState(false)
  const [isStatusBoughtImage, setIsStatusBoughtImage] = useState(true)
  const [textBuyButton, setTextBuyButton] = useState('')
  const [textModalTitle, setTextModalTitle] = useState('')
  const [textBuySuccessTitle, setTextBuySuccessTitle] = useState('')
  const [textBuySuccess1, setTextBuySuccess1] = useState('')
  const [textBuySuccess2, setTextBuySuccess2] = useState('')
  const [textBuyError, setTextBuyError] = useState('')

  const actionCloseConfirm = () => {
    setIsShowConfirm(false)
  }
  const actionConfirm = async () => {
    dispatch(setIsLoading(true))

    // 購入申請の承認済みの写真を選択した場合、購入する
    if (selectedImageBuyRequests.length > 0) {
      buyImage(selectedImageBuyRequests)
      return
    }

    if ((selectedImages?.length ?? 0) <= 0) return

    // call api buy photo
    switch (buyMode) {
      case BUY_MODE.NORMAL:
        buyImage(selectedImages)
        break
      case BUY_MODE.REQUEST:
        await apiPost('/api/v1/images/request_buy', JSON.stringify(selectedImages)).finally(() => {
          setIsShowAlertSuccess(true)
          setIsStatusBoughtImage(true)
          setIsShowConfirm(false)
          dispatch(setIsLoading(false))
        })
        break
    }
  }
  const buyImage = async (listImage) => {
    await apiPost('/api/v1/images/buy', JSON.stringify(listImage))
      .then(() => {
        setIsShowAlertSuccess(true)
        setIsStatusBoughtImage(true)
      })
      .catch(() => {
        setIsStatusBoughtImage(false)
      })
      .finally(() => {
        setIsShowConfirm(false)
        dispatch(setIsLoading(false))
      })
  }
  const doneAlertSuccess = () => {
    setIsShowAlertSuccess(false)
    if (selectedImageBuyRequests.length > 0) {
      setDataWhenBoughtImage(selectedImageBuyRequests)
    } else if (selectedImages.length > 0) {
      setDataWhenBoughtImage(selectedImages)
    }
    refreshData()
  }
  const isEnableBuyButton = () => {
    return (
      (selectedImages && selectedImages.length > 0) ||
      (selectedImageBuyRequests && selectedImageBuyRequests.length > 0)
    )
  }
  useEffect(() => {
    if (selectedImageBuyRequests.length > 0) {
      useTextForBuy()
    } else {
      switch (buyMode) {
        case BUY_MODE.NORMAL:
          useTextForBuy()
          break
        case BUY_MODE.REQUEST:
          useTextForRequestBuy()
          break
      }
    }
  }, [selectedImageBuyRequests])
  const useTextForBuy = () => {
    setTextBuyButton(t('buy'))
    setTextModalTitle(t('text_title_buy_photo'))
    setTextBuySuccessTitle(t('text_title_buy_success'))
    setTextBuySuccess1(t('text_body_buy_success_1'))
    setTextBuySuccess2(t('text_body_buy_success_2'))
    setTextBuyError(t('text_body_buy_error'))
  }
  const useTextForRequestBuy = () => {
    setTextBuyButton(t('request_buy'))
    setTextModalTitle(t('text_title_request_buy_photo'))
    setTextBuySuccessTitle(t('text_title_request_buy_success'))
    setTextBuySuccess1(t('text_body_request_buy_success_1'))
    setTextBuySuccess2(t('text_body_request_buy_success_2'))
    setTextBuyError(t('text_body_request_buy_error'))
  }
  return (
    <React.Fragment>
      <div className="header-list-image-session d-flex align-items-center justify-content-between bg-black">
        <div className="back cs" onClick={exit}>
          <img src={ChevronBack} alt="Chevron Back" />
        </div>
        <div className="title text-white">{title}</div>
        <div className={`action-page ${isEnableBuyButton() ? 'enable-button' : ''}`}>
          <span onClick={() => setIsShowConfirm(true)}>{textBuyButton}</span>
        </div>
      </div>
      <ModalAlert
        titleModal={textBuySuccessTitle}
        textAlert={
          isStatusBoughtImage ? (
            <>
              <span>{textBuySuccess1}</span>
              <br />
              <span>{textBuySuccess2}</span>
            </>
          ) : (
            textBuyError
          )
        }
        showModal={isShowAlertSuccess}
        actionClose={() => doneAlertSuccess()}
      />
      <ModalConfirm
        titleModal={textModalTitle}
        textCancel={t('text_action_cancel')}
        textConfirm={t('text_action_submit')}
        showModal={isShowModalConfirm}
        actionClose={actionCloseConfirm}
        actionConfirm={actionConfirm}
      />
    </React.Fragment>
  )
}

TopActionBar.propTypes = {
  exit: PropTypes.func,
  title: PropTypes.string,
  buyMode: PropTypes.string,
  selectedImages: PropTypes.any,
  selectedImageBuyRequests: PropTypes.any,
  setDataWhenBoughtImage: PropTypes.func,
  refreshData: PropTypes.func
}

export default TopActionBar
