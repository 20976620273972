import React, { useState, useEffect } from 'react'
import { db } from '@/config/firebase'
import { collection, query, onSnapshot, getDoc, doc } from 'firebase/firestore'
import {
  setIsLoading,
  selectIsShowModalFullPage,
  setIsShowModalFullPage,
  setShowToastError
} from '@/features/Common/CommonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, getI18n } from 'react-i18next'
import moment from 'moment'
import { timeInUnitsFormat } from '@/utils/utils'

import ModalFullPage from '@/components/common/ModalFullPage'
import ItemNotification from '@/components/notification/ItemNotification'
import DetailItemNotification from '@/components/notification/DetailItemNotification'
import '@/assets/scss/notification.scss'
import { useNavigate } from 'react-router-dom'

function Notification() {
  const [listNotification, setListNotification] = useState([])
  const [dataDetail, setDataDetail] = useState(null)
  const isShowModal = useSelector(selectIsShowModalFullPage) || null

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const getDataNotification = async () => {
    dispatch(setIsLoading(true))
    try {
      const queryDataEvent = await query(collection(db, 'notifications'))
      await onSnapshot(queryDataEvent, (querySnapshot) => {
        const data = []
        querySnapshot.forEach((doc) => {
          const docData = doc.data()
          const time_created_seconds = moment(docData.time_created, 'DD/MM/YYYY HH:mm:ss').unix()
          const formatData = {
            id: doc.id,
            title: docData?.aps?.alert?.title,
            body: docData?.aps?.alert?.body,
            session_id: docData.session_id || null,
            url: docData.url || null,
            image: docData.fcm_options?.image || null,
            time_created: docData.time_created,
            time_created_seconds: time_created_seconds,
            time_diff: timeInUnitsFormat(time_created_seconds * 1000, getI18n().language)
          }
          data.push(formatData)
        })
        setListNotification(() => {
          return data.sort((a, b) => {
            return b.time_created_seconds - a.time_created_seconds
          })
        })
      })
    } catch {
      dispatch(setIsLoading(false))
    }
    dispatch(setIsLoading(false))
  }

  useEffect(() => {
    getDataNotification()
  }, [])
  const actionShowDetail = async (event, item) => {
    event.preventDefault()
    if (item.session_id) {
      const query = doc(db, 'events', item.session_id)
      const docSnap = await getDoc(query)
      if (docSnap.exists()) {
        navigate(`/session/${item.session_id}`)
      } else {
        dispatch(setShowToastError(t('text_not_event')))
      }
      return
    }
    if (item.url) {
      const a = document.createElement('a')
      a.href = item.url
      a.target = '_blank'
      a.click()
      return
    }
    setDataDetail(item)
    dispatch(setIsShowModalFullPage(true))
  }
  const closeModalDetail = () => {
    setDataDetail(null)
    dispatch(setIsShowModalFullPage(false))
  }
  return (
    <div className="page-notification position-relative">
      {isShowModal ? (
        <ModalFullPage>
          <DetailItemNotification dataItem={dataDetail} closeModalDetail={closeModalDetail} />
        </ModalFullPage>
      ) : (
        ''
      )}
      <div className="content-page-app">
        <div className="header-common">
          <div className="title-page text-white text-center">{t('news')}</div>
          <div className="description-page text-center text-white">{t('text_news')}</div>
        </div>
        <div className="list-notification pb-3">
          {listNotification && listNotification.length > 0
            ? listNotification.map((item) => {
                return (
                  <ItemNotification
                    dataItem={item}
                    key={item.id}
                    actionShowDetail={actionShowDetail}
                  />
                )
              })
            : ''}
        </div>
      </div>
    </div>
  )
}

export default Notification
