import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({ link, text }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className="text-white">
      {text}
    </a>
  )
}
ExternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}
export default ExternalLink
