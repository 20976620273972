import { initializeApp } from 'firebase/app'
import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  getIdToken,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import { getRemoteConfig } from 'firebase/remote-config'
import { getFirestore } from 'firebase/firestore'
import { getDatabase, onValue, ref as rRef, serverTimestamp, set } from 'firebase/database'
import { getStorage } from 'firebase/storage'
import { logoutApp, setToken } from '@/utils/utils'
import { LOGIN_STATE, localStorageWeb } from '@/utils/constants'

const firebaseConfig = {
  // eslint-disable-next-line no-undef
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
setPersistence(auth, browserSessionPersistence).catch(console.error)
const storage = getStorage(app)
const realtimeDb = getDatabase(app)

// We'll create two constants which we will write to
// the Realtime database when this device is offline
// or online.
export const offlineLoginSate = {
  is_online: LOGIN_STATE.OFFLINE,
  last_seen: serverTimestamp()
}

function setupPresence(uid) {
  if (!uid) return

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  const userStatusDatabaseRef = rRef(realtimeDb, '/status/' + uid)
  onValue(userStatusDatabaseRef, (snap) => {
    const status = snap.val()
    if (status?.is_online === LOGIN_STATE.REVOKE) {
      const curentToken = status.accessToken
      const cookieTokenRenoke = localStorage.getItem(localStorageWeb.TOKEN_REVOKE) || null
      if (curentToken && curentToken?.trim() == cookieTokenRenoke?.trim()) {
        setStatusUser(uid, LOGIN_STATE.ONLINE)
        localStorage.removeItem(localStorageWeb.TOKEN_REVOKE)
        return
      }
      logoutApp()
        .then(() => {
          if (document?.location?.href) document.location.href = '/'
          else if (window?.location?.href) window.location.href = '/'
          else location.reload()
        })
        .catch(console.error)
    }
  })
}

const setStatusUser = (uid, status, token = null) => {
  const userStatusDatabaseRef = rRef(realtimeDb, '/status/' + uid)
  set(userStatusDatabaseRef, {
    is_online: status,
    last_seen: serverTimestamp(),
    accessToken: token
  }).catch(console.error)
}

const logInWithEmailAndPassword = (form) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, form.email, form.password)
      .then(async (response) => {
        await localStorage.setItem(localStorageWeb.TOKEN_REVOKE, response?.user?.accessToken)
        await setStatusUser(response.user?.uid, LOGIN_STATE.REVOKE, response?.user?.accessToken)
        resolve(response.user)
      })
      .catch((error) => {
        reject(error.code)
      })
  })
}

const registerWithEmailAndPassword = (form) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, form.email, form.password)
      .then(async (response) => {
        await setStatusUser(response.user?.uid, LOGIN_STATE.ONLINE)
        resolve(response)
      })
      .catch((error) => {
        reject(error.code)
      })
  })
}
const sendPasswordReset = (email) => {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error.message)
      })
  })
}

const getDataUser = auth.onAuthStateChanged(async (user) => {
  if (user) {
    await refreshToken()
    return user
  }
})
const logout = () => {
  signOut(auth).catch(console.error)
}
const withdraw = () => {
  deleteUser(auth.currentUser).catch(console.error)
}

const refreshToken = async () => {
  const auth = getAuth()
  const { currentUser } = auth
  if (currentUser) {
    const token = await getIdToken(currentUser, true)
    if (token) {
      setToken(token)
      return token
    }
    return null
  }
  return null
}

const remoteConfig = getRemoteConfig(app)

export {
  auth,
  db,
  realtimeDb,
  setupPresence,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  getDataUser,
  logout,
  withdraw,
  storage,
  refreshToken,
  remoteConfig,
  setStatusUser
}
