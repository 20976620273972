import { t } from 'i18next'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Cond from '@/components/ModalListImageSession/ImageViewer/Cond'
import { DEFAULT_ZOOM_STEP, BUY_REQUEST_STATUS } from '@/utils/constants'

const BottomActionBar = (props) => {
  let {
    allowCheck = true,
    allowZoom = true,
    allowRotate = false,
    allowNavigate = false,
    buttonAlign = 'flex-end',
    showTitle = true,
    title,
    isReset,
    reset,
    multi,
    navigateImage,
    selectedImages,
    setSelectedImages,
    listImageBuyRequest,
    selectedImageBuyRequests,
    setSelectedImageBuyRequests,
    current,
    transform,
    setTransform
  } = props
  const { x, y, zoom, rotate } = transform

  const keyboardNavigation = (e) => {
    let { allowZoom = true } = props
    switch (e.key) {
      case 'ArrowLeft':
        if (zoom > 1) setTransform({ ...transform, x: x - 20 })
        else if (multi && zoom === 1) navigateImage('prev', e)
        break
      case 'ArrowRight':
        if (zoom > 1) setTransform({ ...transform, x: x + 20 })
        else if (multi && zoom === 1) navigateImage('next', e)
        break
      case 'ArrowUp':
        if (zoom > 1) setTransform({ ...transform, y: y + 20 })
        break
      case 'ArrowDown':
        if (zoom > 1) setTransform({ ...transform, y: y - 20 })
        break
      case '+':
        if (allowZoom) applyZoom('in')
        break
      case '-':
        if (allowZoom) applyZoom('out')
        break
    }
  }
  useEffect(() => {
    // bug keyboardInteraction => disable
    const keyboardInteraction = false
    if (keyboardInteraction) document.addEventListener('keyup', keyboardNavigation)

    const buyRequestedImage = getImageBuyRequestInfo(current)
    if (buyRequestedImage && buyRequestedImage.status == BUY_REQUEST_STATUS.REQUESTED) {
      allowCheck = false
    }
  }, [])

  const applyZoom = (type) => {
    let zoomStep = DEFAULT_ZOOM_STEP
    switch (type) {
      case 'in':
        setTransform({ ...transform, zoom: zoom + zoomStep })
        break
      case 'out':
        if (zoom - zoomStep < 1) break
        else if (zoom - zoomStep === 1) {
          setTransform({ ...transform, x: 0, y: 0, zoom: 1 })
        } else setTransform({ ...transform, zoom: zoom - zoomStep })
        break
      case 'reset':
        setTransform({ ...transform, x: 0, y: 0, zoom: 1 })
        break
    }
  }

  const applyRotate = (type) => {
    switch (type) {
      case 'cw':
        setTransform({ ...transform, rotate: rotate + 90 })
        break
      case 'acw':
        setTransform({ ...transform, rotate: rotate - 90 })
        break
    }
  }
  const select = () => {
    if (selectedImageBuyRequests.length > 0) {
      // 購入申請が承認済の写真が選択されてる場合は何もしない
      return
    }
    if (selected) {
      setSelectedImages(selectedImages.filter((x) => x !== current))
      return
    }
    if (selectedImages.length === 0) setSelectedImages([current])
    else setSelectedImages([...selectedImages, current])
  }
  const isSelected = () => {
    if (selectedImages?.length === 0) return false
    return selectedImages.includes(current)
  }
  const selectImageBuyRequest = () => {
    if (selectedImages.length > 0) {
      // 購入申請したい写真が選択されてる場合は何もしない
      return
    }
    if (selected) {
      setSelectedImageBuyRequests(selectedImageBuyRequests.filter((x) => x !== current))
      return
    }
    if (selectedImageBuyRequests.length === 0) setSelectedImageBuyRequests([current])
    else setSelectedImageBuyRequests([...selectedImageBuyRequests, current])
  }
  const isSelectedImageBuyRequest = () => {
    if (selectedImageBuyRequests?.length === 0) return false
    return selectedImageBuyRequests.includes(current)
  }
  const getImageBuyRequestInfo = (item) => {
    return listImageBuyRequest.find((request) => request.image_id === item)
  }
  const handleSelect = () => {
    const buyRequestedImage = getImageBuyRequestInfo(current)
    if (buyRequestedImage) {
      switch (buyRequestedImage.status) {
        case BUY_REQUEST_STATUS.REQUESTED:
          break
        case BUY_REQUEST_STATUS.APPROVED:
          selectImageBuyRequest()
      }
    } else {
      select()
    }
  }
  const ImageBuyRequestStatus = () => {
    const buyRequestedImage = getImageBuyRequestInfo(current)
    if (buyRequestedImage) {
      switch (buyRequestedImage.status) {
        case BUY_REQUEST_STATUS.REQUESTED:
          return (
            <div className="buy_request_status" style={{ top: -30 + 'px' }}>
              {t('request_buy_requested')}
            </div>
          )
        case BUY_REQUEST_STATUS.APPROVED:
          return (
            <div className="buy_request_status approved" style={{ top: -30 + 'px' }}>
              {t('request_buy_approved')}
            </div>
          )
      }
    }
  }
  const selected = isSelected() || isSelectedImageBuyRequest()
  return (
    <div className="lb-header" style={{ justifyContent: buttonAlign, position: 'relative' }}>
      {ImageBuyRequestStatus()}
      <Cond condition={showTitle && title}>
        <div
          className="lb-title"
          style={{
            display: buttonAlign === 'center' ? 'none' : 'flex',
            order: buttonAlign === 'flex-start' ? '2' : 'unset'
          }}>
          <span>{t('text_buy_image')}</span>
        </div>
      </Cond>
      <Cond condition={buttonAlign === 'center' || isReset}>
        <div
          title="Reset"
          style={{ order: buttonAlign === 'flex-start' ? '1' : 'unset' }}
          className={`lb-button lb-icon-reset lb-hide-mobile reload ${
            isReset ? '' : 'lb-disabled'
          }`}
          onClick={reset}></div>
      </Cond>
      <Cond condition={multi && allowNavigate}>
        <div
          title="Previous"
          className="lb-button lb-icon-arrow prev lb-hide-mobile"
          onClick={(e) => navigateImage('prev', e)}></div>
        <div
          title="Next"
          className="lb-button lb-icon-arrow next lb-hide-mobile"
          onClick={(e) => navigateImage('next', e)}></div>
      </Cond>
      <Cond condition={allowZoom}>
        <div
          title="Zoom In"
          className="lb-button zoom-in bi bi-plus-circle-fill"
          onClick={() => applyZoom('in')}></div>
        <div
          title="Zoom Out"
          className={`lb-button zoom-out bi bi-dash-circle-fill ${zoom <= 1 ? 'lb-disabled' : ''}`}
          onClick={() => applyZoom('out')}></div>
      </Cond>
      <Cond condition={allowRotate}>
        <div
          title="Rotate left"
          className="lb-button lb-icon-rotate rotate-l lb-hide-mobile"
          onClick={() => applyRotate('acw')}></div>
        <div
          title="Rotate right"
          className="lb-button lb-icon-rotate rotate-r lb-hide-mobile"
          onClick={() => applyRotate('cw')}></div>
      </Cond>
      <Cond condition={allowCheck}>
        <div title="Select" className="lb-button lb-button-check" onClick={() => handleSelect()}>
          <div>
            <label className={`select-container ${selected ? 'active' : ''}`}>
              <span className="select-checkmark"></span>
            </label>
          </div>
        </div>
      </Cond>
    </div>
  )
}
BottomActionBar.propTypes = {
  allowCheck: PropTypes.bool,
  allowZoom: PropTypes.bool,
  allowRotate: PropTypes.bool,
  allowNavigate: PropTypes.bool,
  buttonAlign: PropTypes.string,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  isReset: PropTypes.any,
  reset: PropTypes.func,
  multi: PropTypes.any,
  navigateImage: PropTypes.func,
  selectedImages: PropTypes.any,
  setSelectedImages: PropTypes.func,
  listImageBuyRequest: PropTypes.any,
  selectedImageBuyRequests: PropTypes.any,
  setSelectedImageBuyRequests: PropTypes.func,
  current: PropTypes.any,
  transform: PropTypes.any,
  setTransform: PropTypes.func
}
export default BottomActionBar
