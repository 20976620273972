import { createSlice } from '@reduxjs/toolkit'

const CommonSlice = createSlice({
  name: 'common',
  initialState: {
    isLoading: false,
    toastSuccess: null,
    toastError: null,
    redirectRouter: null,
    isShowModalFullPage: false,
    isLayoutScroll: false
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setShowToastSuccess: (state, action) => {
      state.toastSuccess = action.payload
    },
    setShowToastError: (state, action) => {
      state.toastError = action.payload
    },
    setRedirectRouter: (state, action) => {
      state.redirectRouter = action.payload
    },
    setIsShowModalFullPage: (state, action) => {
      state.isShowModalFullPage = action.payload
    },
    setIsLayoutScroll: (state, action) => {
      state.isLayoutScroll = action.payload
    }
  }
})
export const {
  setIsLoading,
  setShowToastSuccess,
  setShowToastError,
  setRedirectRouter,
  setIsShowModalFullPage,
  setIsLayoutScroll
} = CommonSlice.actions

export default CommonSlice.reducer

export const selectIsLoading = (state) => state.common.isLoading
export const selectToastSuccess = (state) => state.common.toastSuccess
export const selectToastError = (state) => state.common.toastError
export const selectRedirectRouter = (state) => state.common.redirectRouter
export const selectIsShowModalFullPage = (state) => state.common.isShowModalFullPage
export const selectIsLayoutScroll = (state) => state.common.isLayoutScroll
