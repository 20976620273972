import axios from 'axios'
import { keyCookie, localStorageWeb, TIME_LENGTHS } from '@/utils/constants'
import { logout, withdraw } from '@/config/firebase'
import React from 'react'
import { useLocation } from 'react-router-dom'

export const getCookie = (name) => {
  let dataToken = document.cookie.split(';')
  let nameToken = `${name}=`
  let token = ''
  for (const element of dataToken) {
    if (element.includes(nameToken)) {
      token = element
    }
  }
  if (token !== '') {
    token = token.split('=')[1]
    return token
  } else {
    return ''
  }
}

export const removeToken = () => {
  let token = 'Unauthorized'
  document.cookie = 'token=' + token + '; expires=Thu, 01 Jan 1970 00:00:00 UTC"; path=/'
  axios.defaults.headers.common['Authorization'] = 'Unauthorized'
}

export const setToken = (token) => {
  if (token) {
    document.cookie = 'token=Unauthorized; expires=Thu, 01 Jan 1970 00:00:00 UTC"; path=/'
    const time = setTimeCookie(7)
    document.cookie = `${keyCookie.token}=${token}; expires=${new Date(time)}; path=/`
  }
}

export const removeValueNameCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
}

export const setTimeCookie = (date) => {
  const now = new Date()
  const time = now.getTime()
  return time + date * 60 * 60 * 24
}

export const getTimeStartAndTimeEnd = (arrTime) => {
  if (arrTime && arrTime.length > 0) {
    const sort = arrTime.sort((a, b) => {
      return a.seconds * 1000 - b.seconds * 1000
    })
    return {
      timeStart: sort[0],
      timeEnd: sort[sort.length - 1]
    }
  }
  return {
    timeStart: null,
    timeEnd: null
  }
}

export const logoutApp = async () => {
  await removeValueNameCookie(keyCookie.token)
  await removeValueNameCookie(keyCookie.user)
  await localStorage.removeItem(localStorageWeb.avatar_user)
  await logout()
}

export const withdrawApp = async () => {
  await removeValueNameCookie(keyCookie.token)
  await removeValueNameCookie(keyCookie.user)
  await localStorage.removeItem(localStorageWeb.avatar_user)
  await withdraw()
}

export const handleSetTimeCountDown = (hours, minute, second) => {
  second--
  if (second <= 0) {
    second = 59
    minute--
    if (minute === 0) {
      minute = 59
      hours--
    }
  }
  if (hours === 0 && minute < 0 && second <= 59) {
    hours = 0
    minute = 0
    second = 0
  }
  if (hours.toString().length < 2) hours = `0${hours}`
  if (minute.toString().length < 2) minute = `0${minute}`
  if (second.toString().length < 2) second = `0${second}`
  return `${hours}:${minute}:${second}`
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function formatMoney(price, currency = 'JPY') {
  return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: currency }).format(price)
}

export function setScrollLayout(isScroll) {
  const element = document.getElementById('layout-content-page')
  if (!isScroll && element) {
    element.scrollTo(0, 0)
    element.style.overflow = 'hidden'
  }
  if (isScroll && element) {
    element.style.overflow = 'auto'
  }
}

export function remove_vietnamese_accents(str) {
  // Gộp nhiều dấu space thành 1 space
  str = str.replace(/\s+/g, ' ')
  // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
  str = str.trim()
  // bắt đầu xóa dấu tiếng việt  trong chuỗi
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = window.atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export function timeInUnitsFormat(time, language) {
  let data = []
  let now = new Date()
  let diffInSeconds = parseInt((now.getTime() - time) / 1000)
  for (let tl of TIME_LENGTHS) {
    if (diffInSeconds >= tl.seconds) {
      let num_of_times = Math.floor(diffInSeconds / tl.seconds)
      diffInSeconds = diffInSeconds % tl.seconds
      data.push(`${num_of_times} ${language === 'jp' ? tl.termJp : tl.termEn}`)
    }
  }
  if (data.length > 0) {
    return data[0]
  }
  return language === 'jp' ? '今' : 'Just now'
}

export function browserName() {
  let browserName = ''

  if (navigator.vendor.match(/google/i)) {
    browserName = 'chrome'
  } else if (navigator.vendor.match(/apple/i)) {
    browserName = 'safari'
  } else if (navigator.userAgent.match(/firefox\//i)) {
    browserName = 'firefox'
  } else if (navigator.userAgent.match(/edge\//i)) {
    browserName = 'edge'
  } else if (navigator.userAgent.match(/trident\//i)) {
    browserName = 'ie'
  } else {
    browserName = navigator.userAgent + '\n' + navigator.vendor
  }
  return browserName
}

export function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}
