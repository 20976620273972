import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Close from '@/assets/images/icons/close.svg'
import NoAvatar from '@/assets/images/no-avatar.svg'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import CameliaDefault from '@/assets/images/camelia_default.png'

function InfomationSession({
  dataSession,
  statusSession,
  showModalDetail,
  handleActionSession,
  isDisableButton,
  textButtonStatus
}) {
  const navigate = useNavigate()

  const textStatusSession = useMemo(() => {
    if (dataSession && Object.keys(dataSession).length > 0) {
      const timeNow = new Date().getTime()
      if (dataSession.timeEnd - timeNow < 0) {
        return t('text_status_session_finished')
      }
      if (dataSession.timeStart - timeNow < 0 && dataSession.timeEnd - timeNow > 0) {
        return t('text_status_session_happen')
      }
      if (dataSession.timeStart - timeNow > 0) {
        return t('text_status_session_soon')
      }
    }
  }, [dataSession])

  const closeDetail = () => {
    navigate(-1)
  }

  return (
    <>
      <div className="image-session-detail">
        <img
          src={dataSession?.image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = CameliaDefault
          }}
          alt={dataSession?.title}
        />
        <div className="status-session d-flex justify-content-center align-items-center">
          <div className="content-text w-60">
            <label className={`text ${statusSession}`}>{textStatusSession || ''}</label>
          </div>
        </div>
        <div className="box-info-time-session d-flex justify-content-center align-items-center">
          <div className="header-detail-session d-flex w-60 align-items-center">
            <div className="date-session">
              <span className="pr-15px">{`${t('text_date')} : ${dataSession?.dateStart}`}</span>
              <span>{dataSession?.timeString}</span>
            </div>
            {/*
            <div className="capacity-session pl-20px">{`${t('text_capacity_session')} : ${
              dataSession.audienceIds && dataSession.audienceIds.length > 0
                ? dataSession.audienceIds.length
                : 0
            }`}</div>
            */}
          </div>
        </div>
      </div>
      <div className="content-detail-session">
        <div className="box-header-content-detail-session">
          <div className="title text-white">{dataSession?.title}</div>
          <div className="action-close cs" onClick={closeDetail}>
            <img src={Close} alt="close" />
          </div>
        </div>
        <div className="info-user-host">
          <div className="box-info-host d-flex">
            <div className="image">
              <img src={NoAvatar} alt="avatar host" />
            </div>
            <div className="info-user pl-10px">
              <div className="name text-white">Organizer</div>
            </div>
          </div>
        </div>
        <div className="box-price d-flex align-items-center">
          <div className="number-price">{dataSession.price ? dataSession.price : '0'}</div>
          <div className="text-price">
            <div>{t('participation_fee')}</div>
            <div>{t('ten_thousand_yen')}</div>
          </div>
        </div>
        <div className="action-button-session text-center">
          <div className="view-detail">
            <button className="btn" onClick={showModalDetail}>
              {t('view_detail_session')}
            </button>
          </div>
          <div className="action_livestream">
            <button
              className={`btn ${isDisableButton ? 'disabled-button' : 'orange-button'}`}
              disabled={isDisableButton}
              onClick={handleActionSession}>
              {textButtonStatus}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
InfomationSession.propTypes = {
  dataSession: PropTypes.object,
  statusSession: PropTypes.string,
  handleActionSession: PropTypes.func,
  showModalDetail: PropTypes.func,
  isDisableButton: PropTypes.bool,
  textButtonStatus: PropTypes.string
}
export default InfomationSession
