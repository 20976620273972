import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  setIsShowListSession,
  selectIsShowListSession,
  setDataItemModalListImage
} from '@/features/Sessions/SessionSlice'
import { selectCurrentToken, setShowFormLogin, setTabProfile } from '@/features/Auth/AuthSlice'
import {
  setRedirectRouter,
  setIsShowModalFullPage,
  selectIsLayoutScroll,
  setIsLayoutScroll
} from '@/features/Common/CommonSlice'
import { useSelector, useDispatch } from 'react-redux'
import { linkFooter, TAB_PROFILE } from '@/utils/constants'
import routers from '@/routers/routers'
import { setScrollLayout } from '@/utils/utils'

function Footer() {
  const isShowListSession = useSelector(selectIsShowListSession)
  const isShowLayoutScroll = useSelector(selectIsLayoutScroll)
  const token = useSelector(selectCurrentToken)
  const pathname = useLocation().pathname
  const dispatch = useDispatch()
  const handleSetIsShowListSession = (path, isAuth) => {
    if ((path === routers.profile || path === routers.album) && !token && isAuth) {
      dispatch(setShowFormLogin(true))
      dispatch(setRedirectRouter(path))
    }
    if (isShowListSession) {
      dispatch(setIsShowListSession(false))
    }
  }

  useEffect(() => {
    const element = document.getElementById('container-page')
    if (element) {
      element.style.maxWidth = '768px'
    }

    if (isShowLayoutScroll) {
      dispatch(setIsLayoutScroll(true))
      setScrollLayout(true)
    }
    dispatch(setIsShowModalFullPage(false))
    dispatch(setTabProfile(TAB_PROFILE.SETTING_PROFILE))
    dispatch(setDataItemModalListImage(null))
    if (isShowListSession && pathname === routers.dashboard) {
      dispatch(setIsShowListSession(false))
    }
  }, [pathname])
  return (
    <div className="footer d-flex align-items-center justify-content-center" id="footer-app">
      <div className="layout-footer">
        <div className="list-item d-flex align-items-center justify-content-center">
          {linkFooter && linkFooter.length > 0
            ? linkFooter.map((item, key) => {
                return (
                  <div className="box-link" key={key}>
                    <Link
                      to={!token && item.isAuth ? '#' : item.path}
                      onClick={() => handleSetIsShowListSession(item.path, item.isAuth)}
                      className={pathname === item.path ? 'active' : ''}>
                      <img src={item.icon} alt={item.path} />
                    </Link>
                  </div>
                )
              })
            : ''}
        </div>
      </div>
    </div>
  )
}

export default Footer
