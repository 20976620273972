import { createSlice } from '@reduxjs/toolkit'

const SessionSlice = createSlice({
  name: 'session',
  initialState: {
    listSession: [],
    isShowListSession: false,
    isShowModalListImage: false,
    dataItemModalListImage: null,
    keyActiveSlide: 0,
    settingSlide: {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 3000
    }
  },
  reducers: {
    setListSession: (state, action) => {
      state.listSession = action.payload
    },
    setIsShowListSession: (state, action) => {
      state.isShowListSession = action.payload
    },
    setIsShowModalListImage: (state, action) => {
      state.isShowModalListImage = action.payload
    },
    setDataItemModalListImage: (state, action) => {
      state.dataItemModalListImage = action.payload
    },
    setKeyActiveSlide: (state, action) => {
      state.keyActiveSlide = action.payload
    },
    setSettingSlide: (state, action) => {
      state.settingSlide = {
        ...state.settingSlide,
        initialSlide: action.payload
      }
    }
  }
})

export const {
  setListSession,
  setIsShowListSession,
  setIsShowModalListImage,
  setDataItemModalListImage,
  setKeyActiveSlide,
  setSettingSlide
} = SessionSlice.actions

export default SessionSlice.reducer

export const selectListSession = (state) => state.session.listSession
export const selectIsShowListSession = (state) => state.session.isShowListSession
export const selectIsShowModalListImage = (state) => state.session.isShowModalListImage
export const selectDataItemModalListImage = (state) => state.session.dataItemModalListImage
export const selectKeyActiveSlide = (state) => state.session.keyActiveSlide
export const selectSettingSlide = (state) => state.session.settingSlide
