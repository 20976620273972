import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import React from 'react'
import { FeedbackPageIndex } from './Feedback'
import { Form } from 'react-bootstrap'
import { MailToDev } from '@/utils/constants'
import { t } from 'i18next'

export const PageConfirm = ({ setPage, subject, content }) => {
  function handleSubmit() {
    setPage(FeedbackPageIndex.Sent)
    window.open(`mailto:${MailToDev}?subject=${subject}&body=${content}`)
  }

  return (
    <>
      <Form className="form-create-feedback">
        <Form.Group className="text-center mb-3" controlId="feedback-confirm.Title">
          <Form.Label className="text-center">{t('text_feedback_confirm_question')}</Form.Label>
        </Form.Group>
        <hr />
        <Form.Group className="mb-3 feedback-content" controlId="feedback-confirm.Content">
          <Form.Label className="yuGothic text-content">{content}</Form.Label>
        </Form.Group>
        <hr />
        <div className="d-flex justify-content-center py-2">
          <Button
            type="submit"
            className="camelia-button camelia-button-filled"
            onClick={handleSubmit}>
            {t('button_send')}
          </Button>
        </div>
      </Form>
    </>
  )
}
PageConfirm.propTypes = {
  setPage: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}
