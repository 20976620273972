import React, { useEffect, useMemo, useState } from 'react'
import { selectIsLoading, setIsLoading } from '@/features/Common/CommonSlice'
import { selectListSession, setListSession } from '@/features/Sessions/SessionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '@/config/firebase'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { getTimeStartAndTimeEnd, remove_vietnamese_accents } from '@/utils/utils'
import moment from 'moment'
import ItemSession from '@/components/home/ItemSession'
import IconSearch from '@/assets/images/search.svg'
import { useTranslation } from 'react-i18next'

function Search() {
  const isLoading = useSelector(selectIsLoading) || false
  const listSession = useSelector(selectListSession) || []
  const [textSearch, setTextSearch] = useState('')

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (!(!isLoading && listSession.length === 0)) return
    getDataEvent().catch(console.error)
  }, [])

  const getDataEvent = async () => {
    dispatch(setIsLoading(true))
    const queryDataEvent = await query(collection(db, 'events'))
    await onSnapshot(queryDataEvent, (querySnapshot) => {
      const data = []
      querySnapshot.forEach((doc) => {
        const doc_data = doc.data()
        if (doc_data.invisible === true) return
        const { timestamps, audienceIDs, title, price, coverImagePath, image_limit } = doc_data
        const time = getTimeStartAndTimeEnd(timestamps)
        const formatData = {
          id: doc.id,
          audienceIds: audienceIDs || [],
          title: title,
          price: price,
          image: coverImagePath,
          timeStart: time?.timeStart?.seconds * 1000,
          timeEnd: time?.timeEnd?.seconds * 1000,
          dateStart: moment(time?.timeStart?.seconds * 1000).format('YYYY/MM/DD'),
          timeString: `${moment(time?.timeStart?.seconds * 1000).format('HH:mm')} - ${moment(
            time?.timeEnd?.seconds * 1000
          ).format('HH:mm')}`,
          image_limit: image_limit
        }
        data.push(formatData)
      })
      dispatch(setListSession(data))
    })
    dispatch(setIsLoading(false))
  }

  const dataSearch = useMemo(() => {
    let data = []
    if (textSearch) {
      listSession.forEach((item) => {
        if (
          remove_vietnamese_accents(item.title)
            .toUpperCase()
            .indexOf(remove_vietnamese_accents(textSearch).toUpperCase()) > -1
        ) {
          data.push(item)
        }
      })
    } else {
      data = listSession
    }
    const dataClone = [...data]
    if (dataClone && dataClone.length > 0) {
      return dataClone.sort((a, b) => {
        return b.timeStart - a.timeStart
      })
    }
    return []
  }, [textSearch, listSession])
  return (
    <div className="page-search w-100 content-page-app">
      <div className="header-content-search header-common">
        <div className="title-page text-white text-center">{t('text_session')}</div>
        <div className="description-page text-center text-white">{t('text_session_jp')}</div>
      </div>
      <div className="box-search input-group">
        <span className="input-group-text" id="search">
          <img src={IconSearch} alt="icon-search" />
        </span>
        <input
          type="text"
          className="form-control"
          aria-label="search"
          aria-describedby="search"
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
        />
      </div>
      <div className="list-session-search padding-bottom-120">
        {dataSearch && dataSearch.length > 0
          ? dataSearch.map((item) => {
              return (
                <div className="box-home mb-3" key={item.id}>
                  <ItemSession dataItem={item} />
                </div>
              )
            })
          : ''}
      </div>
    </div>
  )
}

export default Search
