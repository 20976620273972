import React, { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import { db } from '@/config/firebase'
import { collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore'
import ListVideoPlayback from '@/components/sessions/ListVideoPlayback'

import ChangeCamera from '@/assets/images/change_camera.png'
import ActionPhoto from '@/assets/images/action_photo.png'
import Close from '@/assets/images/icons/close.svg'
import { handleSetTimeCountDown } from '@/utils/utils'
import { STATUS_STRIPE } from '@/utils/constants'
import Logo from '@/assets/images/logo.svg'

function BroadcastScreenPlayback({ isShowBroadcast, dataItem, closeBroadcast, videoPlayback }) {
  const userLogin = useSelector(selectCurrentUser) || null
  const [isPhoto, setIsPhoTo] = useState(false)
  const [isShowListVideo, setIsShowListVideo] = useState(false)
  const [sizeImageRequest, setSizeImageRequest] = useState(0)
  const [timeCountDown, setTimeCountDown] = useState('00:00:00')
  const [videoCurrent, setVideoCurrent] = useState(null)
  const [isDisableCapture, setIsDisableCapture] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    let intervalSetTime = null
    getAmountImageRequest().catch(console.error)
    if (videoPlayback && videoPlayback.length > 0 && !videoCurrent) {
      setVideoCurrent(videoPlayback[0])
    }
    intervalSetTime = setInterval(() => {
      const checkEnd = dataItem.timeEnd - new Date().getTime()
      if (checkEnd > 0) {
        let now = moment(new Date())
        const expiration = moment(dataItem.timeEnd)
        const diff = expiration.diff(now)
        const diffDuration = moment.duration(diff)
        let hours = diffDuration.hours()
        if (diffDuration.days() >= 1) {
          hours = hours + 24 * diffDuration.days()
        }
        const minute = diffDuration.minutes()
        const second = diffDuration.seconds()

        if (hours === 0 && minute === 0 && second === 0) {
          setIsDisableCapture(true)
          stopVideo()
          clearInterval(intervalSetTime)
        }
        const dataSetTimeCountDown = handleSetTimeCountDown(hours, minute, second)
        setTimeCountDown(dataSetTimeCountDown)
      } else {
        setIsDisableCapture(false)
        if (intervalSetTime) {
          clearInterval(intervalSetTime)
        }
      }
    }, 1000)

    return () => {
      if (intervalSetTime) {
        clearInterval(intervalSetTime)
      }
    }
  }, [dataItem, isShowBroadcast, videoPlayback])

  const getAmountImageRequest = async () => {
    const q = await query(
      collection(db, 'crop_image_requests'),
      where('event_id', '==', dataItem.id),
      where('user_id', '==', userLogin.id)
    )
    await onSnapshot(q, (querySnapshot) => {
      setSizeImageRequest(querySnapshot.size)
    })
  }

  const actionCapture = async () => {
    if (amountImageRequest <= 0 || !videoCurrent || isDisableCapture) return
    setIsPhoTo(true)
    const data = {
      event_id: dataItem.id,
      broadcasters_id: videoCurrent.uid,
      user_id: userLogin.id,
      status: STATUS_STRIPE.NEW
    }
    if (videoCurrent && Object.keys(videoCurrent).length > 0) {
      const timeNow = new Date()
      const broadcasters = dataItem.broadcasters.find((item) => item.uid === videoCurrent.uid)
      if (broadcasters) {
        setTimeout(() => {
          setIsPhoTo(false)
        }, 100)
        data.time = timeNow - dataItem.timeStart
        try {
          const newDataCropRef = doc(collection(db, 'crop_image_requests'))
          await setDoc(newDataCropRef, data)
            .then(() => {
              console.info('success insert data crop_image_requests')
            })
            .catch(console.error)
        } catch (err) {
          console.error('err', err)
        }
      }
    }
  }

  const amountImageRequest = useMemo(() => {
    return dataItem.image_limit - sizeImageRequest
  }, [sizeImageRequest, dataItem])

  useEffect(() => {
    const video = document.getElementById('video-current')
    if (video) {
      video.type = 'video/mp4'
      const timeNow = new Date().getTime()
      let intervalVideo = null
      let timePlayVideo = (timeNow - dataItem.timeStart) / 1000
      intervalVideo = setInterval(() => {
        const setTimePlayVideo = (new Date().getTime() - dataItem.timeStart) / 1000
        if (setTimePlayVideo - videoCurrent.duration > 0) {
          setIsDisableCapture(true)
          if (intervalVideo) {
            clearInterval(intervalVideo)
          }
        }
      }, 1000)
      if (timePlayVideo - videoCurrent.duration > 0 && intervalVideo) {
        clearInterval(intervalVideo)
      }
      if (timePlayVideo - videoCurrent.duration >= 0) {
        video.src = `${videoCurrent.path}#t=${videoCurrent.duration - 0.01}`
      } else if (timePlayVideo - videoCurrent.duration < 0) {
        video.src = `${videoCurrent.path}#t=${timePlayVideo}`
      } else {
        video.src = `${videoCurrent.path}#t=${videoCurrent.duration - 0.01}`
      }
      setTimeout(() => {
        video.play()
      }, 0)
    }
    return () => {
      if (video) {
        setTimeout(() => {
          video.pause()
        }, 0)
      }
    }
  }, [videoCurrent])

  const stopVideo = () => {
    const video = document.getElementById('video-current')
    if (video) {
      setTimeout(() => {
        video.pause()
      }, 0)
    }
  }

  const changeVideo = () => {
    setIsShowListVideo(true)
  }
  const actionCloseChangeVideo = () => {
    setIsShowListVideo(false)
  }
  const actionChangeVideo = (dataItemVideo) => {
    setVideoCurrent(dataItemVideo)
    setIsShowListVideo(false)
  }
  return (
    <div className="w-100">
      <div className="event-video w-100 position-relative playback-video">
        <div id="streamer-audience" className={`${isPhoto ? 'bg-photo' : ''}`}>
          {videoCurrent ? (
            // eslint-disable-next-line react/no-unknown-property
            <video id="video-current" playsInline className="w-100"></video>
          ) : (
            ''
          )}
        </div>
        <div className="position-absolute logo-leave-stream">
          <img src={Logo} alt="Logo live stream" />
        </div>
        <div className="action-leave-stream position-absolute cs" onClick={closeBroadcast}>
          <img src={Close} alt="close" />
        </div>
        {!videoCurrent ? (
          <div className="text_wait_live_stream position-absolute w-100 text-white">
            {t('text_wait_live_stream')}
          </div>
        ) : (
          ''
        )}
        <div className="footer-camera-audience position-absolute start-0 w-100 d-flex align-items-center justify-content-between">
          <div
            className={`change-camera box-camera-audience d-flex align-items-center justify-content-center ${
              dataItem.broadcasters && dataItem.broadcasters.length > 1 ? '' : 'disable-change-host'
            }
            `}
            onClick={changeVideo}>
            <img src={ChangeCamera} alt="change camera" />
          </div>
          <div
            className={`action-photo box-camera-audience d-flex align-items-center justify-content-center ${
              !videoCurrent || amountImageRequest <= 0 || isDisableCapture
                ? 'disable-change-host'
                : ''
            }`}
            onClick={actionCapture}>
            <img src={ActionPhoto} alt="action photo" />
          </div>
          <div className="info-camera-audience box-camera-audience">
            <div className="box-info-camera-audience amount-image d-flex align-items-center px-10px">
              <span className="text fs-10px">{t('text_remaining_number')}</span>
              <span className="amount pl-10px">{`${amountImageRequest}/${dataItem.image_limit}`}</span>
            </div>
            <div className="time-video box-info-camera-audience d-flex align-items-center px-10px">
              <span className="text fs-10px">{t('text_time_remaining')}</span>
              <span className="time pl-10px">{timeCountDown}</span>
            </div>
          </div>
        </div>
        {isShowListVideo ? (
          <ListVideoPlayback
            show={isShowListVideo}
            listVideo={videoPlayback}
            actionCloseChangeVideo={actionCloseChangeVideo}
            actionChangeVideo={actionChangeVideo}
            timeStartVideo={dataItem.timeStart}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

BroadcastScreenPlayback.propTypes = {
  dataItem: PropTypes.any,
  isShowBroadcast: PropTypes.bool,
  closeBroadcast: PropTypes.func,
  videoPlayback: PropTypes.array
}

export default memo(BroadcastScreenPlayback)
