import React from 'react'
import ReactLoading from 'react-loading'

function BaseLoading() {
  const type = 'spin'
  const color = '#656565'
  return (
    <div className="loading">
      <ReactLoading className="base-loading" type={type} color={color} height={50} width={50} />
    </div>
  )
}

export default BaseLoading
