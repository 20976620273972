import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  selectOnOffNotification,
  setAvatarUser,
  setCredentials,
  setOnOffNotification,
  setShowFormLogin
} from '@/features/Auth/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import ModalConfirm from '@/components/common/ModalConfirm'
import Chevron from '@/assets/images/chevron.svg'
import { logoutApp, getCookie, withdrawApp } from '@/utils/utils'
import { setIsLoading } from '@/features/Common/CommonSlice'
import { LINK_CONFIG_PROFILE, LINK_QA, LINK_COMPANY, keyCookie } from '@/utils/constants'
import ExternalLink from '@/components/common/ExternalLink'
// import { getAuth } from 'firebase/auth'
import { ref as rRef, set } from 'firebase/database'
import { offlineLoginSate, realtimeDb } from '@/config/firebase'

function SettingProfile() {
  const { t } = useTranslation()
  const onOffNotification = useSelector(selectOnOffNotification) || false
  const [listSetting, setListSetting] = useState([])
  const [isShowModalConfirm, setIsShowConfirm] = useState(false)
  const [isShowWithdrawModalConfirm, setIsShowWithdrawModalConfirm] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const showModalConfirm = () => {
    setIsShowConfirm(true)
  }
  const showModalConfirmWithdraw = () => {
    setIsShowWithdrawModalConfirm(true)
  }
  useEffect(() => {
    const list = [
      {
        id: 'push_notification',
        value: t('text_push_notification'),
        textOff: t('text_off_notification'),
        textOn: t('text_on_notification'),
        isOnOff: true
      },
      {
        id: 'camera_settings',
        value: t('text_camera_settings')
      },
      {
        id: 'change_email',
        value: t('text_change_email'),
        isIconChevron: true,
        action: () => nextRouterProfile('/profile/edit')
      },
      {
        id: 'qa',
        value: t('text_qa'),
        isIconChevron: true,
        link: LINK_QA
      },
      {
        id: 'operating_company',
        value: t('operating_company'),
        isIconChevron: true,
        link: LINK_COMPANY
      },
      {
        id: 'inquiry',
        value: t('text_inquiry'),
        isIconChevron: true,
        action: () => nextRouterProfile(`/feedback`)
      },
      {
        id: LINK_CONFIG_PROFILE.TEAM_OF_SERVICE,
        value: t('text_terms_of_service'),
        isIconChevron: true,
        action: () => nextRouterProfile(`/information/${LINK_CONFIG_PROFILE.TEAM_OF_SERVICE}`)
      },
      {
        id: LINK_CONFIG_PROFILE.POLICY,
        value: t('text_privacy_policy'),
        isIconChevron: true,
        action: () => nextRouterProfile(`/information/${LINK_CONFIG_PROFILE.POLICY}`)
      },
      {
        id: 'withdraw',
        value: t('text_withdraw'),
        isIconChevron: true,
        action: showModalConfirmWithdraw
      },
      {
        id: 'logout',
        value: t('text_logout'),
        isIconChevron: true,
        action: showModalConfirm
      }
    ]
    setListSetting(list)
  }, [])
  const actionClose = () => {
    setIsShowConfirm(false)
    setIsShowWithdrawModalConfirm(false)
  }
  const actionConfirm = () => {
    dispatch(setIsLoading(true))
    const user = getCookie(keyCookie.user) ? JSON.parse(getCookie(keyCookie.user)) : null
    if (user?.id) {
      const userStatusDatabaseRef = rRef(realtimeDb, '/status/' + user.id)
      set(userStatusDatabaseRef, offlineLoginSate).catch(console.error)
    }
    logoutApp()
      .then(() => {
        setTimeout(() => {
          dispatch(
            setCredentials({
              user: null,
              accessToken: null
            })
          )
          dispatch(setShowFormLogin(false))
          dispatch(setAvatarUser(null))
          dispatch(setIsLoading(false))
          navigate('/')
        }, 1000)
      })
      .catch(() => {
        dispatch(setIsLoading(false))
      })
  }
  const actionConfirmWithdraw = () => {
    dispatch(setIsLoading(true))
    withdrawApp()
      .then(() => {
        setTimeout(() => {
          dispatch(
            setCredentials({
              user: null,
              accessToken: null
            })
          )
          dispatch(setShowFormLogin(false))
          dispatch(setAvatarUser(null))
          dispatch(setIsLoading(false))
          navigate('/')
        }, 1000)
      })
      .catch(() => {
        dispatch(setIsLoading(false))
      })
  }

  const renderElementByItem = (dataItem) => {
    if (dataItem.isOnOff) {
      return (
        <div className="on-off-notification icon-item">
          <button
            className={`btn btn-on-off-notification ${
              onOffNotification ? 'on-notification' : 'off-notification'
            }`}
            onClick={() => dispatch(setOnOffNotification(!onOffNotification))}>
            {!onOffNotification ? dataItem.textOff : dataItem.textOn}
          </button>
        </div>
      )
    }
    if (dataItem.isIconChevron) {
      return (
        <div className="on-off-notification icon-item">
          <img src={Chevron} alt="Chevron" />
        </div>
      )
    }
    return ''
  }

  const nextRouterProfile = (link) => {
    navigate(link)
  }

  return (
    <div className="list_setting-tab">
      <ul>
        {listSetting && listSetting.length > 0
          ? listSetting.map((item) => {
              return (
                <li key={item.id} className="item-setting-tab" onClick={item.action}>
                  {item.link ? (
                    <ExternalLink link={item.link} text={item.value} />
                  ) : (
                    <Link to="#" className="text-white">
                      {item.value}
                    </Link>
                  )}
                  {renderElementByItem(item)}
                </li>
              )
            })
          : ''}
      </ul>
      <ModalConfirm
        titleModal={t('text_title_logout')}
        textCancel={t('text_action_cancel')}
        textConfirm={t('text_action_submit')}
        showModal={isShowModalConfirm}
        actionClose={actionClose}
        actionConfirm={actionConfirm}
      />
      <ModalConfirm
        titleModal={t('text_title_withdraw')}
        textCancel={t('text_action_cancel')}
        textConfirm={t('text_action_submit')}
        showModal={isShowWithdrawModalConfirm}
        actionClose={actionClose}
        actionConfirm={actionConfirmWithdraw}
      />
    </div>
  )
}

export default SettingProfile
