import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

function ModalConfirm({
  titleModal,
  textCancel,
  textConfirm,
  actionClose,
  actionConfirm,
  showModal,
  children
}) {
  return (
    <Modal show={showModal} className="modal-confirm" centered>
      <Modal.Header className="header-confirm w-100 text-center">
        <h3 className="text-white fs-14px text-center mb-0 w-100">{titleModal}</h3>
      </Modal.Header>
      <Modal.Body>
        {children ? children : ''}
        <div className="action-confirm d-flex">
          <div className="close w-50 text-center box-action" onClick={actionClose}>
            {textCancel}
          </div>
          <div className="submit w-50 text-center box-action" onClick={actionConfirm}>
            {textConfirm}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ModalConfirm.propTypes = {
  titleModal: PropTypes.string,
  textCancel: PropTypes.string,
  textConfirm: PropTypes.string,
  actionClose: PropTypes.func,
  actionConfirm: PropTypes.func,
  showModal: PropTypes.bool,
  children: PropTypes.any
}

export default ModalConfirm
