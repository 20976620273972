import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'

const RCheckBox = ({ onChange, isChecked, className }) => {
  return (
    <div className="rcheckbox-wrapper">
      <label className={`rcheckbox-container ${className}`}>
        <input type="checkbox" onChange={onChange} checked={isChecked} />
        <span className="checkmark" />
      </label>
    </div>
  )
}

RCheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  className: PropTypes.any
}

export default RCheckBox
