import React from 'react'
import './header.scss'
import noAvatar from '@/assets/images/no-avatar.svg'
import bg from '@/assets/videos/rimophy_back_logo.mp4'
import pen from '@/assets/images/icons/icon_pen.png'
import { useSelector } from 'react-redux'
import { selectAvatarUser, selectCurrentUser } from '@/features/Auth/AuthSlice'
import { useTranslation } from 'react-i18next'

import routers from '@/routers/routers'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { keyCookie, TAB_PROFILE } from '@/utils/constants'
import { getCookie } from '@/utils/utils'

function Header({ changeTabProfile, tabProfile }) {
  const user = getCookie(keyCookie.user)
    ? JSON.parse(getCookie(keyCookie.user))
    : useSelector(selectCurrentUser) || null
  const avatarUser = useSelector(selectAvatarUser) || noAvatar
  const { t } = useTranslation()

  return (
    <div className="header-container-profile position-relative">
      <div>
        <video autoPlay loop playsInline className="video-background" muted src={bg} />
        <div className="icon-pen-float">
          <Link to={routers.editProfile}>
            <img className="icon-pen" src={pen} alt="icon-pen" />
          </Link>
        </div>

        <div
          className="card card-profile w-100 border-top-0 border-bottom-0 d-flex align-items-center"
          style={{ background: 'transparent' }}>
          <div className="info d-flex align-items-center w-100">
            <div className="avatar-card-profile">
              <Link to={routers.editProfile}>
                <img
                  className="border-radius-md avatar"
                  src={avatarUser}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = noAvatar
                  }}
                  alt="image"
                />
              </Link>
            </div>
            <div className="info-user-profile pl-15px">
              <div className="card-body p-0">
                <div className="roboto text-white mb-5px">{user?.userName ?? 'User Name'}</div>
                <div className="roboto text-white mb-5px">{user?.email ?? 'Email'}</div>
                <div className="roboto text-white mb-10px">
                  {t('possession_points')} {user?.point ?? 0}P
                </div>
                <div className="action-add-point">
                  <Link to={routers.point}>{t('text_add_point')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="nav nav-tabs nav-fill position-absolute bottom-0 end-0 w-100">
        <div
          className={`nav-link ${tabProfile === TAB_PROFILE.SESSION_HISTORY ? 'active' : ''}`}
          onClick={() => changeTabProfile(TAB_PROFILE.SESSION_HISTORY)}>
          {t('tab_session_history')}
        </div>
        <div
          className={`nav-link ${tabProfile === TAB_PROFILE.POINT_HISTORY ? 'active' : ''}`}
          onClick={() => changeTabProfile(TAB_PROFILE.POINT_HISTORY)}>
          {t('tab_point_history')}
        </div>
        <div
          className={`nav-link ${tabProfile === TAB_PROFILE.SETTING_PROFILE ? 'active' : ''}`}
          onClick={() => changeTabProfile(TAB_PROFILE.SETTING_PROFILE)}>
          {t('tab_settings')}
        </div>
      </nav>
    </div>
  )
}
Header.propTypes = {
  changeTabProfile: PropTypes.any,
  tabProfile: PropTypes.any
}
export default Header
