import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { setTimeCookie } from '@/utils/utils'
import { keyCookie, LINK_QA, LINK_COMPANY, localStorageWeb } from '@/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAvatarUser,
  selectCurrentToken,
  selectCurrentUser,
  setAvatarUser,
  // setCredentials,
  setIsLoadUser,
  setShowFormLogin,
  setShowFormRegister,
  setUser
} from '@/features/Auth/AuthSlice'
import logo from '@/assets/images/logo.svg'
import noAvatar from '@/assets/images/no-avatar.svg'

import { useTranslation } from 'react-i18next'
import { db, storage } from '@/config/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'
import httpRequest from '@/config/httpRequest'
import ExternalLink from '@/components/common/ExternalLink'

function Header() {
  const token = useSelector(selectCurrentToken) || null
  const user = useSelector(selectCurrentUser) || null
  const avatarUser = useSelector(selectAvatarUser)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const actionLogin = () => {
    dispatch(setShowFormLogin(true))
  }
  const actionRegister = () => {
    dispatch(setShowFormRegister(true))
  }
  useEffect(() => {
    if (token) {
      httpRequest.defaults.headers = {
        Authorization: `Bearer ${token}`
      }
      getDataFirebase().catch(console.error)
      setTimeout(() => {
        dispatch(setIsLoadUser(false))
      })
    }
  }, [token])

  const getDataFirebase = async (dataUser) => {
    const dataSet = dataUser || user || null
    const q = await query(collection(db, 'users'), where('auth_user_id', '==', dataSet?.id))
    await onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        const userData = {
          ...dataSet,
          email: doc.data()?.email,
          userName: doc.data()?.user_name,
          point: doc.data()?.point
        }
        dispatch(setShowFormLogin(false))
        document.cookie = 'token=Unauthorized; expires=Thu, 01 Jan 1970 00:00:00 UTC"; path=/'
        const time = setTimeCookie(7)
        document.cookie = `${keyCookie.token}=${token}; expires=${new Date(time)}; path=/`
        document.cookie = `${keyCookie.user}=${JSON.stringify(userData)}; expires=${new Date(
          time
        )}; path=/`
        if (!avatarUser) {
          const imageRef = ref(storage, `users/avatar/${userData?.id}.jpg`)
          await getDownloadURL(imageRef)
            .then((url) => {
              let avatar = url ? url : noAvatar
              localStorage.setItem(localStorageWeb.avatar_user, avatar)
              dispatch(setAvatarUser(avatar))
            })
            .catch(() => {
              dispatch(setAvatarUser(noAvatar))
            })
        }

        dispatch(setUser({ ...userData }))
      })
    })
  }

  return (
    <div className="header" id="header-app">
      <div className="content-header d-flex align-items-center justify-content-center">
        <div className="logo-camelia w-30">
          <Link to="/">
            <img src={logo} alt="logo camelia" />
          </Link>
        </div>
        <div className="menu-camelia w-70 d-flex align-items-center justify-content-end">
          <div className="box-menu mr-25px hidden-app">
            <ExternalLink link={LINK_COMPANY} text={t('operating_company')} />
          </div>
          <div className="box-menu mr-25px hidden-app">
            <ExternalLink link={LINK_QA} text={t('text_qa')} />
          </div>
          <div className="box-menu mr-25px hidden-app">
            <Link to="/feedback">{t('inquiries')}</Link>
          </div>
          {!token ? (
            <div className="d-flex">
              <div className="box-menu mr-25px">
                <div className="box-menu-button box-login cs" onClick={actionLogin}>
                  <div className="cs">{t('login')}</div>
                </div>
              </div>
              <div className="box-menu">
                <div className="box-menu-button box-register cs" onClick={actionRegister}>
                  <div className="cs text-white">{t('sign_up')}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="user-information">
              <Link className=" d-flex align-items-center cs" to="/profile">
                <div className="avatar">
                  <img
                    src={avatarUser}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = noAvatar
                    }}
                    alt="avatar"
                  />
                </div>
                <div className="information text-white pl-10px">
                  <div>{user ? user.userName : ''}</div>
                  <div>{user ? user.email : ''}</div>
                  <div>
                    {t('possession_points')} {user && user.point ? user.point : 0}P
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
