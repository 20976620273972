import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { handleSetTimeCountDown } from '@/utils/utils'
import CameliaDefault from '@/assets/images/camelia_default.png'
import { useDispatch } from 'react-redux'
import { setKeyActiveSlide } from '@/features/Sessions/SessionSlice'

let timeClickImage = 0
function ItemNextSession({ dataItem, index }) {
  const [isCountDown, setIsCountDown] = useState(true)
  const [timeCountDown, setTimeCountDown] = useState('00:00:00')
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const timeNow = new Date().getTime()
    let intervalSetTime = null
    if (timeNow - dataItem.timeStart >= 0) {
      setIsCountDown(false)
    } else {
      intervalSetTime = setInterval(() => {
        const checkStart = moment(new Date().getTime()) - moment(dataItem.timeStart)
        if (checkStart < 0) {
          let now = moment(new Date())
          const expiration = moment(dataItem.timeStart)
          const diff = expiration.diff(now)
          const diffDuration = moment.duration(diff)
          let hours = diffDuration.hours()
          if (diffDuration.days() >= 1) {
            hours = hours + 24 * diffDuration.days()
          }
          const minute = diffDuration.minutes()
          const second = diffDuration.seconds()

          if (hours === 0 && minute === 0 && second === 0) clearInterval(intervalSetTime)
          const dataSetTimeCountDown = handleSetTimeCountDown(hours, minute, second)
          setTimeCountDown(dataSetTimeCountDown)
        } else {
          setIsCountDown(false)
          clearInterval(intervalSetTime)
        }
      }, 1000)
    }
    return () => {
      if (intervalSetTime) {
        clearInterval(intervalSetTime)
      }
    }
  }, [])

  const handelImageMouseDown = (event) => {
    timeClickImage = event.timeStamp
  }

  const handelImageMouseUp = (event) => {
    if (event.timeStamp - timeClickImage < 130) {
      dispatch(setKeyActiveSlide(index))
      navigate(`/session/${dataItem.id}`)
    }
    timeClickImage = 0
  }

  return (
    <div className="box-next-session">
      <Link className="w-100" to={`/session/${dataItem.id}`}>
        <div className="header-box-next-session d-flex align-items-center text-white py-2">
          <div className="title-box w-50 line line-1">{dataItem.title}</div>
          <div className="countdown-box w-50 text-end">
            {!isCountDown ? t('text_session_not_count_down') : timeCountDown}
          </div>
        </div>
      </Link>

      <div className="image-box">
        <img
          src={dataItem.image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = CameliaDefault
          }}
          onMouseDown={handelImageMouseDown}
          onMouseUp={handelImageMouseUp}
          className="cs"
          alt={dataItem.title}
        />
      </div>
    </div>
  )
}

ItemNextSession.propTypes = {
  dataItem: PropTypes.any,
  index: PropTypes.number
}

export default ItemNextSession
