import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BroadcastScreenLiveStream from '@/components/sessions/BroadcastScreenLiveStream'
import BroadcastScreenPlayback from '@/components/sessions/BroadcastScreenPlayback'
import ModalDetailSession from '@/components/sessions/ModalDetailSession'
import Stripe from '@/features/Payment'
import { apiPost } from '@/config/httpRequest'
import { db } from '@/config/firebase'
import { EVENT_TYPE, BUY_MODE } from '@/utils/constants'
import { getTimeStartAndTimeEnd, useQuery, setScrollLayout, isMobile } from '@/utils/utils'
import { selectCurrentToken, selectCurrentUser, setShowFormLogin } from '@/features/Auth/AuthSlice'
import {
  selectIsLoading,
  setIsLoading,
  setShowToastSuccess,
  setIsShowModalFullPage,
  setIsLayoutScroll,
  setShowToastError
} from '@/features/Common/CommonSlice'
import {
  setDataItemModalListImage,
  selectKeyActiveSlide,
  setSettingSlide
} from '@/features/Sessions/SessionSlice'
import { STATUS_SESSION } from '@/constants'
import ModalFullPage from '@/components/common/ModalFullPage'
import ModalListImageSession from '@/components/ModalListImageSession/index'
import InfomationSession from '@/components/sessions/InfomationSession'
import ModalConfirm from '@/components/common/ModalConfirm'

let intervalButtonDetail = null

function DetailSession() {
  const { id } = useParams()
  const queryParams = useQuery()
  const { t } = useTranslation()
  const [dataSession, setDataSession] = useState({})
  const [statusSession, setStatusSession] = useState('')
  const [textButtonStatus, setTextButtonStatus] = useState('')
  const [isDisableButton, setIsDisableButton] = useState(false)
  const [isShowModalDetail, setIsShowModalDetail] = useState(false)
  const [listVideoPlayback, setListVideoPlayback] = useState([])
  const [isShowStripe, setIsShowStripe] = useState(false)
  const user = useSelector(selectCurrentUser) || null
  const tokenLogin = useSelector(selectCurrentToken) || null
  const [isShowBroadcast, setIsShowBroadcast] = useState(false)
  const [password, setPassword] = useState('')
  const [isShowModalPassword, setIsShowModalPassword] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector(selectIsLoading)
  const keyActiveSlide = useSelector(selectKeyActiveSlide) || 0
  // const [isLoadingEventFree, setIsLoadingEventFree] = useState(false)

  useEffect(() => {
    const payment_intent = queryParams.get('payment_intent')
    const payment_intent_client_secret = queryParams.get('payment_intent_client_secret')
    const redirect_status = queryParams.get('redirect_status')
    if (!(payment_intent && payment_intent_client_secret && redirect_status)) return
    if (redirect_status !== 'succeeded') return
    dispatch(setShowToastSuccess(t('payment_successed')))
    navigate(-1) // refresh page
  }, [])

  useEffect(() => {
    if (id) {
      getDataEvent()
    }
  }, [id, tokenLogin])

  useEffect(() => {
    dispatch(setSettingSlide(keyActiveSlide))
  }, [keyActiveSlide])

  useEffect(() => {
    let keyDownHandler = null
    if (isShowModalPassword) {
      keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          actionConfirmPassword()
        }
      }
      document.addEventListener('keydown', keyDownHandler)
    }

    return () => {
      if (keyDownHandler) {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [isShowModalPassword])

  const getDataEvent = () => {
    try {
      if (!isLoading) {
        dispatch(setIsLoading(true))
      }
      const query = doc(db, 'events', id)
      onSnapshot(query, async (doc) => {
        setDataSessionRealtime(doc.data())
      })
    } catch (error) {
      dispatch(setIsLoading(false))
      console.error(error)
    }
  }

  const setDataSessionRealtime = async (dataPayload) => {
    const {
      timestamps,
      price,
      audienceIDs,
      title,
      coverImagePath,
      eventDescription,
      total_free_image,
      image_limit,
      event_type,
      broadcasters,
      password,
      buy_mode
    } = dataPayload

    const time = await getTimeStartAndTimeEnd(timestamps)

    const formatData = {
      id: id,
      audienceIds: audienceIDs || [],
      title: title,
      price: price,
      image: coverImagePath,
      description: eventDescription,
      total_free_image: total_free_image,
      timeStart: time?.timeStart?.seconds * 1000,
      timeEnd: time?.timeEnd?.seconds * 1000,
      dateStart: moment(time?.timeStart?.seconds * 1000).format('YYYY/MM/DD'),
      timeString: `${moment(time?.timeStart?.seconds * 1000).format('HH:mm')} - ${moment(
        time?.timeEnd?.seconds * 1000
      ).format('HH:mm')}`,
      image_limit: image_limit || 1000,
      event_type: event_type,
      broadcasters: broadcasters,
      password: password,
      buy_mode: buy_mode
    }
    setDataSession(formatData)
    dispatch(setIsLoading(false))
  }

  const setComponentLayout = () => {
    if (dataSession.event_type === EVENT_TYPE.LIVESTREAM) {
      return (
        <BroadcastScreenLiveStream
          isShowBroadcast={isShowBroadcast}
          dataItem={dataSession}
          closeBroadcast={closeBroadcast}
        />
      )
    }
    return (
      <BroadcastScreenPlayback
        isShowBroadcast={isShowBroadcast}
        dataItem={dataSession}
        closeBroadcast={closeBroadcast}
        videoPlayback={listVideoPlayback}
      />
    )
  }

  useEffect(() => {
    if (dataSession && Object.keys(dataSession).length > 0 && id) {
      checkSessionFree()
      if (intervalButtonDetail) {
        clearInterval(intervalButtonDetail)
      }
      handleButtonEvent()
      intervalButtonDetail = setInterval(() => {
        const timeNowInterval = new Date().getTime()
        if (timeNowInterval - dataSession.timeEnd > 0) {
          clearInterval(intervalButtonDetail)
        }
        handleButtonEvent()
      }, 1000)
      return () => {
        if (intervalButtonDetail) {
          clearInterval(intervalButtonDetail)
        }
      }
    }
  }, [dataSession, user])

  const checkSessionFree = () => {
    if (!user?.id) return
    const audienceIdUser = dataSession?.audienceIds?.includes(user.id)
    if (audienceIdUser) return
    apiPost('/api/v1/events/free', { eventId: id })
  }

  const getSessionWithUser = (checkTimeStart, checkTimeEnd) => {
    const audienceIdUser = dataSession?.audienceIds?.includes(user.id)
    if (!audienceIdUser && checkTimeEnd > 0) {
      setStatusSession(STATUS_SESSION.NOTBOUGHT_NOTFINISH_NOTHAPPEN)
      setIsDisableButton(false)
      setTextButtonStatus(t('text_status_detail_session_one'))
      return
    }
    if (audienceIdUser && checkTimeStart > 0) {
      setStatusSession(STATUS_SESSION.BOUGHT_NOTHAPPEN)
      setIsDisableButton(true)
      setTextButtonStatus(t('text_status_detail_session_two'))
      return
    }
    if (audienceIdUser && checkTimeStart < 0 && checkTimeEnd > 0) {
      setStatusSession(STATUS_SESSION.BOUGHT_AND_HAPPEN)
      setIsDisableButton(false)
      setTextButtonStatus(t('text_status_detail_session_three'))
      return
    }
    if (audienceIdUser && checkTimeEnd < 0) {
      setStatusSession(STATUS_SESSION.BOUGHT_FINISHED)
      setIsDisableButton(false)
      setTextButtonStatus(t('text_status_detail_session_four'))
      return
    }
    if (!audienceIdUser && checkTimeEnd < 0) {
      setStatusSession(STATUS_SESSION.NOTBOUGHT_FINISHED)
      setTextButtonStatus(t('text_status_detail_session_five'))
      setIsDisableButton(true)
    }
  }

  const handleButtonEvent = () => {
    const timeNow = new Date().getTime()
    const checkTimeStart = dataSession.timeStart - timeNow
    const checkTimeEnd = dataSession.timeEnd - timeNow

    if (user && user.id) {
      getSessionWithUser(checkTimeStart, checkTimeEnd)
    } else {
      if (checkTimeEnd > 0) {
        setStatusSession(STATUS_SESSION.NOTBOUGHT_NOTFINISH_NOTHAPPEN)
        setIsDisableButton(false)
        setTextButtonStatus(t('text_status_detail_session_one'))
        return
      }
      if (checkTimeEnd < 0) {
        setStatusSession(STATUS_SESSION.NOTBOUGHT_FINISHED)
        setTextButtonStatus(t('text_status_detail_session_five'))
        setIsDisableButton(true)
      }
    }
  }

  useEffect(() => {
    if (
      dataSession &&
      Object.keys(dataSession).length > 0 &&
      dataSession.broadcasters &&
      dataSession.broadcasters.length > 0
    ) {
      const data = []
      setTimeout(() => {
        dataSession.broadcasters.forEach((item, index) => {
          const element = document.getElementById(`video-duration-${item.uid}${index}`)
          if (element) {
            const dataFormat = {
              ...item,
              duration: element.duration
            }
            data.push(dataFormat)
          }
        })
        setListVideoPlayback(data)
      }, 2000)
    }
  }, [dataSession])

  const handleActionSession = () => {
    if (isLoading) return
    if (!isDisableButton && !tokenLogin) {
      dispatch(setShowFormLogin(true))
      return
    }
    if (dataSession.password && statusSession === STATUS_SESSION.BOUGHT_AND_HAPPEN) {
      setIsShowModalPassword(true)
      return
    }
    handleStatusSession()
  }
  const closeModalDetail = () => {
    setIsShowModalDetail(false)
  }
  const showModalDetail = () => {
    setIsShowModalDetail(true)
  }
  const closeBroadcast = () => {
    setIsShowBroadcast(false)
  }
  const handleStatusSession = () => {
    switch (statusSession) {
      case STATUS_SESSION.NOTBOUGHT_NOTFINISH_NOTHAPPEN:
        setIsShowStripe(true)
        break
      case STATUS_SESSION.BOUGHT_AND_HAPPEN:
        setIsShowBroadcast(true)
        break
      case STATUS_SESSION.BOUGHT_FINISHED:
        setScrollLayout(false)
        dispatch(setIsLayoutScroll(true))
        dispatch(setDataItemModalListImage({ id: id, title: dataSession.title }))
        dispatch(setIsShowModalFullPage(true))
        break
      default:
        break
    }
  }

  const handleConfirmPayment = (res) => {
    const { paymentIntent } = res
    const { status } = paymentIntent

    if (status !== 'succeeded') {
      dispatch(setShowToastSuccess(t('payment_not_succeeded')))
      return
    }
    // succeeded
    dispatch(setShowToastSuccess(t('payment_successed')))
    setIsShowStripe(false)
    setIsShowModalDetail(false)
  }

  const actionClosePassword = () => {
    setIsShowModalPassword(false)
    setPassword('')
  }
  const actionConfirmPassword = () => {
    if (!password.trim()) return
    if (password.trim() !== dataSession.password) {
      dispatch(setShowToastError(t('text_error_confirm_password_session')))
    }
    if (password.trim() === dataSession.password) {
      handleStatusSession()
    }
    actionClosePassword()
  }

  useEffect(() => {
    const element = document.getElementById('container-page')
    if (isShowBroadcast && element) {
      element.style.maxWidth = '100%'
      showVideoIsMobile()
    } else {
      element.style.maxWidth = '768px'
      document.getElementById('header-app').style.display = 'block'
      document.getElementById('footer-app').style.display = 'flex'
      document
        .getElementById('layout-content-page')
        .setAttribute('style', 'overflow:auto !important')
      document.getElementById('container-page').setAttribute('style', 'max-width:768px !important')
    }
  }, [isShowBroadcast])

  const showVideoIsMobile = () => {
    if (isMobile()) {
      document.getElementById('header-app').setAttribute('style', 'display:none !important')
      document.getElementById('footer-app').setAttribute('style', 'display:none !important')
      document
        .getElementById('layout-content-page')
        .setAttribute('style', 'overflow:visible !important')
      document.getElementById('container-page').setAttribute('style', 'max-width:none !important')
    }
  }

  if (isShowStripe)
    return (
      <div className="detail-session">
        <div className="w-100">
          <div className="image-session-detail">
            <img src={dataSession.image} alt={dataSession.title} />
          </div>
          <div className="content-detail-session">
            <Stripe
              eventId={id}
              dataSession={dataSession ?? {}}
              setIsShowStripe={setIsShowStripe}
              handleConfirmPayment={handleConfirmPayment}
            />
          </div>
        </div>
      </div>
    )

  return (
    <div className="detail-session">
      {dataSession && dataSession.event_type === EVENT_TYPE.PLAYBACK ? (
        <div className="d-none">
          {dataSession.broadcasters && dataSession.broadcasters.length > 0
            ? dataSession.broadcasters.map((item, index) => {
                return (
                  <video key={item.uid} id={`video-duration-${item.uid}${index}`}>
                    <source src={`${item.path}`} type="video/mp4" />
                  </video>
                )
              })
            : ''}
        </div>
      ) : (
        ''
      )}
      {!isShowBroadcast ? (
        <div className="w-100">
          {dataSession && Object.keys(dataSession).length > 0 ? (
            <InfomationSession
              dataSession={dataSession}
              statusSession={statusSession}
              handleActionSession={handleActionSession}
              showModalDetail={showModalDetail}
              isDisableButton={isDisableButton}
              textButtonStatus={textButtonStatus}
            />
          ) : (
            ''
          )}

          <ModalDetailSession
            dataItem={dataSession}
            isShow={isShowModalDetail}
            handleClose={closeModalDetail}
          />
          <ModalConfirm
            titleModal={t('text_title_confirm_password_session')}
            showModal={isShowModalPassword}
            textCancel={t('text_action_cancel_confirm_password_session')}
            textConfirm={t('text_action_ok_confirm_password_session')}
            actionClose={actionClosePassword}
            actionConfirm={actionConfirmPassword}>
            <div className="text-description-verify-password px-4">
              {t('text_description_confirm_password_session')}
            </div>
            <div className="p-4 verify-password-input">
              <form autoComplete="off">
                <div className="box-input">
                  <input
                    className={`form-control`}
                    type="password"
                    value={password}
                    placeholder=""
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </ModalConfirm>
          <ModalFullPage>
            <ModalListImageSession
              eventID={dataSession.id}
              buyMode={dataSession.buy_mode ?? BUY_MODE.NORMAL}
            />
          </ModalFullPage>
        </div>
      ) : (
        setComponentLayout()
      )}
    </div>
  )
}

export default DetailSession
