const languageJp = {
  inquiries: 'お問い合わせ',
  login: 'ログイン',
  sign_up: '新規登録',
  possession_points: '所持ポイント',

  // nav
  tab_session_history: 'セッション履歴',
  tab_point_history: 'ポイント履歴',
  tab_settings: '設定',
  text_add_point: 'ポイント追加',

  // session
  ten_thousand_yen: '円',
  text_session_not_count_down: '開催中',
  text_date: '開催予定',
  text_feature: 'FEATURE',
  text_next_session: 'NEXT SESSION',
  text_session: 'SESSION',
  text_capacity_session: '定員',
  text_list_session: 'もっと見る',
  participation_fee: '参加費',
  view_detail_session: '詳しく見る',
  text_status_detail_session_one: '購入する',
  text_status_detail_session_two: '購入済み',
  text_status_detail_session_three: '今すぐ参加する',
  text_status_detail_session_four: '撮影した写真を確認する',
  text_status_detail_session_five: '終了',
  text_status_session_finished: '終了',
  text_status_session_happen: '開催中',
  text_status_session_soon: 'Soon',
  text_price: '料金',
  shooting_time: '撮影時間',
  text_minute: '分まで',
  text_number_photo: '撮影可能枚数',
  text_more_than: '枚以上',
  yen: '円',
  text_tax_included: '税込',
  text_remaining_number: '残枚数',
  text_time_remaining: '残時間',
  text_wait_live_stream: '現在配信準備中...',
  text_sheet: '枚',
  text_buy: '購入',
  text_loading: '読み込み中...',
  text_your_cart: 'あなたのカート',
  text_date_point_history: '開催終了',
  text_no_image: 'No image',
  text_wating_image: '撮影画像の準備中です。しばらくお待ち下さい',

  // login + register
  email_address: 'メールアドレス',
  password: 'パスワード',
  click_link_register: 'はじめての方はこちら',
  click_link_privacy: 'プライバシーポリシー',
  click_link_when_trouble_login: 'ログインに問題がある場合はこちら',
  error_email: 'メールアドレスの形式が正しくではありません。',
  error_password: 'パスワードは6文字以上の大文字を含む半角英数字で入力してください',
  login_error: 'メールアドレスまたはパスワードが正しくではありません。',
  email_already: 'このメールアドレスが登録されています。',
  email_not_register: 'このメールアドレスが登録されていません。',
  password_not_match: 'パスワードと再確認パスワードが一致しません。',
  register_error: '操作誤りがありました。',
  title_confirm_email: '登録しますか？',
  content_confirm_email: '登録を完了するには、{{email}}に送信した確認メールをチェックしてください',
  ok: 'OK',
  text_avatar: 'プロフィール画像',
  require_userName: 'ニックネームを入力してください。',
  require_email: 'メールアドレスを入力してください。',
  require_password: 'パスワードを入力してください。',
  require_password_confirm: 'パスワード(再確認)を入力してください。',
  text_register: '新規会員登録',
  label_user_name: 'ニックネーム',
  placeholder_password: '6文字以上の大文字を含む半角英数字で入力してください',
  label_confirm_password: 'パスワード(再確認)',
  placeholder_confirm_password: '確認のためパスワードを再入力してください。',
  button_submit_register: '同意して登録',
  button_submit_login: 'ログイン',
  text_forgot_password: 'パスワードを忘れた方',
  button_submit_forgot_password: 'メールを送信する',
  text_forgot_password_success: 'パスワード再設定のメールを送信しました。ご確認ください。',
  text_error_forgot_password: '操作誤りがありました。再度ご確認お願いします。',

  // point
  buy: '購入する',
  text_title_my_point: 'ポイント残高',
  point_buy_title: 'ポイント追加',
  text_title_buy_point: 'ポイントが足りません。購入しますか。',

  // image
  text_buy_image: 'こちらの写真を購入します。',
  text_title_buy_photo: '購入しますか？',
  text_title_buy_success: '購入完了',
  text_body_buy_success_1: '購入した写真はアルバムから',
  text_body_buy_success_2: '編集・ダウンロード可能です。',

  // request_buy
  request_buy: '購入申請する',
  text_title_request_buy_photo: '購入申請しますか？',
  text_title_request_buy_success: '購入申請完了',
  text_body_request_buy_success_1: '運営で確認後、',
  text_body_request_buy_success_2: '購入許可した写真は購入可能となります',
  request_buy_requested: '購入申請済',
  request_buy_approved: '購入許可済',

  // setting_tab
  text_push_notification: 'プッシュ通知',
  text_camera_settings: 'カメラ設定',
  text_change_email: 'プロファイル変更',
  text_qa: 'よくある質問',
  operating_company: '運営会社',
  text_inquiry: 'お問い合わせ',
  text_terms_of_service: '利用規約',
  text_privacy_policy: 'プライバシーポリシー',
  text_withdraw: '退会する',
  text_logout: 'ログアウト',
  text_off_notification: 'OFF',
  text_on_notification: 'ON',
  text_title_logout: 'ログアウトしますか？',
  text_title_withdraw:
    '退会すると撮影データなどにアクセスできなくなります。それでも退会されますか？',
  text_action_cancel: 'いいえ',
  text_action_submit: 'はい',
  text_back_home: 'ホームへ戻る',

  // album
  text_album: 'アルバム',
  album: 'ALBUM',
  text_download: 'ダウンロード',

  text_title_download_image: '端末にダウンロードしますか。',
  text_session_jp: 'セッション',

  // notificcation
  news: 'NEWS',
  text_title_detail_news: 'ニュース',
  text_news: 'お知らせ',
  text_not_event: 'イベント情報を取得できません。',

  // feedback
  text_title_feedback: '問い合わせ',
  text_feedback_subject: '件名',
  text_feedback_content: 'お問い合わせ内容',
  text_feedback_content_placeholder: '内容を入力してください。',
  text_feedback_subject_withdrawal: '会員登録/退会に関して',
  text_feedback_subject_purchasing: 'セッションの購入/参加に関して',
  text_feedback_subject_how: '撮影方法に関して',
  text_feedback_subject_photo: '写真の保存に関して',
  text_feedback_subject_other: 'その他のお問い合わせ',
  button_feedback_confirm: '内容確認',
  text_feedback_confirm_question: '下記の内容を送信しますか。',
  button_send: '送信',
  text_feedback_sent: 'お問い合わせ内容を送信しました。',
  template_sent1: 'いつもご利用いただきありがとうございます。',
  template_sent2: 'お客さまからのお問い合わせを受け付けました。',
  template_sent3: 'お問い合わせ時刻：',
  template_sent4: 'お問い合わせ件名：',
  template_sent5: 'ご連絡をいただいたお問い合わせについて、事務局で内容を確認いたします。',
  template_sent6: '順次ご案内をおこなっておりますので、今しばらくお待ちください。',

  // edit profile
  text_title_edit_profile: 'プロフィール編集',
  text_submit_edit_profile: '完了',
  text_submit_input: '確認',
  text_title_verify_password: 'パスワードを入力してください。',
  text_description_verify_password: 'メールアドレスを変更するため、パスワード入力が必要です。',
  text_error_verify_password: '正しいパスワードを入力してください。',
  text_too_many_request: 'パスワードの変更回数が超えております',

  text_title_confirm_password_session: 'パスワードを入力してください。',
  text_description_confirm_password_session:
    'こちらのセッションは参加用パスワードが設定されています。',
  text_action_cancel_confirm_password_session: 'キャンセル',
  text_action_ok_confirm_password_session: 'OK',
  text_error_confirm_password_session:
    'パスワードが間違っています。入力内容をご確認の上、再入力してください。',

  // payment
  text_save_card: '支払情報を保存する',
  text_add_card: '新しいカードを追加',
  text_choose_card: 'または保存したカードを選択',
  payment_successed: 'ご購入が完了しました。',
  payment_not_succeeded: '購入に失敗しました。再度ご確認お願いします。',

  //bought image
  text_body_buy_error: '購入を完了できませんでした。',
  text_body_request_buy_error: '購入申請を完了できませんでした。'
}

export default languageJp
