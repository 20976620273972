import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { formatMoney } from '@/utils/utils'
import PropTypes from 'prop-types'
import CreditCard from '@/features/Payment/components/CreditCard'
import { Form } from 'react-bootstrap'
import { apiPost } from '@/config/httpRequest'
import { setIsLoading } from '@/features/Common/CommonSlice'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'

const CheckoutForm = ({ data, handleConfirmPayment }) => {
  const { price, savedPaymentMethods, paymentIntentKey, paymentIntentId } = data
  const default_card =
    savedPaymentMethods?.find((item) => item.is_default)?.id ?? savedPaymentMethods?.at(0)?.id
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedCard, setSelectedCard] = useState(default_card)
  const [isAddCard, setIsAddCard] = useState(false)
  const dispatch = useDispatch()

  const isShowPaymentElement = !selectedCard || isAddCard

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    const res = selectedCard
      ? await stripe.confirmCardPayment(paymentIntentKey, {
          payment_method: selectedCard
        })
      : await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: `${window.location.origin}${window.location.pathname}`,
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'JP' // set default country
                }
              }
            }
          },
          redirect: 'if_required' // customize handle the result
        })
    const { error } = res
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      handleConfirmPayment(res) // customize handle the result
    }
  }
  const options = {
    fields: {
      billingDetails: {
        address: {
          country: 'never'
        }
      }
    }
  }

  async function handleSaveCard() {
    dispatch(setIsLoading(true))
    await apiPost('/api/v1/events/save-card', JSON.stringify({ paymentIntentId }))
      .catch(console.error)
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

  return (
    <React.Fragment>
      <form className="m-auto" onSubmit={handleSubmit}>
        {isShowPaymentElement ? (
          <>
            <PaymentElement options={options} />
            <Form.Group className="mt-3 text-white">
              <Form.Check
                type="checkbox"
                id="disabledFieldsetCheck"
                label={t('text_save_card')}
                onClick={handleSaveCard}
              />
            </Form.Group>
          </>
        ) : (
          <div className="payment-method-container">
            {selectedCard && (
              <button
                onClick={() => setIsAddCard(true)}
                className="camelia-button camelia-button-filled w-100 mt-5 fs-18 bg-black"
                disabled={!stripe}>
                {t('text_add_card')}
              </button>
            )}
            {savedPaymentMethods && (
              <div className="text-white fs-16px text-center my-5">{t('text_choose_card')}</div>
            )}
            {savedPaymentMethods?.map((item, index) => (
              <React.Fragment key={`${item.id}-${index}`}>
                <CreditCard
                  item={item}
                  selected={selectedCard === item.id}
                  onClick={() => setSelectedCard(item.id)}
                />
              </React.Fragment>
            ))}
          </div>
        )}
        <button
          className="camelia-button camelia-button-filled w-100 mt-5 fs-18 bg-blue"
          disabled={!stripe}>
          {`Pay ${formatMoney(price)}`}
        </button>
        {/* Show error message to your customers */}
        {errorMessage && <div className="text-danger mt-3 fs-22px">{errorMessage}</div>}
      </form>
    </React.Fragment>
  )
}
CheckoutForm.propTypes = {
  data: PropTypes.any.isRequired,
  handleConfirmPayment: PropTypes.func.isRequired
}
export default CheckoutForm
