import routers from '@/routers/routers'

// Pages
import Home from '@/pages/Home/Home'
import DetailSession from '@/pages/Sessions/DetailSession'
import Search from '@/pages/Search/Search'
import Notification from '@/pages/Notification/Notification'
import Album from '@/pages/Album/Album'
import Profile from '@/pages/Profile/Profile'
import EditProfile from '@/pages/Profile/EditProfile'
import PointBuy from '@/pages/Points'
import DetailProfileConfig from '@/pages/Profile/DetailProfileConfig'
import Feedback from '@/pages/Profile/components/Feedback'

const arrRouter = [
  { path: routers.home, component: Home, isNeedAuth: false },
  { path: routers.detailSession, component: DetailSession, isNeedAuth: true },
  { path: routers.dashboard, component: Home, nameRedirect: routers.home, isNeedAuth: false },
  {
    path: routers.notification,
    component: Notification,
    isHiddenScrollLayout: true,
    isNeedAuth: true
  },
  { path: routers.search, component: Search, isHiddenScrollLayout: true, isNeedAuth: false },
  { path: routers.album, component: Album, isHiddenScrollLayout: true, isNeedAuth: true },
  { path: routers.profile, component: Profile, isHiddenScrollLayout: true, isNeedAuth: true },
  { path: routers.editProfile, component: EditProfile, isNeedAuth: true },
  { path: routers.point, component: PointBuy, isNeedAuth: true },
  {
    path: routers.information,
    component: DetailProfileConfig,
    isHiddenScrollLayout: true,
    isNeedAuth: true
  },
  { path: routers.feedback, component: Feedback, isHiddenScrollLayout: true, isNeedAuth: false }
]

export { arrRouter }
