import React, { memo, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { APP_ID_AGORA, CLIENT, ROLE_AGORA } from '@/config/agora'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import { httpRequestNotToken } from '@/config/httpRequest'
import ChangeCamera from '@/assets/images/change_camera.png'
import ActionPhoto from '@/assets/images/action_photo.png'
import { useTranslation } from 'react-i18next'
import { db } from '@/config/firebase'
import { collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore'
import moment from 'moment'
import ListVideoHost from '@/components/sessions/ListVideoHost'
import Close from '@/assets/images/icons/close.svg'
import { handleSetTimeCountDown } from '@/utils/utils'
import { STATUS_STRIPE } from '@/utils/constants'
import { setIsLoading } from '@/features/Common/CommonSlice'
import Logo from '@/assets/images/logo.svg'

let listChannelUid = []

function BroadcastScreenLiveStream({ isShowBroadcast, dataItem, closeBroadcast }) {
  const userLogin = useSelector(selectCurrentUser) || null
  const [tokenChannel, setTokenChannel] = useState(null)
  const [uidChannel, setUidChannel] = useState(null)
  const [sizeImageRequest, setSizeImageRequest] = useState(0)
  const [timeCountDown, setTimeCountDown] = useState('00:00:00')
  const [isShowListHost, setIsShowListHost] = useState(false)
  const [isPhoto, setIsPhoTo] = useState(false)
  const [isDisableCapture, setIsDisableCapture] = useState(false)

  const listUidChannel = listChannelUid

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    resetData()
  }, [])

  useEffect(() => {
    let intervalSetTime = null
    getAmountImageRequest().catch(console.error)

    intervalSetTime = setInterval(() => {
      const checkEnd = dataItem.timeEnd - new Date().getTime()
      if (checkEnd > 0) {
        let now = moment(new Date())
        const expiration = moment(dataItem.timeEnd)
        const diff = expiration.diff(now)
        const diffDuration = moment.duration(diff)
        let hours = diffDuration.hours()
        if (diffDuration.days() >= 1) {
          hours = hours + 24 * diffDuration.days()
        }
        const minute = diffDuration.minutes()
        const second = diffDuration.seconds()

        if (hours === 0 && minute === 0 && second === 0) {
          setIsDisableCapture(true)
          clearInterval(intervalSetTime)
        }
        const dataSetTimeCountDown = handleSetTimeCountDown(hours, minute, second)
        setTimeCountDown(dataSetTimeCountDown)
      } else {
        if (intervalSetTime) {
          clearInterval(intervalSetTime)
        }
      }
    }, 1000)
    return () => {
      if (intervalSetTime) {
        clearInterval(intervalSetTime)
      }
    }
  }, [dataItem, isShowBroadcast])

  useEffect(() => {
    CLIENT.on('user-published', handleUserPublish)
    CLIENT.on('user-unpublished', handleUserUnPublish)
  }, [])

  useEffect(() => {
    // set role agora
    setRoleAgora().catch(console.error)
    if (isShowBroadcast) {
      getTokenChannel()
    } else {
      document.getElementById('header-app').style.display = 'flex'
      document.getElementById('footer-app').style.display = 'flex'
      document
        .getElementById('layout-content-page')
        .setAttribute('style', 'overflow:auto !important')
      document.getElementById('container-page').setAttribute('style', 'max-width:768px !important')
    }
  }, [isShowBroadcast])

  useEffect(() => {
    if (tokenChannel && isShowBroadcast) {
      joinChannel().catch(console.error)
    }
  }, [tokenChannel, isShowBroadcast])

  useEffect(() => {
    if (listUidChannel && listUidChannel.length > 0 && !uidChannel) {
      setUidChannel(listUidChannel[0])
    }
    if (listUidChannel.length === 0) {
      setUidChannel(null)
    }
  }, [listUidChannel])

  useEffect(() => {
    if (uidChannel && uidChannel.uid) {
      renderVideo().catch(console.error)
    } else {
      removeChildElementVideo()
    }
  }, [uidChannel])

  const renderVideo = async () => {
    const remoteVideoTrack = uidChannel.user?.videoTrack
    const remoteAudioTrack = uidChannel.user?.audioTrack
    await removeChildElementVideo()
    setTimeout(() => {
      const elementStream = document.getElementById('streamer-audience')
      if (remoteAudioTrack) {
        remoteAudioTrack.play()
      }
      if (remoteVideoTrack) {
        remoteVideoTrack.play(elementStream)
      }
    }, 300)
  }

  const removeChildElementVideo = () => {
    const elementStream = document.getElementById('streamer-audience')
    if (elementStream.hasChildNodes()) {
      elementStream.removeChild(elementStream.children[0])
    }
  }

  const setRoleAgora = async () => {
    await CLIENT.setClientRole(ROLE_AGORA.audience)
  }

  const getTokenChannel = () => {
    const paramGetTokenAgora = {
      uid: 0,
      channelName: dataItem.id,
      expires: -111
    }
    dispatch(setIsLoading(true))
    httpRequestNotToken
      .post('/api/v1/agora/create-token', paramGetTokenAgora)
      .then((res) => {
        setTokenChannel(res.data.data)
        dispatch(setIsLoading(false))
      })
      .catch(() => {
        dispatch(setIsLoading(false))
      })
  }

  const joinChannel = async () => {
    if (CLIENT.connectionState !== 'CONNECTED') {
      listChannelUid = []
      await CLIENT.join(APP_ID_AGORA, dataItem.id, tokenChannel, null)
    }
  }

  const getAmountImageRequest = async () => {
    const q = await query(
      collection(db, 'crop_image_requests'),
      where('event_id', '==', dataItem.id),
      where('user_id', '==', userLogin.id)
    )
    await onSnapshot(q, (querySnapshot) => {
      setSizeImageRequest(querySnapshot.size)
    })
  }

  const handleUserPublish = async (user, mediaType) => {
    await CLIENT.subscribe(user, mediaType)
    const check = listChannelUid.find((item) => item.uid === user.uid)
    if (!check) {
      listChannelUid = [...listChannelUid, { uid: user.uid, user: user }]
    } else {
      listChannelUid.forEach((item) => {
        if (item.uid === user.uid) {
          item.user = user
        }
      })
    }
  }
  const handleUserUnPublish = async (user) => {
    if (!user.hasAudio && user.hasVideo) {
      listChannelUid.forEach((item) => {
        if (item.uid === user.uid) {
          item.user = user
        }
      })
    } else {
      const check = listChannelUid.find((item) => item.uid === user.uid)
      if (check) {
        const dataSet = listChannelUid.filter((item) => item.uid !== user.uid)
        if (uidChannel && uidChannel.uid === user.uid) {
          removeChildElementVideo()
          setUidChannel(dataSet[0])
        }
        listChannelUid = dataSet
      }
    }
  }

  const amountImageRequest = useMemo(() => {
    return dataItem.image_limit - sizeImageRequest
  }, [sizeImageRequest, dataItem])

  const actionCapture = async () => {
    if (amountImageRequest <= 0 || !uidChannel || isDisableCapture) return
    setIsPhoTo(true)
    const data = {
      event_id: dataItem.id,
      broadcasters_id: uidChannel.uid,
      user_id: userLogin.id,
      status: STATUS_STRIPE.NEW
    }
    if (uidChannel && Object.keys(uidChannel).length > 0) {
      const timeNow = new Date()
      const broadcasters = dataItem.broadcasters.find((item) => item.uid === uidChannel.uid)
      if (broadcasters) {
        setTimeout(() => {
          setIsPhoTo(false)
        }, 100)
        const timeStartVideo = new Date(broadcasters.startTime * 1000)
        data.time = timeNow - timeStartVideo
        try {
          const newDataCropRef = doc(collection(db, 'crop_image_requests'))
          await setDoc(newDataCropRef, data)
            .then(() => {
              console.info('success insert data crop_image_requests')
            })
            .catch(console.error)
        } catch (err) {
          console.error('err', err)
        }
      } else {
        setIsPhoTo(false)
      }
    }
  }

  const changeHost = () => {
    if ((listUidChannel && listUidChannel.length <= 1) || isDisableCapture) return
    setIsShowListHost(true)
  }

  const actionCloseChangeHost = () => {
    setIsShowListHost(false)
    renderVideo().catch(console.error)
  }

  const actionChangeHost = (dataItemHost) => {
    setUidChannel(dataItemHost)
    setIsShowListHost(false)
  }

  const closeBroadcastLiveStream = () => {
    closeBroadcast()
    resetData()
  }

  const resetData = () => {
    listChannelUid = []
    setTokenChannel(null)
    CLIENT.leave().catch(console.error)
  }

  return (
    <div className={`w-100 broadcast-livestream ${isShowBroadcast ? 'show' : ''}`}>
      <div className="event-video w-100 position-relative">
        <div id="streamer-audience" className={`${isPhoto ? 'bg-photo' : ''}`}></div>
        <div className="logo-leave-stream">
          <img src={Logo} alt="Logo live stream" />
          <span className="uid-live-stream">{userLogin.id}</span>
        </div>
        <div
          className="action-leave-stream position-absolute cs"
          onClick={closeBroadcastLiveStream}>
          <img src={Close} alt="close" />
        </div>
        {uidChannel && Object.keys(uidChannel).length > 0 ? (
          ''
        ) : (
          <div className="text_wait_live_stream position-absolute w-100 text-white">
            {t('text_wait_live_stream')}
          </div>
        )}
        <div className="footer-camera-audience position-absolute start-0 w-100 d-flex align-items-center justify-content-between">
          <div
            className={`change-camera box-camera-audience box-camera-icon d-flex align-items-center justify-content-center ${
              (listUidChannel && listUidChannel.length <= 1) || isDisableCapture
                ? 'disable-change-host'
                : ''
            }`}
            onClick={changeHost}>
            <img src={ChangeCamera} alt="change camera" />
          </div>
          <div
            className={`action-photo box-camera-audience box-camera-icon d-flex align-items-center justify-content-center ${
              amountImageRequest <= 0 || !uidChannel || isDisableCapture
                ? 'disable-change-host'
                : ''
            }`}
            onClick={actionCapture}>
            <img src={ActionPhoto} alt="action photo" />
          </div>
          <div className="info-camera-audience box-camera-audience">
            <div className="box-info-camera-audience amount-image d-flex align-items-center px-10px">
              <span className="text fs-10px">{t('text_remaining_number')}</span>
              <span className="amount pl-10px">{`${amountImageRequest}/${dataItem.image_limit}`}</span>
            </div>
            <div className="time-video box-info-camera-audience d-flex align-items-center px-10px">
              <span className="text fs-10px">{t('text_time_remaining')}</span>
              <span className="time pl-10px">{timeCountDown}</span>
            </div>
          </div>
        </div>
      </div>
      {isShowListHost ? (
        <ListVideoHost
          show={isShowListHost}
          listVideo={listUidChannel}
          actionCloseChangeHost={actionCloseChangeHost}
          actionChangeHost={actionChangeHost}
        />
      ) : (
        ''
      )}
    </div>
  )
}

BroadcastScreenLiveStream.propTypes = {
  dataItem: PropTypes.any,
  isShowBroadcast: PropTypes.bool,
  closeBroadcast: PropTypes.func
}
export default memo(BroadcastScreenLiveStream)
