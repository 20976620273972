import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from '@/features/Auth/AuthSlice'
import CommonSlice from '@/features/Common/CommonSlice'
import SessionSlice from '@/features/Sessions/SessionSlice'
import PointSlice from '@/features/Points/PointSlice'

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    common: CommonSlice,
    session: SessionSlice,
    point: PointSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['session/setListUidChannel', 'auth/setCredentials', 'auth/setUser'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['action.payload'],
        // Ignore these paths in the state
        ignoredPaths: ['session.listUidChannel', 'auth.user']
      }
    }),
  devTools: true
})
