import React, { useEffect, useMemo } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectListSession,
  selectSettingSlide,
  setKeyActiveSlide
} from '@/features/Sessions/SessionSlice'
import { selectCurrentUser, selectCurrentToken } from '@/features/Auth/AuthSlice'
import ItemNextSession from '@/components/home/ItemNextSession'
import { useTranslation } from 'react-i18next'

function NextSession() {
  const listSession = useSelector(selectListSession) || []
  const user = useSelector(selectCurrentUser) || null
  const token = useSelector(selectCurrentToken) || null
  const settings_slide = useSelector(selectSettingSlide) || null

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setKeyActiveSlide(0))
  }, [])

  const nextSession = useMemo(() => {
    if (listSession.length > 0 && token && user) {
      const timeNow = new Date().getTime()
      const arrData = listSession.filter((item) => item.timeEnd > timeNow)
      if (arrData.length > 0) {
        const data = []
        arrData.forEach((element) => {
          if (element.audienceIds.includes(user?.id)) {
            data.push(element)
          }
        })
        return data
      }
    }
    return []
  }, [listSession, token])

  return (
    <>
      {token && nextSession && nextSession.length > 0 ? (
        <div className="pb-3">
          <h3 className="title-home">{t('text_next_session')}</h3>
          <Slider {...settings_slide}>
            {nextSession.map((item, index) => {
              return <ItemNextSession dataItem={item} key={item.id} index={index} />
            })}
          </Slider>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default NextSession
