import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsShowForgotPassword,
  selectIsShowLogin,
  selectIsShowRegister,
  setCredentials,
  setIsShowForgotPassword,
  setShowFormLogin,
  setShowFormRegister
} from '@/features/Auth/AuthSlice'
import {
  selectRedirectRouter,
  setIsLoading,
  setRedirectRouter,
  setShowToastError
} from '@/features/Common/CommonSlice'
import { useNavigate } from 'react-router-dom'
import { checkEmail, checkPassWord } from '@/utils/validate'
import { logInWithEmailAndPassword, setupPresence } from '@/config/firebase'
import { useTranslation } from 'react-i18next'
import { ERROR_FIREBASE } from '@/utils/constants'

import '@/assets/scss/login.scss'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Logo from '@/assets/images/logo.svg'
import Close from '@/assets/images/icons/close.svg'
import EyeOn from '@/assets/images/icons/eye-on.svg'
import EyeOff from '@/assets/images/icons/eye-off.svg'

function FormLogin() {
  const isShow = useSelector(selectIsShowLogin) || false
  const isShowRegister = useSelector(selectIsShowRegister) || false
  const isShowForgotPassword = useSelector(selectIsShowForgotPassword) || false
  const redirectRouter = useSelector(selectRedirectRouter) || null
  const [isShowEye, setIsShowEye] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [formLogin, setFormLogin] = useState({
    email: '',
    password: ''
  })
  const [successInput, setSuccessInput] = useState({
    email: false,
    password: false
  })

  const [errorMessage, setErrorMessage] = useState({
    email: null,
    password: null
  })

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    let keyDownHandler = null
    if (isShow) {
      keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          handleSubmit()
        }
      }
      document.addEventListener('keydown', keyDownHandler)
    }

    return () => {
      if (keyDownHandler) {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [isSubmit, formLogin, isShow])

  const handleSucceedLogin = async (response) => {
    await setupPresence(response.uid)
    const dataUser = {
      email: response.reloadUserInfo.email,
      id: response.uid,
      isHost: response.reloadUserInfo.customAttributes ? true : false,
      userName: response.reloadUserInfo.user_name,
      point: 0
    }
    dispatch(
      setCredentials({
        user: dataUser,
        accessToken: response.accessToken
      })
    )
    dispatch(setIsLoading(false))
    if (redirectRouter) {
      navigate(redirectRouter)
    }
    setTimeout(() => {
      dispatch(setRedirectRouter(null))
    }, 100)
  }

  const handleSubmit = () => {
    if (!isSubmit || !isShow) return
    dispatch(setIsLoading(true))
    logInWithEmailAndPassword(formLogin)
      .then((response) => {
        handleSucceedLogin(response)
      })
      .catch((error) => {
        if (error === ERROR_FIREBASE.EMAIL_NOT_FOUND) {
          dispatch(setShowToastError(t('email_not_register')))
        } else {
          dispatch(setShowToastError(t('login_error')))
        }
        dispatch(setIsLoading(false))
      })
  }
  const resetData = () => {
    setFormLogin({
      email: '',
      password: ''
    })
    setIsSubmit(false)
    setIsShowEye(false)
    setSuccessInput({
      email: false,
      password: false
    })
    setErrorMessage({
      email: null,
      password: null
    })
  }
  const handleClose = () => {
    resetData()
    dispatch(setShowFormLogin(false))
    dispatch(setRedirectRouter(null))
  }
  const handleInputLogin = (e, nameInput) => {
    const form = {}
    form[nameInput] = e.target.value
    setFormLogin((previous) => {
      return {
        ...previous,
        ...form
      }
    })
  }
  useEffect(() => {
    if (checkEmail(formLogin.email)) {
      setSuccessInput((previous) => {
        return {
          ...previous,
          email: true
        }
      })
      setErrorMessage((previous) => {
        return {
          ...previous,
          email: null
        }
      })
    } else {
      setSuccessInput((previous) => {
        return {
          ...previous,
          email: false
        }
      })
    }
    if (checkPassWord(formLogin.password)) {
      setSuccessInput((previous) => {
        return {
          ...previous,
          password: true
        }
      })
      setErrorMessage((previous) => {
        return {
          ...previous,
          password: null
        }
      })
    } else {
      setSuccessInput((previous) => {
        return {
          ...previous,
          password: false
        }
      })
    }
    if (!checkEmail(formLogin.email) || !checkPassWord(formLogin.password)) {
      setIsSubmit(false)
    } else {
      setIsSubmit(true)
    }
  }, [formLogin])

  const showFormRegister = (event) => {
    event.preventDefault()
    dispatch(setShowFormRegister(true))
  }

  const showFormPrivacy = () => {
    resetData()
    dispatch(setShowFormLogin(false))
    navigate('/information/privacy_policy_web')
  }

  const showFormForgotPassword = (event) => {
    event.preventDefault()
    dispatch(setIsShowForgotPassword(true))
  }

  const blurInput = (e, nameInput) => {
    const value = e.target.value
    switch (nameInput) {
      case 'email':
        if (!checkEmail(value)) {
          if (!value) {
            setErrorMessage((previous) => {
              return {
                ...previous,
                email: t('require_email')
              }
            })
          } else {
            setErrorMessage((previous) => {
              return {
                ...previous,
                email: t('error_email')
              }
            })
          }
        }
        break
      case 'password':
        if (!checkPassWord(value)) {
          if (!value) {
            setErrorMessage((previous) => {
              return {
                ...previous,
                password: t('require_password')
              }
            })
          } else {
            setErrorMessage((previous) => {
              return {
                ...previous,
                password: t('error_password')
              }
            })
          }
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (!isShow) {
      resetData()
    }
  }, [isShow])

  return (
    <Modal show={isShow} className="form-login" centered scrollable>
      {isShowRegister || isShowForgotPassword ? (
        ''
      ) : (
        <Modal.Header className="header-login">
          <div className="action-close-button" onClick={handleClose}>
            <img src={Close} alt="close" />
          </div>
          <div className="logo-login d-flex align-items-center justify-content-center w-100 mt-5">
            <img src={Logo} alt="logo" />
          </div>
          <h3 className="text-white fs-14px text-center pt-5">{t('login')}</h3>
        </Modal.Header>
      )}

      <Modal.Body>
        <div className="px-4">
          <form autoComplete="off">
            <div className={`box-input mb-3 ${successInput.email ? 'success-input' : ''}`}>
              <label htmlFor="email" className="form-label text-white">
                {t('email_address')}
              </label>
              <input
                type="email"
                className={`form-control ${errorMessage.email ? 'is-invalid' : ''}`}
                id="email"
                autoComplete="off"
                value={formLogin.email}
                placeholder="登録したメールアドレスを入力してください"
                onChange={(e) => handleInputLogin(e, 'email')}
                onBlur={(e) => blurInput(e, 'email')}
              />
              {errorMessage.email ? (
                <div className="invalid-feedback">{errorMessage.email}</div>
              ) : (
                ''
              )}
            </div>
            <div className="box-input mb-3">
              <label htmlFor="password" className="form-label text-white">
                {t('password')}
              </label>
              <div
                className={`input-group input-password ${
                  successInput.password ? 'success-input' : ''
                }`}>
                <input
                  type={isShowEye ? 'text' : 'password'}
                  className={`form-control ${errorMessage.password ? 'is-invalid' : ''} ${
                    successInput.password ? 'border-end-0' : ''
                  }`}
                  id="password"
                  autoComplete="off"
                  placeholder="6文字以上の大文字を含む半角英数字で入力してください"
                  value={formLogin.password}
                  onChange={(e) => handleInputLogin(e, 'password')}
                  onBlur={(e) => blurInput(e, 'password')}
                />
                {!isShowEye ? (
                  <span
                    className={`input-group-text cs ${errorMessage.password ? 'is-invalid' : ''}`}
                    onClick={() => setIsShowEye(true)}>
                    <img src={EyeOn} alt="eye-on" />
                  </span>
                ) : (
                  <span
                    className={`input-group-text cs ${errorMessage.password ? 'is-invalid' : ''}`}
                    onClick={() => setIsShowEye(false)}>
                    <img src={EyeOff} alt="eye-off" />
                  </span>
                )}
                {errorMessage.password ? (
                  <div className="invalid-feedback">{errorMessage.password}</div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </div>

        <div className="action-login d-flex justify-content-center py-4">
          <Button
            type="submit"
            className={`button-login ${!isSubmit ? 'disable-button' : ''}`}
            onClick={handleSubmit}>
            {t('button_submit_login')}
          </Button>
        </div>

        <div className="action-register">
          <div className="link-action-register py-4">
            <div className="item-action mb-2">
              <div className="link-action" onClick={showFormRegister}>
                {t('click_link_register')}
              </div>
            </div>
            <div className="item-action mb-2">
              <div className="link-action" onClick={showFormPrivacy}>
                {t('click_link_privacy')}
              </div>
            </div>
            <div className="link-action item-action" onClick={showFormForgotPassword}>
              {t('click_link_when_trouble_login')}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FormLogin
