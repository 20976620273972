import React, { useEffect, useMemo, useState } from 'react'
import { selectCurrentUser } from '@/features/Auth/AuthSlice'
import {
  selectDataItemModalListImage,
  setDataItemModalListImage
} from '@/features/Sessions/SessionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { db, storage } from '@/config/firebase'
import { getDownloadURL, ref } from 'firebase/storage'
import {
  CONFIG,
  STATUS_IMAGE_USER,
  BUY_MODE,
  BUY_REQUEST_STATUS,
  STATUS_STRIPE
} from '@/utils/constants'
import { setScrollLayout } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import ChevronBack from '@/assets/images/chevron-back.svg'
import {
  selectIsLayoutScroll,
  setIsLayoutScroll,
  setIsLoading,
  setIsShowModalFullPage,
  selectIsShowModalFullPage
} from '@/features/Common/CommonSlice'
import '@/assets/scss/modalListImageSession.scss'
import Lightbox from '@/components/ModalListImageSession/ImageViewer'
import RCheckBox from '@/components/RCheckBox'
import PointSpend from '@/components/ModalListImageSession/PointSpend'
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  getCountFromServer
} from 'firebase/firestore'
import { apiPost } from '@/config/httpRequest'
import ModalConfirm from '@/components/common/ModalConfirm'
import ModalAlert from '@/components/common/ModalAlert'
import PointBuy from '@/pages/Points'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'

function ModalListImageSession({ eventID, buyMode }) {
  const userLogin = useSelector(selectCurrentUser) || null
  const dataItem = useSelector(selectDataItemModalListImage) || null
  const isSetLayoutScroll = useSelector(selectIsLayoutScroll) || null
  const isShowFullPage = useSelector(selectIsShowModalFullPage) || null
  const [listImageAvailable, setListImageAvailable] = useState([])
  const [listSelectedImage, setListSelectedImage] = useState([])
  const [viewImage, setViewImage] = useState(false)
  const [viewImageId, setViewImageId] = useState('')
  const [buyData, setBuyData] = useState({ free_image: 0, price_image: 10 })
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)
  const [isShowModalConfirmBuyPoint, setIsShowModalConfirmBuyPoint] = useState(false)
  const [isShowAlertSuccess, setIsShowAlertSuccess] = useState(false)
  const [isShowPointBuy, setIsShowPointBuy] = useState(false)
  const [isStatusBoughtImage, setIsStatusBoughtImage] = useState(true)
  const [sizeImageBought, setSizeImageBought] = useState(0)
  const [imageAvailabale, setImageAvailabale] = useState([])
  const [textBuyButton, setTextBuyButton] = useState('')
  const [textModalTitle, setTextModalTitle] = useState('')
  const [textBuySuccessTitle, setTextBuySuccessTitle] = useState('')
  const [textBuySuccess1, setTextBuySuccess1] = useState('')
  const [textBuySuccess2, setTextBuySuccess2] = useState('')
  const [textBuyError, setTextBuyError] = useState('')
  const [listImageBuyRequest, setListImageBuyRequest] = useState([])
  const [listSelectedImageRequestedBuy, setListSelectedImageRequestedBuy] = useState([])
  const [cropImageRequestCount, setCropImageRequestCount] = useState(0)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !isShowFullPage ||
      !userLogin?.id ||
      Object.keys(dataItem || {}).length <= 0 ||
      cropImageRequestCount != 0
    )
      return
    getDataShowModal()
    changeElementText()
  }, [isShowFullPage, dataItem])

  const getDataShowModal = () => {
    getActiveCropImageCount()
    getDataImageAvailabale()
    getDataImageBought()
    getDataEventAndConfig()
    // 購入申請
    getDataImageBuyRequest()
  }

  const getActiveCropImageCount = async () => {
    const q = query(
      collection(db, `crop_image_requests/`),
      where('event_id', '==', dataItem.id),
      where('user_id', '==', userLogin.id),
      where('status', '==', STATUS_STRIPE.NEW)
    )

    const snapshotCropImageRequests = await getCountFromServer(q)
    const cropImageRequestCount = snapshotCropImageRequests.data().count

    setCropImageRequestCount(cropImageRequestCount)
  }
  const getDataImageAvailabale = async () => {
    const q = query(
      collection(db, `users/${userLogin.id}/events/${dataItem.id}/images`),
      where('status', '==', STATUS_IMAGE_USER.AVAILABLE)
    )
    const queryImage = await getDocs(q)
    const dataImage = []
    queryImage.forEach((doc) => {
      if (doc.data()) {
        dataImage.push(doc.data())
      }
    })
    setListImageAvailable(dataImage)
  }
  const getDataImageBought = async () => {
    const q = query(
      collection(db, `users/${userLogin.id}/events/${dataItem.id}/images`),
      where('status', '==', STATUS_IMAGE_USER.BOUGHT)
    )
    const queryImage = await getDocs(q)
    setSizeImageBought(queryImage.size)
  }
  const getDataImageBuyRequest = async () => {
    const q = query(
      collection(db, `image_buy_requests/`),
      where('event_id', '==', eventID),
      where('user_id', '==', userLogin.id)
    )
    const queryImage = await getDocs(q)
    const dataImage = []
    queryImage.forEach((doc) => {
      if (doc.data()) {
        const buyRequestData = doc.data()
        buyRequestData.image_id = doc.id
        dataImage.push(buyRequestData)
      }
    })
    setListImageBuyRequest(dataImage)
  }

  const getDataEventAndConfig = async () => {
    let free_image = 0
    let price_image = 10
    const queryEvent = doc(db, 'events', dataItem.id)
    const dataEvent = await getDoc(queryEvent)
    free_image = dataEvent.data()?.total_free_image || 0
    const q = query(collection(db, 'configs'))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const { value, code } = doc.data()
      if (code === CONFIG.IMAGE_PRICE) price_image = value
    })

    setBuyData({ free_image, price_image })
  }

  useEffect(() => {
    if (!listImageAvailable?.length) {
      setImageAvailabale([])
      return
    }
    dispatch(setIsLoading(true))
    listImageAvailable.forEach((item) => {
      const { status, watermarked_path, thumbnail_path, title, id, created_date } = item
      let path = thumbnail_path
      if (watermarked_path) path = watermarked_path
      const imageRef = ref(storage, path)

      getDownloadURL(imageRef)
        .then((url) => {
          const data = {
            id: id,
            path: url,
            title: title,
            selected: false,
            status: status,
            created_date: created_date
          }
          setImageAvailabale((previous) => {
            const check = previous.find((dataCheck) => dataCheck.id === data.id)
            if (!check) {
              return [...previous, data]
            }
            return [...previous]
          })
        })
        .catch(console.error)
    })
    dispatch(setIsLoading(false))
  }, [listImageAvailable])

  const pointNeeded = useMemo(() => {
    let count = listSelectedImage.length
    const { free_image, price_image } = buyData
    // case: buy free images
    if (free_image >= sizeImageBought + count) return 0
    // case: out of free images
    if (sizeImageBought > free_image) return count * price_image
    // case: stil has free images BUT buy more than free images in one time
    return (count + sizeImageBought - free_image) * price_image
  }, [listSelectedImage, sizeImageBought])

  const changeImage = (event, dataItem) => {
    if (listSelectedImageRequestedBuy.length > 0) {
      // 購入申請が承認済の写真が選択されてる場合は何もしない
      return
    }
    if (event.target.checked) {
      setListSelectedImage((previous) => {
        const check = previous.find((item) => item.id === dataItem.id)
        if (!check) return [...previous, dataItem.id]
      })
    }
    if (!event.target.checked) {
      setListSelectedImage((previous) => {
        return previous.filter((item) => item !== dataItem.id)
      })
    }
  }

  const changeImageBuyRequest = (event, dataItem) => {
    if (listSelectedImage.length > 0) {
      // 購入申請したい写真が選択されてる場合は何もしない
      return
    }
    if (event.target.checked) {
      setListSelectedImageRequestedBuy((previous) => {
        const check = previous.find((item) => item.id === dataItem.id)
        if (!check) return [...previous, dataItem.id]
      })
    }
    if (!event.target.checked) {
      setListSelectedImageRequestedBuy((previous) => {
        return previous.filter((item) => item !== dataItem.id)
      })
    }
  }

  const ImageRCheckBoxView = (isChecked, item) => {
    const buyRequestedImage = getImageBuyRequestInfo(item)
    if (buyRequestedImage) {
      switch (buyRequestedImage.status) {
        case BUY_REQUEST_STATUS.REQUESTED:
          return <div className="buy_request_status">{t('request_buy_requested')}</div>
        case BUY_REQUEST_STATUS.APPROVED:
          return (
            <>
              <div className="buy_request_status approved">{t('request_buy_approved')}</div>
              <RCheckBox isChecked={isChecked} onChange={(e) => changeImageBuyRequest(e, item)} />
            </>
          )
      }
    } else {
      return <RCheckBox isChecked={isChecked} onChange={(e) => changeImage(e, item)} />
    }
  }

  const isCheckedImage = (item) => {
    if (listSelectedImage.length > 0) {
      return listSelectedImage.filter((x) => x === item?.id).length > 0
    }
    if (listSelectedImageRequestedBuy.length > 0) {
      return listSelectedImageRequestedBuy.filter((x) => x === item?.id).length > 0
    }
    return false
  }

  const closeModal = () => {
    if (isSetLayoutScroll) {
      setScrollLayout(true)
      dispatch(setIsLayoutScroll(false))
    }
    setListImageAvailable([])
    setListSelectedImage([])
    dispatch(setIsShowModalFullPage(false))
    dispatch(setDataItemModalListImage(null))
    setCropImageRequestCount(0)
  }

  const handleBuyImage = async () => {
    const isSelectedImageListEmpty = (listSelectedImage?.length ?? 0) <= 0
    const isSelectedImageBuyRequestListEmpty = (listSelectedImageRequestedBuy?.length ?? 0) <= 0
    if (isSelectedImageListEmpty && isSelectedImageBuyRequestListEmpty) return
    const isNotEnoughPoint = pointNeeded > userLogin.point
    if (isNotEnoughPoint) {
      setIsShowModalConfirmBuyPoint(true)
      return
    }
    setIsShowModalConfirm(true)
  }

  const confirmBuyPhoto = async () => {
    dispatch(setIsLoading(true))

    // 購入申請の承認済みの写真を選択した場合、購入する
    if (listSelectedImageRequestedBuy.length > 0) {
      buyImage(listSelectedImageRequestedBuy)
      return
    }

    // call api buy photo
    switch (buyMode) {
      case BUY_MODE.NORMAL:
        buyImage(listSelectedImage)
        break
      case BUY_MODE.REQUEST:
        await apiPost('/api/v1/images/request_buy', JSON.stringify(listSelectedImage)).finally(
          () => {
            getDataImageBuyRequest()
            setIsShowAlertSuccess(true)
            setIsStatusBoughtImage(true)
            setIsShowModalConfirm(false)
            dispatch(setIsLoading(false))
          }
        )
        break
    }

    setIsShowAlertSuccess(true)
  }

  const buyImage = async (listImage) => {
    await apiPost('/api/v1/images/buy', JSON.stringify(listImage))
      .then(() => {
        getDataImageBought()
        setDataWhenBoughtImage(listImage)
        setIsShowAlertSuccess(true)
        setIsStatusBoughtImage(true)
      })
      .catch(() => {
        setIsStatusBoughtImage(false)
      })
      .finally(() => {
        setIsShowModalConfirm(false)
        dispatch(setIsLoading(false))
      })
  }

  const setDataWhenBoughtImage = (imageSelected) => {
    const data = []
    imageAvailabale.map((item) => {
      const check = imageSelected.find((dataItem) => dataItem === item.id)
      if (!check) data.push(item)
    })
    setImageAvailabale(data)
    setListSelectedImage([])
    setViewImageId('')
    setViewImage(false)
  }

  const refreshData = () => {
    getDataImageBought()
    getDataImageBuyRequest()
  }

  const confirmBuyPoint = () => {
    setIsShowModalConfirmBuyPoint(false)
    setIsShowPointBuy(true)
  }

  const changeElementText = () => {
    switch (buyMode) {
      case BUY_MODE.NORMAL:
        useTextForBuy()
        break
      case BUY_MODE.REQUEST:
        useTextForRequestBuy()
        break
    }
  }

  useEffect(() => {
    if (listSelectedImageRequestedBuy.length > 0) {
      useTextForBuy()
    } else {
      changeElementText()
    }
  }, [listSelectedImageRequestedBuy])

  const useTextForBuy = () => {
    setTextBuyButton(t('buy'))
    setTextModalTitle(t('text_title_buy_photo'))
    setTextBuySuccessTitle(t('text_title_buy_success'))
    setTextBuySuccess1(t('text_body_buy_success_1'))
    setTextBuySuccess2(t('text_body_buy_success_2'))
    setTextBuyError(t('text_body_buy_error'))
  }

  const useTextForRequestBuy = () => {
    setTextBuyButton(t('request_buy'))
    setTextModalTitle(t('text_title_request_buy_photo'))
    setTextBuySuccessTitle(t('text_title_request_buy_success'))
    setTextBuySuccess1(t('text_body_request_buy_success_1'))
    setTextBuySuccess2(t('text_body_request_buy_success_2'))
    setTextBuyError(t('text_body_request_buy_error'))
  }

  const getImageBuyRequestInfo = (item) => {
    return listImageBuyRequest.find((request) => request.image_id === item.id)
  }

  const isEnableBuyButton = () => {
    return (
      (listSelectedImage && listSelectedImage.length > 0) ||
      (listSelectedImageRequestedBuy && listSelectedImageRequestedBuy.length > 0)
    )
  }

  return (
    <>
      <div className="header-list-image-session d-flex align-items-center justify-content-between">
        <div className="back cs" onClick={closeModal}>
          <img src={ChevronBack} alt="Chevron Back" />
        </div>
        <div className="title text-white">{dataItem?.title}</div>
        <div className={`action-page ${isEnableBuyButton() ? 'enable-button' : ''}`}>
          <span onClick={handleBuyImage}>{textBuyButton}</span>
        </div>
      </div>
      <div
        className={`list-image-session ${
          !imageAvailabale?.length ? 'd-flex justify-content-center align-items-center' : ''
        }`}>
        {/*{imageAvailabale?.length > 0 && cropImageRequestCount == 0 ? (*/}
        {imageAvailabale?.length > 0 ? (
          imageAvailabale
            .sort((a, b) => {
              return b.created_date.seconds - a.created_date.seconds
            })
            .map((item) => {
              const isChecked = isCheckedImage(item)
              return (
                <div className="box-image" key={item.id}>
                  <div className="item-box">
                    <img
                      src={item.path}
                      alt={item.title}
                      onClick={() => {
                        setViewImageId(item.id)
                        setViewImage(true)
                      }}
                    />
                    {ImageRCheckBoxView(isChecked, item)}
                  </div>
                </div>
              )
            })
        ) : (
          <div className="no-data">{t('text_wating_image')}</div>
        )}
      </div>
      <hr />
      <>
        {imageAvailabale?.length && viewImage && (
          <Lightbox
            selectedImages={listSelectedImage}
            setSelectedImages={setListSelectedImage}
            buyMode={buyMode}
            listImageBuyRequest={listImageBuyRequest}
            selectedImageBuyRequests={listSelectedImageRequestedBuy}
            setSelectedImageBuyRequests={setListSelectedImageRequestedBuy}
            viewImageId={viewImageId}
            onClose={() => setViewImage(false)}
            setDataWhenBoughtImage={setDataWhenBoughtImage}
            refreshData={refreshData}
            listImage={imageAvailabale.map((item) => {
              return {
                id: item.id,
                url: item.path,
                title: item.title
              }
            })}
          />
        )}
      </>
      {listSelectedImage.length > 0 && (
        <PointSpend pointNeeded={pointNeeded} userPoint={userLogin.point} />
      )}
      <ModalConfirm
        titleModal={textModalTitle}
        textCancel={t('text_action_cancel')}
        textConfirm={t('text_action_submit')}
        showModal={isShowModalConfirm}
        actionClose={() => setIsShowModalConfirm(false)}
        actionConfirm={confirmBuyPhoto}
      />
      <ModalConfirm
        titleModal={t('text_title_buy_point')}
        textCancel={t('text_action_cancel')}
        textConfirm={t('text_action_submit')}
        showModal={isShowModalConfirmBuyPoint}
        actionClose={() => setIsShowModalConfirmBuyPoint(false)}
        actionConfirm={confirmBuyPoint}
      />
      <ModalAlert
        titleModal={textBuySuccessTitle}
        textAlert={
          isStatusBoughtImage ? (
            <>
              <span>{textBuySuccess1}</span>
              <br />
              <span>{textBuySuccess2}</span>
            </>
          ) : (
            textBuyError
          )
        }
        showModal={isShowAlertSuccess}
        actionClose={() => setIsShowAlertSuccess(false)}
      />
      <Modal
        backdrop="static"
        show={isShowPointBuy}
        className="modal-confirm modal-buy-point"
        centered
        onHide={() => setIsShowPointBuy(false)}>
        <Modal.Body className="body-buy-point">
          <PointBuy isShowModal={isShowPointBuy} setIsShowModal={setIsShowPointBuy} />
        </Modal.Body>
      </Modal>
    </>
  )
}

ModalListImageSession.propTypes = {
  eventID: PropTypes.string,
  buyMode: PropTypes.string.isRequired
}

export default ModalListImageSession
