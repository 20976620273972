import React, { useState, useEffect } from 'react'
import Header from '@/pages/Profile/components/Header'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCurrentToken,
  selectTabProfile,
  setTabProfile,
  selectCurrentUser,
  setStripeTransactionList
} from '@/features/Auth/AuthSlice'
import { TAB_PROFILE } from '@/utils/constants'
import PointHistory from '@/components/profile/PointHistory'
import SessionHistory from '@/components/profile/SessionHistory'
import SettingProfile from '@/components/profile/SettingProfile'
import ModalListImageSession from '@/components/ModalListImageSession/index'
import ModalFullPage from '@/components/common/ModalFullPage'
import '@/assets/scss/profile.scss'
import { selectIsShowModalFullPage } from '@/features/Common/CommonSlice'
import BaseLoading from '@/components/common/BaseLoading'
import { useLocation } from 'react-router-dom'

import { db } from '@/config/firebase'
import { collection, getDocs, query } from 'firebase/firestore'

function Profile() {
  const token = useSelector(selectCurrentToken) || null
  const userLogin = useSelector(selectCurrentUser) || null
  const [isLoadingTab, setIsLoadingTab] = useState(false)
  const isShowModalImage = useSelector(selectIsShowModalFullPage) || false
  const tabProfile = useSelector(selectTabProfile)

  const dispatch = useDispatch()
  const location = useLocation()
  const changeTab = (value) => {
    if (value !== TAB_PROFILE.SETTING_PROFILE) {
      setIsLoadingTab(true)
      setTimeout(() => {
        setIsLoadingTab(false)
      }, 300)
    }
    dispatch(setTabProfile(value))
  }
  const setComponentLayout = () => {
    if (tabProfile === TAB_PROFILE.SESSION_HISTORY) {
      return <SessionHistory tabProfile={tabProfile} />
    }
    if (tabProfile === TAB_PROFILE.POINT_HISTORY) {
      return <PointHistory tabProfile={tabProfile} />
    }
    return <SettingProfile tabProfile={tabProfile} />
  }
  useEffect(() => {
    setComponentLayout()
  }, [tabProfile])

  useEffect(() => {
    const hash = location.hash.slice(1)
    if (hash === TAB_PROFILE.SESSION_HISTORY || hash === TAB_PROFILE.POINT_HISTORY) {
      dispatch(setTabProfile(hash))
    }
  }, [])

  useEffect(() => {
    if (userLogin?.id) {
      setDataStripeTransactionList()
    }
  }, [userLogin])

  const setDataStripeTransactionList = async () => {
    const queryStripeTransactionList = query(
      collection(db, `users/${userLogin.id}/stripe_transaction_list`)
    )
    const getDocData = await getDocs(queryStripeTransactionList)
    const data = []
    getDocData.forEach((doc) => {
      if (doc.data()) {
        const formatData = {
          ...doc.data(),
          created_date: doc.data().created_date.seconds,
          updated_date: doc.data()?.updated_date?.seconds || null
        }
        data.push(formatData)
      }
    })
    dispatch(setStripeTransactionList(data))
  }

  return (
    <>
      {token ? (
        <div className="w-100 position-relative">
          <Header changeTabProfile={changeTab} tabProfile={tabProfile} />
          <div className="content-profile content-page-app">
            {!isLoadingTab ? setComponentLayout() : <BaseLoading />}
          </div>
          {isShowModalImage ? (
            <ModalFullPage>
              <ModalListImageSession />
            </ModalFullPage>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default Profile
