import React from 'react'
import PropTypes from 'prop-types'
import LogoApp from '@/assets/images/logo_app.png'
import Chevron from '@/assets/images/chevron.svg'

function ItemNotification({ dataItem, actionShowDetail }) {
  return (
    <div
      className="box-notification position-relative d-flex"
      onClick={(e) => actionShowDetail(e, dataItem)}>
      <div className="box-image d-flex align-items-center">
        <div className="image">
          <img src={dataItem.image || LogoApp} alt={dataItem.title} />
        </div>
      </div>

      <div className="info-notification d-flex position-relative">
        <div className="box-title text-white line line-2 position-relative">
          <div className="title">{dataItem.title}</div>
          <div className="time">{dataItem.time_diff}</div>
        </div>
        <div className="icon position-absolute">
          <img src={Chevron} alt="Chevron" />
        </div>
      </div>
    </div>
  )
}

ItemNotification.propTypes = {
  dataItem: PropTypes.any,
  actionShowDetail: PropTypes.func
}

export default ItemNotification
