import React, { useEffect, useMemo, useState } from 'react'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '@/config/firebase'
import { selectIsLoading, setIsLoading, setShowToastSuccess } from '@/features/Common/CommonSlice'
import { setListPoint } from '@/features/Points/PointSlice'
import { PointBuyItem } from '@/pages/Points/components/PointBuyItem'
import './components/points.scss'
import { MyPoint } from '@/pages/Points/components/MyPoint'
import Close from '@/assets/images/icons/close.svg'
import { t } from 'i18next'
import Stripe from '@/features/Payment'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

function PointBuy(props) {
  const isLoading = useSelector(selectIsLoading) || false
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [points, setPoints] = useState([])
  const [isShowStripe, setIsShowStripe] = useState(false)
  const [selectedId, setSelectedId] = useState(-1)

  const { isShowModal, setIsShowModal } = props

  useEffect(() => {
    if (!isLoading) {
      getDataPoint().catch(console.error)
    }
  }, [])
  const getDataPoint = async () => {
    dispatch(setIsLoading(true))
    const queryDataEvent = await query(collection(db, 'points'))
    await onSnapshot(queryDataEvent, (querySnapshot) => {
      const data = []
      querySnapshot.forEach((doc) => {
        const formatData = {
          id: doc.id,
          price: doc.data().price,
          point: doc.data().point
        }
        data.push(formatData)
      })
      dispatch(setListPoint(data))
      setPoints(data)
    })
    dispatch(setIsLoading(false))
  }

  const sortData = useMemo(() => {
    const dataClone = [...points]
    return dataClone.sort((a, b) => {
      return a.price - b.price
    })
  }, [points])

  function closeDetail() {
    if (isShowModal) {
      setIsShowModal(false)
      return
    }
    navigate(-1)
  }

  function handleConfirmPayment(res) {
    const { paymentIntent } = res
    const { status } = paymentIntent

    if (status !== 'succeeded') {
      dispatch(setShowToastSuccess(t('payment_not_succeeded')))
      return
    }
    // succeeded
    dispatch(setShowToastSuccess(t('payment_successed')))
    if (isShowModal) setIsShowModal(false)
    setIsShowStripe(false)
  }

  function renderStripePayment() {
    if (!isShowStripe) return <div></div>

    return (
      <div className="stripe-container">
        <Stripe
          pointId={selectedId}
          dataSession={{}}
          setIsShowStripe={setIsShowStripe}
          handleConfirmPayment={handleConfirmPayment}
        />
      </div>
    )
  }

  function oncClickPointBuy(id) {
    setSelectedId(id)
    setIsShowStripe(true)
  }

  function renderPage() {
    if (isShowStripe) return <div></div>
    return (
      <React.Fragment>
        <div className="box-title-detail-session">
          <div className="title text-white">{t('point_buy_title')}</div>
          <div className="action-close cs" onClick={closeDetail}>
            <img src={Close} alt="close" />
          </div>
        </div>
        <MyPoint />
        {sortData.map((item) => {
          return <PointBuyItem key={item.id} item={item} onClick={oncClickPointBuy} />
        })}
      </React.Fragment>
    )
  }

  return (
    <div className="point-detail-session">
      <div className="w-100">
        {renderPage()}
        {renderStripePayment()}
      </div>
    </div>
  )
}

PointBuy.propTypes = {
  isShowModal: PropTypes.bool,
  setIsShowModal: PropTypes.func
}

export default PointBuy
