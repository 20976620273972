import React, { useEffect } from 'react'
import Header from '@/layouts/components/Header'
import Footer from '@/layouts/components/Footer'
import PropTypes from 'prop-types'
import FormLogin from '@/features/Auth/FormLogin'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsLoading,
  selectToastError,
  selectToastSuccess,
  setShowToastError,
  setShowToastSuccess
} from '@/features/Common/CommonSlice'
import BaseLoading from '@/components/common/BaseLoading'
import { toast, ToastContainer } from 'react-toastify'
import FormRegister from '@/features/Auth/FormRegister'
import ForgotPassword from '@/features/Auth/ForgotPassword'
import { useLocation } from 'react-router-dom'
import { isIOS, isMobile } from '@/utils/utils'
import FormConfirmEmail from '@/features/Auth/FormConfirmEmail'
import { setShowEmailVerify, setShowFormRegister } from '@/features/Auth/AuthSlice'
import { getAuth } from 'firebase/auth'
import { db } from '@/config/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { CLIENT } from '@/config/agora'

let unsubscribeSetInterval

function DefaultLayout(props) {
  const { isHiddenScrollLayout, children, classNameComponent } = props
  const isLoading = useSelector(selectIsLoading) || false
  const toastSuccess = useSelector(selectToastSuccess) || null
  const toastError = useSelector(selectToastError) || null

  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const checkEmailVerified = async () => {
    const auth = await getAuth()
    if (!auth?.currentUser) return
    // dispatch(setShowEmailVerify(!auth?.currentUser?.emailVerified))
    if (auth?.currentUser?.emailVerified) return
    if (unsubscribeSetInterval) return // don't run a new interval, one is enough
    const unsubscribeOnUserChanged = auth.onAuthStateChanged(async () => {
      unsubscribeSetInterval = setInterval(async () => {
        const user = auth?.currentUser
        if (!user) {
          if (unsubscribeSetInterval) clearInterval(unsubscribeSetInterval)
          return
        }
        await user?.reload()
        if (user?.emailVerified) {
          clearInterval(unsubscribeSetInterval) //stop setInterval
          dispatch(setShowEmailVerify(false))
          await updateDoc(doc(db, 'users', user.uid), {
            email_verified: true
          }).catch(console.error)
          dispatch(setShowFormRegister(false))
          return unsubscribeOnUserChanged() //unsubscribe onUserChanged
        }
      }, 3000)
    })
  }
  checkEmailVerified().catch(console.error)

  useEffect(() => {
    setVhScreen()
    function handleResize() {
      setVhScreen()
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const setVhScreen = () => {
    setTimeout(() => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 100)
  }

  useEffect(() => {
    const element = document.getElementById('layout-content-page')
    if (element) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        element.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
    }
    if (CLIENT.connectionState === 'CONNECTED') {
      CLIENT.leave().catch(console.error)
    }
  }, [pathname])

  useEffect(() => {
    if (toastSuccess) {
      toast.success(toastSuccess, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    return () => {
      dispatch(setShowToastSuccess(null))
    }
  }, [toastSuccess])

  useEffect(() => {
    if (toastError) {
      toast.error(toastError, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    return () => {
      dispatch(setShowToastError(null))
    }
  }, [toastError])
  return (
    <div className={`wrapper ${isMobile() ? 'camelia-mobile' : 'camelia-web'}`}>
      <Header />
      <div className={`content-page ${isIOS() ? 'is-ios' : ''}`} id="content-page">
        <div className="container-page" id="container-page">
          <div
            id="layout-content-page"
            className={`layout-content ${isHiddenScrollLayout ? 'overflow-hidden' : ''} ${
              classNameComponent ? classNameComponent : ''
            }`}>
            {children}
            <div>test</div>
          </div>
        </div>
      </div>
      <Footer />
      <FormLogin />
      <FormRegister />
      <FormConfirmEmail />
      <ForgotPassword />
      {isLoading ? <BaseLoading /> : ''}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isHiddenScrollLayout: PropTypes.bool,
  classNameComponent: PropTypes.string
}

export default DefaultLayout
