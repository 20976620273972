import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ChevronBack from '@/assets/images/chevron-back.svg'

function ListImage({ dataImage, closeModal, dataItem, actionClickBoxImage }) {
  const { t } = useTranslation()

  return (
    <div>
      <div className="header-list-image-session d-flex align-items-center">
        <div className="back cs w-10" onClick={closeModal}>
          <img src={ChevronBack} alt="Chevron Back" />
        </div>
        <div className="title text-white w-90 text-center">{dataItem?.title}</div>
      </div>
      <div
        className={`list-image-session padding-bottom-120 ${
          dataImage && dataImage.length === 0
            ? 'd-flex justify-content-center align-items-center'
            : ''
        }`}>
        {dataImage && dataImage.length > 0 ? (
          dataImage.map((item, index) => {
            return (
              <div
                className="box-image"
                key={item.id + index}
                onClick={() => actionClickBoxImage(item)}>
                <div className="item-box">
                  <img src={item.path} alt={item.title} />
                </div>
              </div>
            )
          })
        ) : (
          <div className="no-data">{t('text_no_image')}</div>
        )}
      </div>
    </div>
  )
}

ListImage.propTypes = {
  dataItem: PropTypes.any,
  dataImage: PropTypes.any,
  closeModal: PropTypes.func,
  actionClickBoxImage: PropTypes.func
}

export default ListImage
