import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

/* Usage:
 *
 * */
function ModalAlert({ titleModal, textAlert, actionClose, showModal }) {
  const [isShowModal, setIsShowModal] = useState(true)
  const defaultActionClose = () => {
    setIsShowModal(false)
  }
  return (
    <Modal
      show={showModal ?? isShowModal}
      className="modal-confirm"
      centered
      onHide={actionClose || defaultActionClose}>
      <Modal.Header className="header-confirm w-100 text-center" closeButton>
        <h3 className="text-white yuGothic fs-22px-b text-center mb-0 w-100">{titleModal}</h3>
      </Modal.Header>
      <Modal.Body className="body-alert">
        <h4 className="text-white fs-14px text-center mb-0 w-100">{textAlert}</h4>
      </Modal.Body>
    </Modal>
  )
}

ModalAlert.propTypes = {
  titleModal: PropTypes.string,
  textAlert: PropTypes.any,
  actionClose: PropTypes.func,
  showModal: PropTypes.bool
}

export default ModalAlert
