import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import bg_button from '@/assets/images/bg_button.png'
import { formatMoney } from '@/utils/utils'

export function PointBuyItem({ item, onClick }) {
  const { t } = useTranslation()
  return (
    <React.Fragment key={item.id}>
      <div className="point-container">
        <div className="point-name fs-20px-m">{item.point ?? 'point'}P</div>
        <div className="point-price">
          <div className="price fs-10px-m">{formatMoney(item.price) ?? 'price'}</div>
        </div>

        <div className="buy-point" onClick={() => onClick(item.id)}>
          <img className="" src={bg_button} alt="bg_button"></img>
          <span className="prevent-select">{t('buy')}</span>
        </div>
      </div>
    </React.Fragment>
  )
}

PointBuyItem.propTypes = {
  item: PropTypes.any,
  onClick: PropTypes.func
}
