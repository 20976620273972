import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CameliaDefault from '@/assets/images/camelia_default.png'

function ItemSession({ dataItem }) {
  const { t } = useTranslation()
  const statusSession = useMemo(() => {
    if (dataItem && Object.keys(dataItem).length > 0) {
      const timeNow = new Date().getTime()
      if (dataItem.timeEnd - timeNow < 0) {
        return t('text_status_session_finished')
      }
      if (dataItem.timeStart - timeNow < 0 && dataItem.timeEnd - timeNow > 0) {
        return t('text_status_session_happen')
      }
      if (dataItem.timeStart - timeNow > 0) {
        return t('text_status_session_soon')
      }
    }
  }, [dataItem])

  return (
    <div className="item-session">
      <Link to={`/session/${dataItem.id}`}>
        <div className="images">
          <img
            src={dataItem.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = CameliaDefault
            }}
            alt={dataItem.title}
          />
          <div className="info-session">
            <div className="date-session text-white w-80 d-flex">
              <div className="date">
                {t('text_date')} : {dataItem.dateStart}
              </div>
              <div className="time">{dataItem.timeString}</div>
            </div>
            {/*
            <div className="capacity-session w-20 text-end">{`${t('text_capacity_session')} : ${
              dataItem.audienceIds ? dataItem.audienceIds.length : 0
            }`}</div>
            */}
          </div>
          {statusSession ? (
            <div
              className={`status-session ${
                statusSession === t('text_status_session_finished') ? 'session-end' : ''
              }`}>
              {statusSession || ''}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="title-price-item-session">
          <div className="title-item-session line line-2">{dataItem.title}</div>
          <div className="price-item-session">
            {dataItem.price}
            <span className="name-price fs-12px">{t('ten_thousand_yen')}</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

ItemSession.propTypes = {
  dataItem: PropTypes.any
}

export default ItemSession
