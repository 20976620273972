import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import languageEn from '@/utils/languageEn'
import languageJp from '@/utils/languageJp'

const resources = {
  jp: {
    translation: {
      ...languageJp
    }
  },
  en: {
    translation: {
      ...languageEn
    }
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'jp',
    interpolation: {
      escapeValue: false
    }
  })
  .catch(console.error)

export default i18n
